import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import { ShareRequest } from '@models/share.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const sharedAPi = createApi({
	reducerPath: 'sharedAPi',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		sharedFilter: builder.mutation<void, ShareRequest>({
			query: ({ id }) => ({
				url: `clone/filter/${id}`,
				method: 'POST',
			}),
		}),
		sharedFolderFilter: builder.mutation<void, ShareRequest>({
			query: ({ id }) => ({
				url: `clone/filter/folder/${id}`,
				method: 'POST',
			}),
		}),
		sharedNotebook: builder.mutation<void, ShareRequest>({
			query: ({ id }) => ({
				url: `clone/notebook/${id}`,
				method: 'POST',
			}),
		}),
		sharedFolderNotebook: builder.mutation<void, ShareRequest>({
			query: ({ id }) => ({
				url: `clone/notebook/folder/${id}`,
				method: 'POST',
			}),
		}),
	}),
})
