import { css } from '@styles/theme'

export const menuQuestionsStyles = {
	questionsMenu: css({
		display: 'flex',
		flexWrap: 'wrap',
		gap: '10px 40px',
		alignItems: ' center',
		justifyContent: 'space-between',
		padding: '16px',
		height: '100%',
		minHeight: '84px',

		background: '$neutral20',
		border: '1px solid $neutral50',
		borderRadius: '12px',

		'@resp': {
			display: 'flex',
			flexWrap: 'nowrap',
			gap: '40px',
			alignItems: ' center',
			justifyContent: 'space-between',
			padding: '0px 54px  0px 16px',
		},
	}),

	questionsNumber: css({
		display: 'flex',
		gap: '16px',
		alignItems: ' center',
		width: '100%',
		maxWidth: '248px',

		h6: {
			_font: 'medium',
			fontWeight: '500',
			fontSize: '$md',
			lineHeight: '21px',
			letterSpacing: '0.05em',
			color: '$neutral150',
		},

		p: {
			_font: 'normal',
			fontWeight: '400',
			fontSize: '$md',
			lineHeight: '20px',
			letterSpacing: '0.05em',
			color: '$neutral90',
		},
	}),

	changeSizeContainer: css({
		display: 'flex',
		justifyContent: 'center',
		width: '80px',
		height: '48px',
		background: '$neutral10',

		button: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '39.5px',
			cursor: 'pointer',
			color: '$neutral130',
			_font: 'medium',
			fontWeight: '500',
			border: '1px solid $neutral50',
			borderRadius: '0 12px 12px 0',
			fontSize: '12px',
			lineHeight: '21px',
			letterSpacing: '0.05em',

			'&:first-child': {
				borderRadius: '12px 0 0 12px',
				borderRight: 'none',
			},
		},
	}),

	buttonIcon: css({
		cursor: 'pointer',
		color: '$neutral130',

		'&:hover': {
			color: '$primary',
		},
	}),
}
