import { css, styled } from '@styles/theme'

export const questionHistoricStyles = {
	containerTable: css({
		width: '100%',
		background: '$neutral20',
		marginBottom: '32px',
		borderRadius: '12px',
	}),

	headTable: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		alignItems: 'center',
		minHeight: '81px',
		marginBottom: '32px',
		paddingLeft: '10%',
		borderBottom: '2px solid $neutral40',

		h4: {
			_font: 'normal',
			fontWeight: '700',
			fontSize: '16px',
			lineHeight: '21px',
			letterSpacing: '0.05em',
			color: '$neutral130',
		},
	}),

	bodyTable: css({
		display: 'flex',
		flexDirection: 'column',
		gap: '32px',
		padding: '23px 0px',
	}),

	rowTable: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		alignItems: 'center',
		paddingLeft: '10%',

		h4: {
			_font: 'normal',
			fontWeight: '500',
			fontSize: '$md',
			lineHeight: '21px',
			letterSpacing: '0.05em',
			color: '$neutral130',
		},
	}),

	CheckItem: styled('div', {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',

		svg: {
			width: '25px',
			height: '25px',
			background: '$neutral40',
			borderRadius: '6px',
			padding: '3px',
		},

		color: '$blue20',

		variants: {
			error: {
				true: {
					color: '$red',
				},
			},
		},
	}),
	SpanCheck: styled('span', {
		_font: 'normal',
		fontWeight: '500',
		fontSize: '$md',
		lineHeight: '21px',
		letterSpacing: '0.05em',
	}),
}
