import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import { useIsCurrentLink, useIsCurrentLinkCallBack } from '@/hooks'
import { ChevronDown20Regular, ChevronUp20Regular } from '@fluentui/react-icons'
import * as Accordion from '@radix-ui/react-accordion'

import { Paragraph } from '../Typography'
import { menuItemStyles } from './styles'

const {
	MenuItemStitchesComponent,
	IconContainer,
	ActiveIndicator,
	ContainerMenuAccordion,
	ScrollableSection,
} = menuItemStyles

type MenuItemStitchesComponentProps = React.ComponentProps<
	typeof MenuItemStitchesComponent
>

type ItemsProps = {
	route: string
	name: string
	icon: ReactNode
}
interface MenuItemProps extends MenuItemStitchesComponentProps {
	name: string
	icon: ReactNode
	isOpened: boolean
	items?: Array<ItemsProps>
}

const MenuItem = ({
	to,
	name,
	icon,
	isOpened,
	items,
	...rest
}: MenuItemProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const [zoom, setZoom] = useState(100)
	const [scroll, setScroll] = useState(false)

	const { isCurrent } = useIsCurrentLink(to as string)

	const { executeCurrentLink } = useIsCurrentLinkCallBack()

	const isCurrentCallback = useCallback(() => {
		if (items) {
			const teste = items.map((content: ItemsProps) => {
				return executeCurrentLink(content.route)
			})

			return teste.find((current) => current)
		}

		return false
	}, [items, executeCurrentLink])

	const isCurrentAccordion = isCurrent || isCurrentCallback()

	useEffect(() => {
		function getSizes() {
			const currentZoom = Math.round(
				((window.outerWidth - 10) / window.innerWidth) * 100
			)

			setZoom(currentZoom)
		}

		function getHeight() {
			const screenHeight = window.innerHeight < 650

			setScroll(screenHeight)
		}

		window.addEventListener('resize', getSizes, false)
		window.addEventListener('resize', getHeight, false)

		getSizes()
		getHeight()

		return () => {
			window.removeEventListener('resize', getSizes, false)
		}
	}, [])

	return (
		<>
			{items && items?.length > 0 ? (
				<>
					<Accordion.Root type='single' collapsible defaultValue='1'>
						<Accordion.Item value='1'>
							<Accordion.Header>
								<Accordion.Trigger onClick={() => setIsOpen(!isOpen)}>
									<ContainerMenuAccordion
										isActive={isCurrent}
										isOpened={isOpened}
										isOpen={isCurrentAccordion}
									>
										<MenuItemStitchesComponent
											{...rest}
											to={to}
											isActive={isCurrent}
											isOpened={isOpened}
										>
											<IconContainer isActive={isCurrentAccordion}>
												{icon}
											</IconContainer>
											{isOpened &&
												(isCurrentAccordion ? (
													<Paragraph
														paragraphType='p1'
														css={{ color: '$primary', fontWeight: '$bold' }}
													>
														{name}
													</Paragraph>
												) : (
													<Paragraph
														paragraphType='p1'
														css={{ color: '$neutral90' }}
													>
														{name}
													</Paragraph>
												))}
										</MenuItemStitchesComponent>
										<button type='button'>
											{isOpen ? (
												<ChevronUp20Regular />
											) : (
												<ChevronDown20Regular />
											)}
										</button>
										{isCurrent && <ActiveIndicator />}
									</ContainerMenuAccordion>
								</Accordion.Trigger>
							</Accordion.Header>
							<ScrollableSection zoomed={zoom > 130 || scroll}>
								<Accordion.Content>
									{items.map(
										({ name: nameItem, icon: iconItem, route }, index) => {
											const isCurrentItem = executeCurrentLink(route)

											return (
												<MenuItemStitchesComponent
													{...rest}
													to={route}
													isActive={isCurrentItem}
													isOpened={isOpened}
													isAccordionItem
													key={index}
												>
													<IconContainer isActive={isCurrentItem}>
														{iconItem}
													</IconContainer>
													{isOpened &&
														(isCurrentItem ? (
															<Paragraph
																paragraphType='p1'
																css={{
																	color: '$primary',
																	fontWeight: '$bold',
																}}
															>
																{nameItem}
															</Paragraph>
														) : (
															<Paragraph
																paragraphType='p1'
																css={{ color: '$neutral90' }}
															>
																{nameItem}
															</Paragraph>
														))}
													{isCurrentItem && <ActiveIndicator />}
												</MenuItemStitchesComponent>
											)
										}
									)}
								</Accordion.Content>
							</ScrollableSection>
						</Accordion.Item>
					</Accordion.Root>
				</>
			) : (
				<MenuItemStitchesComponent
					{...rest}
					to={to}
					isActive={isCurrent}
					isOpened={isOpened}
				>
					<IconContainer isActive={isCurrent}>{icon}</IconContainer>
					{isOpened &&
						(isCurrent ? (
							<Paragraph
								paragraphType='p1'
								css={{ color: '$primary', fontWeight: '$bold' }}
							>
								{name}
							</Paragraph>
						) : (
							<Paragraph paragraphType='p1' css={{ color: '$neutral90' }}>
								{name}
							</Paragraph>
						))}
					{isCurrent && <ActiveIndicator />}
				</MenuItemStitchesComponent>
			)}
		</>
	)
}

export { MenuItem }
