import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import {
	ListRankingResponse,
	ListRankingResquest,
	SimulateSelectResponse,
	ListSimulatedResponse,
} from '@models/ranking.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const rankingAPI = createApi({
	reducerPath: 'rankingAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		filter: builder.mutation<ListSimulatedResponse, void>({
			query: () => ({
				url: `/mockCategories/all`,
				method: 'GET',
			}),
		}),
		list: builder.mutation<ListRankingResponse, ListRankingResquest>({
			query: ({ page, limit }) => ({
				url: `/client/ranking/?limit=${limit}&page=${page}`,
				method: 'GET',
			}),
		}),
		get: builder.mutation<ListRankingResponse, ListRankingResquest>({
			query: ({ id, page, limit }) => ({
				url: `/mockCategories/ranking?mockId=${id}&page=${page}&limit=${limit}`,
				method: 'GET',
			}),
		}),
		getSimulateSelect: builder.mutation<SimulateSelectResponse, void>({
			query: () => ({
				url: 'mockCategories/all',
				method: 'GET',
			}),
		}),
	}),
})
