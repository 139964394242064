import { stitchesConfig } from './stitches.config'

export const light = stitchesConfig.createTheme({
	colors: {
		primary: '#6BBBDC',
		blue20: '#29A0D2',
		blue30: '#3E5E74',
		blue40: '#023066',
		orange10: '#FF9862',
		orange20: '#FC6719',
		orange30: '#CE500F',
		orange40: '#B03D01',
		neutral10: '#FDFDFD',
		neutral20: '#FBFBFB',
		neutral30: '#F6F6F6',
		neutral40: '#EDEEF3',
		neutral50: '#DFDEDE',
		neutral60: '#C4C4C4',
		neutral70: '#A4A7AB',
		neutral80: '#909496',
		neutral90: '#86898B',
		neutral100: '#4C4C4C',
		neutral110: '#3F454B',
		neutral120: '#2E3135',
		neutral130: '#202428',
		neutral140: '#252F3F',
		neutral150: '#141414',
		neutral160: '#0C0C0C',
		courseCardBackground: '#E8F5FF',
		gold: '#F29D1D',
		siler: '#A7A4A0',
		bronze: '#9D6C3D',
		green: '#219653',
		yellow: '#F2C94C',
		red: '#C74242',
		disabled: '#DFDEDE',
		text: '#FDFDFD',
		modalOverlay: 'rgba(0, 0, 0, 0.3);',
	},
})
