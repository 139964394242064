import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface PremiumValue {
	value: boolean
}

const initialState: PremiumValue = {
	value: false,
	// comportamento default abaixo
	/* 	value: storage.get('sideBar') === 'true' ? 'false' : 'true', */
}

export const premiumSlice = createSlice({
	name: 'premium',
	initialState,
	reducers: {
		setIsPremium: (
			state,
			{ payload: { value } }: PayloadAction<{ value: boolean }>
		) => {
			state.value = value
		},
	},
})

export const premiumCreators = premiumSlice.actions

export const premiumReducer = premiumSlice.reducer
