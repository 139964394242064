import React, { useEffect, useRef, useState } from 'react'

import { successMessages } from '@/constants/feedbackMessages'
import { AddCircle28Filled } from '@fluentui/react-icons'
import { AvatarProps as AvatarPropss } from '@radix-ui/react-avatar'
import { dashboardAPI } from '@services/DashboardAPI'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'

import { avatarStyles } from './styles'

const { AvatarFallback, AvatarImage, AvatarName, AvatarRoot, ButtonUpload } =
	avatarStyles

interface AvatarProps extends AvatarPropss {
	src?: string
	alt: string
	name: string
	showName?: boolean
	changeImage?: boolean
}

export function Avatar({
	src,
	alt,
	name,
	showName = true,
	changeImage = false,
}: AvatarProps) {
	const dispatch = useAppDispatch()

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [file, setFile] = useState<File>()
	const [imageUrl, setImageUrl] = useState<string>('')
	const inputRef = useRef<HTMLInputElement>(null)

	const [editPhoto, { isSuccess }] = dashboardAPI.useEditPhotoMutation()

	const onImageUpload = (File: File) => {
		editPhoto({ photo: File })
	}

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files?.[0]

		if (selectedFile) {
			setFile(selectedFile)
			onImageUpload(selectedFile)
			setImageUrl(URL.createObjectURL(selectedFile))
		}
	}

	const handleClick = () => {
		if (inputRef.current) {
			inputRef.current.click()
		}
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(notificationCreators.show(successMessages.editPhoto))
		}
	}, [dispatch, isSuccess])

	return (
		<div
			style={{
				display: 'flex',
				gap: '8px',
				position: 'relative',
				alignItems: 'center',
			}}
		>
			<AvatarRoot>
				<AvatarImage src={imageUrl || src} alt={alt} />

				<AvatarFallback delayMs={600}>
					{name.charAt(0).toUpperCase()}
				</AvatarFallback>
			</AvatarRoot>

			{changeImage && (
				<ButtonUpload type='button' onClick={handleClick}>
					<AddCircle28Filled />
				</ButtonUpload>
			)}

			{showName && <AvatarName>{name}</AvatarName>}

			<input
				ref={inputRef}
				type='file'
				onChange={handleFileUpload}
				accept='image/jpeg, image/png'
				style={{ display: 'none' }}
			/>
		</div>
	)
}
