import { styled } from '@styles/theme'

export const styleProgressbar = {
	Container: styled('div', {
		width: '100%',
		height: '16px',
		backgroundColor: '$neutral10',
		borderRadius: '10px',
		overflow: 'hidden',
		border: '1px solid $neutral50',
	}),

	Progress: styled('div', {
		height: '100%',
		backgroundColor: '$blue20',
	}),
}
