import { css, styled } from '@styles/theme'

export const tableHistoricStyles = {
	container: css({
		padding: '27px 36px',

		height: '100%',
		minHeight: '280px',
		width: '100%',

		background: '$neutral10',
		border: '2px solid $neutral50',
		borderRadius: '12px',
		overflowX: 'auto',
	}),
	Table: styled('table', {
		width: '100%',

		'& .custom-head': {
			borderBottom: '1px solid $neutral50',

			display: 'grid',
			gridTemplateColumns:
				'minmax(100px, 45%) minmax(100px, 30%) minmax(90px, 15%) repeat(2, minmax(50px, 5%))',
			gridTemplateAreas: `'name week date value time'`,
			alignItems: 'center',
			minHeight: '37px',
			padding: '8px 20px',
			marginBottom: '16px',
			marginTop: '17px',

			_font: 'normal',
			fontWeight: 400,
			fontSize: '$md',
			lineHeight: '16px',
			letterSpacing: '0.01em',
			color: '$neutral130',
			textTransform: 'uppercase',
		},

		'& .custom-body': {},

		'& .custom-row': {
			display: 'grid',
			gridTemplateColumns:
				'minmax(100px, 45%) minmax(100px, 30%) minmax(90px, 15%) repeat(2, minmax(50px, 5%))',
			gridTemplateAreas: `'name week date value time'`,
			alignItems: 'center',
			minHeight: '38px',
			padding: '10px 21px',

			_font: 'normal',
			fontWeight: 400,
			fontSize: '$md',
			lineHeight: '16px',
			color: '$neutral90',

			'&:nth-of-type(odd)': {
				background: '$neutral30',
			},
		},
	}),
	containerTitle: css({
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		minWidth: '542px',
	}),
	link: css({
		_font: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '18px',
		letterSpacing: '0.04em',
		textDecorationLine: 'underline',
		color: '$blue20',
		cursor: 'pointer',
	}),
}
