import { css } from '@styles/theme'

export const titleStyles = {
	base: {
		h1: css({
			fontSize: '$xxxl',
			lineHeight: '19px',
			fontWeight: 500,

			media: {
				md: {
					fontSize: '$xl',
				},
			},
		}),
		h2: css({
			fontSize: '$xxl',
			fontWeight: 500,
			lineHeight: '19px',

			media: {
				md: {
					fontWeight: 400,
					fontSize: '$xl',
				},
			},
		}),
		h3: css({
			fontSize: '$xl',
			fontWeight: 400,
			lineHeight: '$xl',
			letterSpacing: '0.04em',

			media: {
				md: {
					fontWeight: 700,
					fontSize: '$lg',
				},
			},
		}),
		h4: css({
			_font: 'normal',
			fontSize: '$xl',
			fontWeight: 500,
			lineHeight: '19px',

			media: {
				md: {
					fontSize: '$lg',
				},
			},
		}),
		h5: css({
			fontSize: '$xl',
			fontWeight: 400,
			lineHeight: '21px',
			letterSpacing: '0.05em',

			media: {
				md: {
					fontWeight: 500,
					fontSize: '$md',
				},
			},
		}),
		h6: css({
			fontSize: '$lg',
			fontWeight: 700,
			lineHeight: '19px',

			media: {
				md: {
					fontWeight: 400,
					fontSize: '$md',
					lineHeight: '20px',
				},
			},
		}),
	},
}
