import { css, styled } from '@styles/theme'

export const tagsStyles = {
	containerTagsActions: css({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: '7px 16px',
		gap: '13px',

		width: '100%',
		maxWidth: '426px',
		minHeight: '36px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '8px',

		h5: {
			_font: 'normal',
			fontSize: '$md',
			letterSpacing: '0.05em',
			color: '$neutral160',
		},
	}),
	containerTags: css({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: '10px',
		width: '89%',
	}),
	TagAction: styled('div', {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '0px 8px',
		gap: '4px',

		width: 'fit-content',
		height: '21px',

		background: '$neutral30',
		borderRadius: '12px',

		_font: 'normal',
		fontSize: '$md',
		lineHeigh: '21px',
		letterSpacing: '0.05em',

		color: '$neutral90',

		svg: {
			cursor: 'pointer',
			color: '$primary',
		},
	}),
	buttonCloseAll: css({
		cursor: 'pointer',
		color: '$primary',
	}),
}
