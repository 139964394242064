import React from 'react'
import { Navigate } from 'react-router-dom'

import { authCreators } from '@store/ducks/auth'
import { useAppDispatch } from '@store/hooks'

export function Logout() {
	const dispatch = useAppDispatch()

	localStorage.clear()
	dispatch(authCreators.logout())

	return <Navigate to='/' />
}
