import React from 'react'

import { Checkmark12Regular } from '@fluentui/react-icons'

import { checkboxStyles } from './style'

const { CheckboxRoot, CheckboxIndicator, Flex, Label } = checkboxStyles

type StitchesProps = React.ComponentProps<typeof CheckboxRoot>
interface CheckBoxProps extends StitchesProps {
	label?: string
	checked?: boolean
}

export const CheckBox = ({ label, checked, ...props }: CheckBoxProps) => (
	<Flex css={{ alignItems: 'center' }}>
		<CheckboxRoot {...props} checked={checked}>
			<CheckboxIndicator>
				<Checkmark12Regular />
			</CheckboxIndicator>
		</CheckboxRoot>
		<Label css={{ paddingLeft: 15 }} htmlFor='c1'>
			{label}
		</Label>
	</Flex>
)
