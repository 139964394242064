import { LocalStorage } from '@helpers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
	signed: boolean
	token: string | null
	email: string
	step: 'verify' | 'authenticate'
	newSession: boolean
}

const initialState: AuthState = {
	signed: false,
	token: null,
	newSession: false,
	email: '',
	step: 'verify',
}

const storage = new LocalStorage()

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setStep: (
			state,
			{ payload: { step } }: PayloadAction<{ step: 'verify' | 'authenticate' }>
		) => {
			state.step = step
		},

		setNewSession: (state) => {
			state.newSession = true
		},

		authenticate: (
			state,
			{ payload: { token } }: PayloadAction<{ token: string }>
		) => {
			storage.set('token', token)

			state.signed = true
			state.token = token
		},

		logout: (state) => {
			state.signed = false
			state.token = null

			storage.remove('token')
		},
	},
})

export const authCreators = authSlice.actions

export const authReducer = authSlice.reducer
