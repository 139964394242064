import { css } from '@styles/theme'

export const searchStyles = {
	base: css({
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		maxWidth: '217px',
		minWidth: '150px',
		height: '48px',
		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',
		color: '$neutral160',
		fontSize: '$md',
		padding: '12px 16px',

		'& input': {
			width: '100%',
		},

		svg: {
			color: '#86898B',
			width: '20px',
			height: '20px',
		},
	}),
}
