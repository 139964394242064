type Browsers = {
	[key: string]: RegExp
}

// Função para obter o nome do navegador
export const getBrowserName = (userAgent: string): string => {
	const browsers: Browsers = {
		Chrome: /Chrome/i,
		Safari: /Safari/i,
		Firefox: /Firefox/i,
		Edge: /Edg/i,
		IE: /Trident/i,
	}

	// eslint-disable-next-line no-restricted-syntax
	for (const browser in browsers) {
		if (browsers[browser].test(userAgent)) {
			return browser
		}
	}

	return 'Desconhecido'
}

export const getBrowserVersion = (userAgent: string): string => {
	const versionRegex = /(Chrome|Safari|Firefox|Edg|Trident).*?([\d.]+)/i
	const matches = userAgent.match(versionRegex)

	return matches && matches.length >= 3 ? matches[2] : 'Desconhecida'
}
