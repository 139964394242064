import {
	MiddlewareAPI,
	isRejectedWithValue,
	Middleware,
} from '@reduxjs/toolkit'
import { errorCreators } from '@store/ducks/error'
import { modalCreators } from '@store/ducks/modal'

/**
 * Log a warning and show a toast!
 */
export const errorMiddleware: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		if (isRejectedWithValue(action)) {
			// treat error without modal
			if (['login'].includes(action.meta.arg.endpointName)) {
				api.dispatch(
					errorCreators.set({ message: action.payload.data.message })
				)

				return next(action)
			}

			if (action.payload.status === 401) {
				window.location.assign('/logout')
			}

			if (action.payload.status === 406) {
				return api.dispatch(
					modalCreators.openModal({
						name: 'freeLimit',
						modalInfo: {
							title: 'Você atingiu o limite diário de questões gratuitas!',
							button: {
								url: 'https://www.decorandoaleiseca.com.br/assinaturas/membro-premium',
								label: 'Quero ser Premium',
								icon: 'premium',
							},
						},
					})
				)
			}

			// treat error with modal
			const error = {
				title: 'Ocorreu um erro',
				buttonLabel: 'Fechar',
				description: (() => {
					if (action.payload.data?.errors) {
						return action.payload.data.errors[0].message
					}

					if (Array.isArray(action.payload.data?.message?.errors)) {
						return action.payload.data?.message?.errors[0]?.message
					}

					if (action.payload.data?.message) {
						return action.payload.data?.message
					}

					if (action.payload.data?.error) {
						return action.payload.data?.error
					}

					if (action.payload.data?.length) {
						return action.payload.data[0].message
					}

					if (action.payload.data?.errorMessage) {
						return action.payload.data?.errorMessage
					}

					if (action.payload.data?.err) {
						return 'Problema na validação (dados inconsistentes)'
					}

					if (action.payload.status === 404) {
						return 'Ocorreu um erro desconhecido: Não encontrado'
					}

					return 'Ocorreu um erro desconhecido'
				})(),
				type: 'error' as const,
			}

			api.dispatch(modalCreators.openAlert(error))
		}

		return next(action)
	}
