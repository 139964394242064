import { LocalStorage } from '@helpers'
import { createSlice } from '@reduxjs/toolkit'

interface ThemeState {
	value: string | null
}

const storage = new LocalStorage()

const initialState: ThemeState = {
	value: storage.get('theme'),
}

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme: (state, { payload: value }) => {
			state.value = value
			storage.set('theme', value)
		},
	},
})

export const themeCreators = themeSlice.actions

export const themeReducer = themeSlice.reducer
