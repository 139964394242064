import { styled } from '@styles/theme'

export const cardStatsStyles = {
	Container: styled('div', {
		padding: '27px 18px',

		height: '145px',
		width: '100%',

		background: '$neutral10',
		border: '2px solid $neutral50',
		borderRadius: '12px',

		variants: {
			mobile: {
				true: {
					maxWidth: '100%',
					minWidth: '0px',
					padding: '8px',
				},
			},
		},
	}),
	Icon: styled('div', {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '46.34px',
		height: '36px',
		borderRadius: '8px',

		svg: {
			width: '27px',
			height: '27px',
		},

		variants: {
			color: {
				default: {
					background:
						'linear-gradient(180deg, rgba(145, 181, 255, 0.12) 0%, rgba(85, 112, 241, 0) 118.06%)',
					svg: {
						color: '$blue20',
					},
				},
				correct: {
					background:
						'linear-gradient(180deg, rgba(0, 125, 80, 0.12) 0%, rgba(85, 112, 241, 0) 118.06%)',
					svg: {
						color: '$green',
					},
				},
				wrong: {
					background:
						'linear-gradient(180deg, rgba(255, 0, 0, 0.12) 0%, rgba(85, 112, 241, 0) 118.06%);',
					svg: {
						color: '$red',
					},
				},
				average: {
					background:
						'linear-gradient(180deg, rgba(252, 103, 25, 0.12) 0%, rgba(85, 112, 241, 0) 118.06%)',
					svg: {
						color: '$orange20',
					},
				},
			},
		},
	}),
	Info: styled('div', {
		position: 'relative',
		top: '-27px',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '77px',

		textAlign: 'center',
		color: '$neutral160',
		h4: {
			_font: 'bold',
			fontWeight: '700',
			fontSize: '34px',
			lineHeight: '40px',
		},
		p: {
			_font: 'normal',
			fontSize: '16px',
			lineHeight: 'normal',
			fontWeight: '400',
		},

		variants: {
			mobile: {
				true: {
					top: '0px',
				},
			},
		},
	}),
}
