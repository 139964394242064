import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import {
	AllClientModulesResponse,
	AllModulesContentResponse,
	CommentContentRequest,
	ModuleContentFinishRequest,
	ModuleContentShowResponse,
	PdfRequest,
	ListCoursesResquest,
	FavoriteRequest,
} from '@models/course.model'
import { AllCommentsResponse } from '@models/questions.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const coursesAPI = createApi({
	reducerPath: 'coursesAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		getAllClientModules: builder.mutation<
			AllClientModulesResponse,
			ListCoursesResquest
		>({
			query: ({ page = '1', limit = '10', search = '' }) => ({
				url: `client-modules?page=${page}&limit=${limit}&search=${search}`,
				method: 'GET',
			}),
		}),

		getAllModulesContent: builder.mutation<
			AllModulesContentResponse,
			string | number
		>({
			query: (id) => ({
				url: `module-contents?moduleId=${id}&page=1&limit=10`,
				method: 'GET',
			}),
		}),

		getModuleContentShow: builder.mutation<
			ModuleContentShowResponse,
			string | number
		>({
			query: (id) => ({
				url: `module-contents/${id}?page=1&limit=10`,
				method: 'GET',
			}),
		}),

		getFilePdf: builder.mutation<Blob, PdfRequest>({
			query: ({ name, fileId }) => ({
				url: `downloadPdf?pdf=${name}&fileId=${fileId}`,
				method: 'GET',
				responseType: 'blob',
				cache: 'no-cache',
				responseHandler: (response) => response.blob(),
			}),
		}),

		getCommentsContent: builder.mutation<AllCommentsResponse, string | number>({
			query: (contentID) => ({
				url: `module/comment/${contentID}`,
				method: 'GET',
			}),
		}),

		getLastsAccessedClass: builder.mutation<void, string | number>({
			query: (contentID) => ({
				url: `module-contents/${contentID}`,
				method: 'GET',
			}),
		}),

		createFavoriteClass: builder.mutation<void, FavoriteRequest>({
			query: (body: FavoriteRequest) => ({
				url: `favorite/client-classes`,
				method: 'POST',
				body,
			}),
		}),

		postContentFinished: builder.mutation<void, ModuleContentFinishRequest>({
			query: (body: ModuleContentFinishRequest) => ({
				url: `module-contents/${body.contentId}/mark-as-finished`,
				method: 'POST',
				body: { isFinished: body.isFinished },
			}),
		}),

		createCommentContent: builder.mutation<void, CommentContentRequest>({
			query: (body: CommentContentRequest) => ({
				url: `module/comment`,
				method: 'POST',
				body,
			}),
		}),
		createReportErrorContent: builder.mutation<void, CommentContentRequest>({
			query: (body: CommentContentRequest) => ({
				url: `module/error`,
				method: 'POST',
				body,
			}),
		}),

		deleteCommentContent: builder.mutation<void, number>({
			query: (commentId) => ({
				url: `module/comment/${commentId}`,
				method: 'DELETE',
			}),
		}),
	}),
})
