import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { successMessages } from '@/constants/feedbackMessages'
import { DismissCircle24Regular } from '@fluentui/react-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { commentSchema } from '@schemas/questions/questions.schema'
import { questionsAPI } from '@services/QuestionsAPI'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import moment from 'moment'

import { Button } from '../Button'
import { ModalBase } from '../ModalBase'
import { TextArea } from '../TextArea'
import { Paragraph, Small, Title } from '../Typography'
import { questionReminderStyles } from './styles'

const {
	containerReminder,
	commentDiv,
	commentButton,
	containerTextArea,
	containerReminders,
	buttonDelete,
	containerModalDelete,
	buttonsModalDelete,
} = questionReminderStyles

type FormCommentValue = {
	message: string
}

type QuestionInfo = {
	id: number
}

export function QuestionReminder({ id }: QuestionInfo) {
	const [openModalDeleteReminder, setOpenModalDeleteReminder] = useState(false)
	const [idDeletedReminder, setIdDeletedReminder] = useState<number>()

	const dispatch = useAppDispatch()

	const {
		register,
		handleSubmit,
		reset: resetComment,
		formState: { errors },
	} = useForm<FormCommentValue>({ resolver: yupResolver(commentSchema) })

	const [
		getQuestionReminder,
		{ data: dataQuestionReminder, isSuccess: isSuccessQuestionReminder },
	] = questionsAPI.useGetQuestionRemindersMutation()

	const [createQuestionReminder] =
		questionsAPI.usePostReminderQuestionMutation()

	const [deleteReminder, { isSuccess: isSuccessDeleteReminder }] =
		questionsAPI.useDeleteQuestionReminderMutation()

	const onSubmitComment = handleSubmit((payload) => {
		createQuestionReminder({
			questionId: id,
			message: payload.message,
		}).then(() => {
			resetComment()
			getQuestionReminder(id)
		})
	})

	const handleDeleteReminder = async () => {
		if (idDeletedReminder) {
			deleteReminder(idDeletedReminder)
		}
	}

	useEffect(() => {
		getQuestionReminder(id)
	}, [getQuestionReminder, id])

	useEffect(() => {
		if (isSuccessDeleteReminder) {
			setOpenModalDeleteReminder(false)
			getQuestionReminder(id)
			dispatch(notificationCreators.show(successMessages.deletedReminder))
		}
	}, [dispatch, getQuestionReminder, id, isSuccessDeleteReminder])

	return (
		<>
			<form className={containerReminder()} onSubmit={onSubmitComment}>
				<div className={commentDiv()}>
					<TextArea
						placeholder='Escreva seu lembrete'
						css={{
							minHeight: '87px',
							borderColor: '$neutral30',
							background: '$neutral30',
						}}
						error={errors.message?.message}
						{...register('message')}
					/>
				</div>
				<div className={commentButton()}>
					<Button css={{ '@md': { maxWidth: '219px' } }}>Salvar</Button>
				</div>

				{isSuccessQuestionReminder &&
					dataQuestionReminder &&
					dataQuestionReminder?.reminders.map((reminder, indexKey) => (
						<div className={containerReminders()} key={indexKey}>
							<div className={commentDiv()}>
								<div className={containerTextArea()}>
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<div
											style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}
										>
											<Small smallType='small1' css={{ color: '$neutral70' }}>
												{moment(reminder.date).locale('pt-br').format('LLL')}
											</Small>
										</div>

										<div>
											<button
												type='button'
												className={buttonDelete()}
												onClick={() => {
													setOpenModalDeleteReminder(true)
													setIdDeletedReminder(reminder.id)
												}}
											>
												<DismissCircle24Regular />
												<Small smallType='small1' css={{ color: '$red' }}>
													Excluir
												</Small>
											</button>
										</div>
									</div>

									<Paragraph paragraphType='p2' css={{ color: '$neutral120' }}>
										{reminder.message}
									</Paragraph>
								</div>
							</div>
						</div>
					))}
			</form>

			<ModalBase
				title=''
				open={openModalDeleteReminder}
				onOpenChange={setOpenModalDeleteReminder}
			>
				<div className={containerModalDelete()}>
					<Title variant='h2' color='$neutral120'>
						Deseja excluir seu lembrete?
					</Title>

					<div className={buttonsModalDelete()}>
						<Button color='danger' onClick={handleDeleteReminder}>
							Excluir
						</Button>
						<Button
							outlined
							color='secondary'
							onClick={() => {
								setOpenModalDeleteReminder(false)
							}}
						>
							Cancelar
						</Button>
					</div>
				</div>
			</ModalBase>
		</>
	)
}
