import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

import { Title as TitleTypo } from '../Typography'
import { chartsDoughnutStyles } from './style'

const { Container, containerChart } = chartsDoughnutStyles

ChartJS.register(ArcElement, Tooltip, Legend)

export const options = {
	responsive: true,
	maintainAspectRatio: false,
}

type StitchesProps = React.ComponentProps<typeof Container>
interface PropsChartDoughnut extends StitchesProps {
	hits: number
	errors: number
	showTitle?: boolean
}

export const ChartDoughnut = ({
	hits = 0,
	errors = 0,
	showTitle = true,
	...props
}: PropsChartDoughnut) => {
	const labels = ['Acertos', 'Erros']

	const refinedDate = {
		labels,
		datasets: [
			{
				label: '',
				data: [hits, errors],
				backgroundColor: ['#219653', '#C74242'],
				boxShadown: '0px 7.60221px 11.4033px rgba(135, 145, 233, 0.3)',
				borderWidth: 0,
			},
		],
	}

	return (
		<Container {...props}>
			{showTitle && (
				<TitleTypo variant='h5' color='$neutral160'>
					Percentual de rendimento geral
				</TitleTypo>
			)}
			<div className={containerChart()}>
				<Doughnut options={options} data={refinedDate} />
			</div>
		</Container>
	)
}
