import { shareType } from '@models/share.model'

export const handleShareLink = ({ type, id }: shareType) => {
	try {
		if ('clipboard' in navigator) {
			navigator.clipboard.writeText(
				`https://app.decorandoaleiseca.app/compartilhamento?type=${type}&id=${id}`
			)
		} else {
			document.execCommand(
				'copy',
				true,
				`https://app.decorandoaleiseca.app/compartilhamento?type=${type}&id=${id}`
			)
		}
	} catch (e) {
		/* empty */
	}
}
