import { useState, useCallback, useEffect } from 'react'
import { useLocation, useResolvedPath } from 'react-router-dom'

export default function useIsCurrentLinkCallBack() {
	const [state, setState] = useState('')

	const resolved = useResolvedPath(state)

	const { pathname: currentPathname } = useLocation()

	useEffect(() => {
		setState(currentPathname)
	}, [currentPathname, setState])

	const executeCurrentLink = useCallback(
		(pathname: string) => {
			const parts = pathname.split('/').filter(Boolean)
			const currentPage = parts[0]

			const isCurrent =
				currentPage === undefined && resolved.pathname === '/'
					? true
					: `/${currentPage}` === resolved.pathname

			return isCurrent
		},
		[resolved.pathname]
	)

	return { executeCurrentLink }
}
