import React, { Dispatch, SetStateAction, forwardRef } from 'react'

import { LockClosed16Filled } from '@fluentui/react-icons'
import { RadioGroup } from '@headlessui/react'
import { FilterStateProps } from '@hooks/useQuestion'

import { tagsStyles } from './styles'

const { radioGroupOption, containerTags } = tagsStyles

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StitchesProps = React.ComponentProps<any>
type OptionsProps = { label: string; value: number | object | string }

interface TagsProps extends StitchesProps {
	options?: Array<OptionsProps>
	disabled?: boolean
	value: Array<OptionsProps> | []
	onChange: Dispatch<SetStateAction<FilterStateProps>>
	name: string
}

export const Tags = forwardRef<HTMLInputElement, TagsProps>(
	({ options, disabled, value, onChange, name, ...props }, ref) => {
		return (
			<RadioGroup
				value={value}
				onChange={(item) => {
					onChange((prevState: FilterStateProps) => ({
						...prevState,
						[name]: item,
					}))
				}}
				ref={ref}
				disabled={disabled}
				{...props}
			>
				<div className={containerTags()}>
					{options?.map((item: OptionsProps) => (
						<RadioGroup.Option
							key={item.label}
							value={item.value}
							className={({ active, checked, disabled: disabledItem }) =>
								radioGroupOption({ checked, active, disabledItem })
							}
						>
							{item.label}
							{disabled && <LockClosed16Filled />}
						</RadioGroup.Option>
					))}
				</div>
			</RadioGroup>
		)
	}
)
