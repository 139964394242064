import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { styled } from '@styles/theme'

export const avatarStyles = {
	AvatarRoot: styled(AvatarPrimitive.Root, {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		verticalAlign: 'middle',
		overflow: 'hidden',
		userSelect: 'none',
		width: 40,
		height: 40,
		borderRadius: '100%',
		backgroundColor: '$blue20',
		border: '1px solid $blue20 ',
	}),

	AvatarImage: styled(AvatarPrimitive.Image, {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: 'inherit',
	}),

	AvatarFallback: styled(AvatarPrimitive.Fallback, {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '$neutral10',
		_font: 'normal',
		fontWeight: '500',
		fontSize: '$xxxl',
	}),

	AvatarName: styled('div', {
		width: 'inherit',
		_font: 'medium',
		fontWeight: '500',
		fontSize: '$lg',
		color: '$neutral160',
	}),

	ButtonUpload: styled('button', {
		width: '28px',
		height: '28px',
		position: 'absolute',
		top: '-3px',
		right: '-11px',
		borderRadius: '100%',
		cursor: 'pointer',
		background: '$neutral10',

		color: '$neutral80',

		'&:hover': {
			color: '$blue20',
		},
	}),
}
