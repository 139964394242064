import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import {
	AllFilterClientResponse,
	CreateFilterClientRequest,
	CreateFilterClientResponse,
	CreateFilterInFolderRequest,
	CreateFolderFilterClientRequest,
	EditFilterClientRequest,
	FolderClientResponseType,
	FolderFiltersResponse,
	OneFiltterClientResponse,
} from '@models/filter.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const filterAPI = createApi({
	reducerPath: 'filterAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		getAllFilterClient: builder.mutation<AllFilterClientResponse, void>({
			query: () => ({
				url: 'filter/client',
				method: 'GET',
			}),
		}),

		getAllFolderClient: builder.mutation<Array<FolderClientResponseType>, void>(
			{
				query: () => ({
					url: 'folder/filter/',
					method: 'GET',
				}),
			}
		),

		getFolderFilterClientShow: builder.mutation<FolderFiltersResponse, string>({
			query: (folderId) => ({
				url: `folder/filter/${folderId}`,
				method: 'GET',
			}),
		}),

		getOneFilterClient: builder.mutation<
			OneFiltterClientResponse,
			string | number
		>({
			query: (id) => ({
				url: `filter/client/${id}`,
				method: 'GET',
			}),
		}),

		postCreateFilter: builder.mutation<
			CreateFilterClientResponse,
			CreateFilterClientRequest
		>({
			query: (notebook: CreateFilterClientRequest) => ({
				url: 'filter/client',
				method: 'POST',
				body: notebook,
			}),
		}),

		postCreateFolderFilter: builder.mutation<
			CreateFilterClientResponse,
			CreateFolderFilterClientRequest
		>({
			query: (notebook: CreateFolderFilterClientRequest) => ({
				url: 'folder/filter/',
				method: 'POST',
				body: notebook,
			}),
		}),

		postFilterInFolder: builder.mutation<void, CreateFilterInFolderRequest>({
			query: (notebook: CreateFilterInFolderRequest) => ({
				url: 'filter/folder/',
				method: 'POST',
				body: notebook,
			}),
		}),

		putFilterClient: builder.mutation<void, EditFilterClientRequest>({
			query: (payload) => ({
				url: `filter/client/${payload.id}`,
				method: 'PUT',
				body: { name: payload.name },
			}),
		}),
		putFolderFilterClient: builder.mutation<void, EditFilterClientRequest>({
			query: (payload) => ({
				url: `/folder/filter/${payload.id}`,
				method: 'PUT',
				body: { name: payload.name },
			}),
		}),

		deleteFilterClient: builder.mutation<void, string>({
			query: (id) => ({
				url: `filter/client/${id}`,
				method: 'DELETE',
			}),
		}),

		deleteFolderFilterClient: builder.mutation<void, string>({
			query: (id) => ({
				url: `/folder/filter/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
})
