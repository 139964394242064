import React from 'react'

import { Search24Regular } from '@fluentui/react-icons'
import { styled } from '@styles/theme'

import { searchStyles } from './styles'

const Search = styled('div', searchStyles.base)

interface SearchInputProps extends React.ComponentProps<typeof Search> {
	onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
	placeholder?: string
	value?: string
}

export default function SearchInput({
	onChange,
	placeholder = '',
	value,
	...props
}: SearchInputProps) {
	return (
		<Search {...props}>
			<input placeholder={placeholder} onChange={onChange} value={value} />
			<Search24Regular />
		</Search>
	)
}
