import React, { SetStateAction, useRef } from 'react'

import { Dismiss20Filled } from '@fluentui/react-icons'

import { sideModalStyles } from './style'

const { OverlaySideMenu, SideMenuFilter, headerSideMenu } = sideModalStyles

type SideModalProps = {
	name: string
	open: boolean
	setOpen: (value: SetStateAction<boolean>) => void
	children: JSX.Element
}

export const SideModalBase = ({
	name,
	open,
	setOpen,
	children,
}: SideModalProps) => {
	const ref = useRef<HTMLDivElement>(null)

	return (
		<>
			{open && (
				<OverlaySideMenu
					open={open}
					onClick={(event) => {
						if (ref.current === event.target) {
							return setOpen(false)
						}

						return ''
					}}
					ref={ref}
				>
					<SideMenuFilter>
						<div className={headerSideMenu()}>
							<h5>{name}</h5>
							<button
								type='button'
								onClick={() => {
									setOpen(false)
								}}
							>
								<Dismiss20Filled />
							</button>
						</div>
						{children}
					</SideMenuFilter>
				</OverlaySideMenu>
			)}
		</>
	)
}
