import { css } from '@styles/theme'

export const promotionalLPStyles = {
	background: css({
		backgroundColor: '$neutral10',
		width: '100%',
		minHeight: '100vh',

		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		p: {
			color: '$primary',
		},
	}),
	container: css({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		padding: '24px',

		'@lg': {
			width: '50%',
		},

		'& svg': {
			marginBottom: '64px',
		},

		'& .titles': {
			display: 'flex',
			flexDirection: 'column',
			gap: '16px',
			width: '100%',
			maxWidth: '432px',
			marginBottom: '$6',

			h4: {
				_font: 'normal',
				fontSize: '$xxxl',
				lineHeight: '26px',
				color: '$neutral100',
			},

			p: {
				_font: 'normal',
				fontSize: '$xxl',
				color: '$neutral90',
			},
		},

		form: {
			display: 'flex',
			flexDirection: 'column',
			gap: '32px',
			width: '100%',
			maxWidth: '432px',

			button: {
				marginTop: '8px',
			},
		},

		'& .acounts': {
			marginTop: '64px',

			p: {
				_font: 'normal',
				fontSize: '$xl',
				color: '$neutral90',
			},

			div: {
				display: 'flex',
				justifyContent: 'center',
				gap: '31.3px',
				marginTop: '12px',
				'& .buttons-login': {
					cursor: 'pointer',
					paddingBottom: '0px',
					paddingRight: '0px',
					marginBottom: '0px',
				},
			},
		},

		'& .link': {
			marginTop: '48px',
			_font: 'normal',
			fontSize: '$lg',
			textDecorationLine: 'underline',
			cursor: 'pointer',
			color: '$blue30',
		},

		h5: {
			_font: 'normal',
			fontSize: '$lg',
			lineHeight: '21px',
			color: '$neutral90',
			marginTop: '32px',
		},

		'& .modal-email': {
			maxWidth: 'calc(327px - 48px)',

			button: {
				marginTop: '47px',
			},
		},
	}),
}
