import { styled } from '@styles/theme'

export const chartsHistoricStyles = {
	Container: styled('div', {
		padding: '27px 18px',

		height: '342px',
		width: '100%',

		background: '$neutral10',
		border: '2px solid $neutral50',
		borderRadius: '12px',
	}),
}
