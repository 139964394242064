import { css, styled } from '@styles/theme'

export const switchStyles = {
	switchContainer: css({
		background: '$neutral10',
		border: '1px solid $neutral60',
		borderRadius: '16px',
		height: '19px',
		width: '38px',
		cursor: 'pointer',
	}),

	switchContainerSelected: css({
		background: '$neutral40',
		border: '1px solid $neutral60',
		borderRadius: '16px',
		height: '19px',
		width: '38px',
		cursor: 'pointer',
	}),

	SwitchSpan: styled('span', {
		display: 'block',
		background: '$neutral70',
		boxShadow: '0px 1px 5px $neutral140',
		borderRadius: '16px',
		width: '18px',
		height: '18px',

		transitionTimingFunction: 'ease-in-out',
		transitionDuration: '0.3s',

		variants: {
			enabled: {
				true: {
					transform: 'translateX(19px)',
					background: '$blue20',
				},
			},
		},
	}),
}
