import React, { forwardRef } from 'react'

import { styled } from '@styles/theme'

import { textAreaStyles } from './styles'

const { TextAreaStyles, errorStyles, wrapper } = textAreaStyles

const TextAreaStitchesComponent = styled('textarea', TextAreaStyles.base)

const Error = styled('span', errorStyles.base)

type StitchesProps = React.ComponentProps<typeof TextAreaStitchesComponent>
interface TextAreaStitchesComponentProps extends StitchesProps {
	error?: string
}

const TextArea = forwardRef<
	HTMLTextAreaElement,
	TextAreaStitchesComponentProps
>(({ defaultValue, error, ...props }, ref) => {
	return (
		<div className={wrapper.base()}>
			<TextAreaStitchesComponent
				{...props}
				ref={ref}
				defaultValue={defaultValue}
				invalid={!!error}
			/>
			{error && <Error>{error}</Error>}
		</div>
	)
})

export { TextArea }
