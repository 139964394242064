import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ErrorState {
	message: string
	show: boolean
}

const initialState: ErrorState = {
	message: '',
	show: false,
}

export const errorSlice = createSlice({
	name: 'error',
	initialState,
	reducers: {
		set: (
			state,
			{
				payload: { message },
			}: PayloadAction<{
				message: string
			}>
		) => {
			state.message = message
			state.show = true
		},

		clear: (state) => {
			state.show = false
		},
	},
})

export const errorCreators = errorSlice.actions

export const errorReducer = errorSlice.reducer
