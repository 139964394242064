import React from 'react'

import {
	DataPie24Regular,
	Checkmark12Regular,
	Dismiss20Filled,
	AppsListDetail20Regular,
} from '@fluentui/react-icons'

import { cardStatsStyles } from './style'

const { Container, Icon, Info } = cardStatsStyles

type PropsModal = {
	value: string | number
	desc: string
	type?: 'default' | 'correct' | 'wrong' | 'average'
	mobile?: boolean
}

const iconList = {
	default: <DataPie24Regular />,
	correct: <Checkmark12Regular />,
	wrong: <Dismiss20Filled />,
	average: <AppsListDetail20Regular />,
}

export const CardStats = ({
	value,
	desc,
	mobile,
	type = 'default',
}: PropsModal) => {
	return (
		<Container mobile={mobile}>
			<Icon color={type}>{iconList[type]}</Icon>
			<Info mobile={mobile}>
				<h4>{value}</h4>
				<p>{desc}</p>
			</Info>
		</Container>
	)
}
