import React from 'react'

import * as Tooltip from '@radix-ui/react-tooltip'

import { stylesTooltip } from './styles'

const { TooltipContent, TooltipArrow } = stylesTooltip

type TooltipBaseProps = {
	text: string
	children?: React.ReactNode
}

export const TooltipBase = ({ text, children }: TooltipBaseProps) => {
	return (
		<Tooltip.Provider>
			<Tooltip.Root>
				<Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
				<Tooltip.Portal>
					<TooltipContent sideOffset={5}>
						{text}
						<TooltipArrow />
					</TooltipContent>
				</Tooltip.Portal>
			</Tooltip.Root>
		</Tooltip.Provider>
	)
}
