import { css } from '@styles/theme'

export const questionBookStyles = {
	containerBook: css({
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		margin: '32px 0px',

		background: '$neutral10',
		borderRadius: '12px',
	}),

	containerDiv: css({
		display: 'flex',
		flexDirection: 'column',
		gap: '29px',
		borderBottom: '1px solid $neutral40',
		paddingBottom: '32px',
		paddingTop: '37.5px',
		padding: '16px',

		'@md': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	}),
	containerDivBook: css({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '29px',
		borderBottom: '1px solid $neutral40',
		paddingBottom: '32px',
		paddingTop: '37.5px',
		padding: '16px',

		'@md': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	}),
	containerNotebook: css({
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: '10px',
		padding: '16px',

		'@md': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	}),
	containerInput: css({
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '12px',

		ul: {
			maxHeight: '400px',
			overflowY: 'scroll',
			height: 'fit-content',

			'&::-webkit-scrollbar': {
				width: '12px',
				background: '$neutral50',
				borderRadius: '0px 0px 14px 0px',
			},
			'&::-webkit-scrollbar-track': {
				width: '12px',
			},
			'&::-webkit-scrollbar-thumb': {
				width: '6px',
				height: '37px',
				border: '3px solid $neutral50',
				background: '$primary',
				borderRadius: '6px',
			},
		},

		'@md': {
			flexDirection: 'row',
			width: '50%',
			gap: '32px',
		},
	}),

	buttonNew: css({
		display: 'flex',
		gap: '8px',

		_font: 'normal',
		fontWeight: '400',
		alignItems: 'center',
		fontSize: '$lg',
		lineHeight: '21px',
		color: '$neutral130',
		cursor: 'pointer',

		svg: {
			width: '14px',
			height: '14px',
		},
	}),
}
