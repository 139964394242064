import React, { useState } from 'react'

import { Filter24Regular } from '@fluentui/react-icons'

import { filterButtonStyles } from './styles'

const { FilterButtonStitcherComponent } = filterButtonStyles

type StitchesButtonProps = React.ComponentProps<
	typeof FilterButtonStitcherComponent
>

interface FilterButtonProps extends StitchesButtonProps {
	children: string
	activeFilter?: boolean
}

const FilterButton = ({
	children = 'Texto de filtro',
	activeFilter = false,
	...props
}: FilterButtonProps) => {
	const [active] = useState(activeFilter)

	return (
		<FilterButtonStitcherComponent active={active} {...props}>
			<p>{children}</p>
			<Filter24Regular />
		</FilterButtonStitcherComponent>
	)
}

export { FilterButton }
