import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { successMessages } from '@/constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { reportErrorContentSchema } from '@schemas/questions/questions.schema'
import { coursesAPI } from '@services/CoursesAPI'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'

import { Button } from '../Button'
import { TextArea } from '../TextArea'
import { questionReportErrorStyles } from './styles'

const { containerReportError, commentButton } = questionReportErrorStyles

type FormCommentValue = {
	message: string
}

type QuestionInfo = {
	id: number
}

export function CourseReportError({ id }: QuestionInfo) {
	const dispatch = useAppDispatch()

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormCommentValue>({
		resolver: yupResolver(reportErrorContentSchema),
	})

	const [createReportErrorContent, { isLoading, isSuccess }] =
		coursesAPI.useCreateReportErrorContentMutation()

	const onSubmitComment = handleSubmit((payload) => {
		createReportErrorContent({
			contentId: id,
			message: payload.message,
		})
	})

	useEffect(() => {
		if (isSuccess) {
			reset()
			dispatch(notificationCreators.show(successMessages.createErrorContent))
		}
	}, [dispatch, isSuccess, reset])

	return (
		<>
			<form className={containerReportError()} onSubmit={onSubmitComment}>
				<TextArea
					placeholder='Descreva brevemente sobre o que está acontecendo...'
					css={{ borderColor: '$neutral50', minHeight: '92px' }}
					error={errors.message?.message}
					{...register('message')}
				/>

				<div className={commentButton()}>
					<Button css={{ '@md': { maxWidth: '219px' } }} loading={isLoading}>
						Reportar erro
					</Button>
				</div>
			</form>
		</>
	)
}
