import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import { AnswerQuestionResponse } from '@models/questions.model'
import {
	AllSimulateResponse,
	AllSimulateResquest,
	AnswerQuestionSimulateRequest,
	AnswerQuestionSimulateResult,
	AnswerTestSimulateRequest,
	PostRatingRequest,
	RatingRequest,
	RatingResult,
	ResultSimulateHistoric,
	ResultSimulateRequest,
	ResultSimulateResult,
	TestSimulateResponse,
	WeeksSimulateResponse,
	WeeksSimulateResquest,
} from '@models/simulated.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const simulatedAPI = createApi({
	reducerPath: 'simulatedAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		getAllSimulated: builder.mutation<AllSimulateResponse, AllSimulateResquest>(
			{
				query: ({ page, limit, search }) => ({
					url: `mockCategories/?page=${page}&limit=${limit}${
						search ? `&search=${search}` : ''
					}`,
					method: 'GET',
					headers: { platform: 'web' },
				}),
			}
		),

		getWeeksSimulate: builder.mutation<
			WeeksSimulateResponse,
			WeeksSimulateResquest
		>({
			query: ({ page, limit, categoryId }) => ({
				url: `mockCategories/mocks?page=${page}&limit=${limit}&categoryId=${categoryId}`,
				method: 'GET',
				headers: { platform: 'web' },
			}),
		}),

		getQuestionsSimulate: builder.mutation<TestSimulateResponse, string>({
			query: (id) => ({
				url: `mockCategories/mocks/${id}`,
				method: 'GET',
				headers: { platform: 'web' },
			}),
		}),

		getSimulateResult: builder.mutation<
			ResultSimulateResult,
			ResultSimulateRequest
		>({
			query: ({ mookID, resolutionId }) => ({
				url: `mockCategories/mocksExams/${mookID}/historic/${resolutionId}?page=1&limit=1000`,
				method: 'GET',
				headers: { platform: 'web' },
			}),
		}),

		getRatingMock: builder.mutation<RatingResult, RatingRequest>({
			query: ({ mookID }) => ({
				url: `mockCategories/${mookID}/rating`,
				method: 'GET',
			}),
		}),

		getSimulateHistoric: builder.mutation<ResultSimulateHistoric, string>({
			query: (mookId) => ({
				url: `mockCategories/mocksExams/${mookId}/historic`,
				method: 'GET',
				headers: { platform: 'web' },
			}),
		}),

		postAnswerTestQuestionSimulate: builder.mutation<
			AnswerQuestionResponse,
			AnswerTestSimulateRequest
		>({
			query: (answerQuestion: AnswerTestSimulateRequest) => ({
				url: `mockCategories/mocks/${answerQuestion.mockID}/training`,
				method: 'POST',
				body: {
					questionId: answerQuestion.questionId,
					answer: answerQuestion.answer,
				},
			}),
		}),

		postAnswerQuestionSimulate: builder.mutation<
			AnswerQuestionSimulateResult,
			AnswerQuestionSimulateRequest
		>({
			query: (answerQuestion: AnswerQuestionSimulateRequest) => ({
				url: 'mockCategories/mocks',
				method: 'POST',
				body: answerQuestion,
			}),
		}),

		postSimulateRating: builder.mutation<void, PostRatingRequest>({
			query: ({ categoryId, rating }: PostRatingRequest) => ({
				url: `mockCategories/${categoryId}/rating?rating=${rating}`,
				method: 'POST',
			}),
		}),
	}),
})
