import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface QuestionPrintState {
	printType: string
}

const initialState: QuestionPrintState = {
	printType: '',
}

export const questionPrintSlice = createSlice({
	name: 'questionPrint',
	initialState,
	reducers: {
		setQuestionPrintType: (
			state,
			{
				payload: { type },
			}: PayloadAction<{
				type: string
			}>
		) => {
			state.printType = type
		},
	},
})

export const questionPrintcreators = questionPrintSlice.actions

export const questionPrintReducer = questionPrintSlice.reducer
