import { css } from '@styles/theme'

export const inputStyles = {
	base: css({
		display: 'flex',
		fleDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '14px 16px',

		width: '100%',
		height: '48px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',

		color: '$neutral90',

		fontWeight: '400',
		fontSize: '$lg',

		'&::placeholder': {},

		'&:focus': {
			borderColor: '$blue20',
		},

		variants: {
			invalid: {
				true: {
					borderColor: '$orange40',
				},
			},
		},
	}),

	error: css({
		color: '$red',
		_font: 'normal',
		fontWeight: '400',
		fontSize: '$md',
		lineHeight: '21px',
		paddingLeft: '16px',
	}),
}
