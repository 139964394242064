import { slideDown } from './animations'
import { globalCss } from './theme'

export const globalStyles = globalCss({
	'*': { margin: 0, padding: 0, boxSizing: 'border-box' },

	html: {
		fontSize: '62.5%',
	},

	body: {
		fontFamily: '$primary',
		fontSize: '$sm',
		'-webkit-font-smoothing': 'antialiased',
		'-moz-osx-font-smoothing': 'grayscale',
	},
	'.grecaptcha-badge': {
		visibility: 'hidden !important',
	},
	'.custom-editor *': { fontFamily: 'Arial', fontSize: '16px' },

	'.ck-editor__editable_inline': {
		padding: '0 30px !important',
	},

	'.custom-editor .ck-editor__editable_inline': {
		minHeight: '300px',
	},

	'.ck .ck-button.ck-on .ck-icon': {
		color: 'inherit !important',
	},
	'.ck .ck-button.ck-off .ck-icon': {
		color: 'inherit !important',
	},

	/* Garanta que a cor do texto em negrito seja herdada */
	'.ck-content strong': {
		color: 'inherit !important',
	},

	'input, button, a': {
		all: 'unset',
		boxSizing: 'border-box',
		fontFamily: '"Roboto", sans-serif',
	},

	'.transition': {
		transitionProperty:
			'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
		transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
		transitionDuration: '150ms',
	},

	'.duration-75': {
		transitionDuration: '75ms',
	},

	'.duration-150': {
		transitionDuration: '150ms',
	},

	'.duration-300': {
		transitionDuration: '300ms',
	},

	'.opacity-0': {
		opacity: 0,
	},

	'.opacity-100': {
		opacity: 1,
	},

	'.translate-y--20': {
		'-webkit-transform': 'translateY(-20px)',
		transform: 'translateY(-20px)',
	},

	'.translate-y-0': {
		'-webkit-transform': 'translateY(0)',
		transform: 'translateY(0)',
	},

	'.slideDown': {
		animation: `${slideDown} 300ms ease-in-out 10ms both`,
	},

	'.custom-scroll': {
		scrollbarColor: '#cbd5e1 #e2e8f0',
		scrollbarWidth: 'thin',

		'&::-webkit-scrollbar': {
			width: '5px',
			height: '8px',
		},

		'&::-webkit-scrollbar-track': {
			background: '#e2e8f0',
			borderRadius: '900px',
		},

		'&::-webkit-scrollbar-thumb': {
			background: '#cbd5e1',
			borderRadius: '900px',
		},
	},

	'@media print': {
		'.page-break': {
			pageBreakAfter: 'always',
		},
	},
	'.image': {
		'& img': {
			height: 'fit-content',
		},
	},
})
