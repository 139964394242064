import * as yup from 'yup'

export const commentSchema = yup.object({
	message: yup.string().required('Campo obrigatório'),
})

export const notebookSchema = yup.object({
	notebook: yup.string().required('Campo obrigatório'),
})

export const newNotebookSchema = yup.object({
	name: yup.string(),
})

export const reportErrorSchema = yup.object({
	message: yup
		.string()
		.required('Obrigatório no minimo 50 caracteres')
		.min(50, 'Obrigatório no minimo 50 caracteres'),
})

export const reportErrorContentSchema = yup.object({
	message: yup.string().required('Campo obrigatório'),
})
