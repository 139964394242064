import * as Dialog from '@radix-ui/react-dialog'
import { styled, keyframes } from '@styles/theme'

import { Icon } from '../Icon'

const overlayShow = keyframes({
	'0%': { opacity: 0 },
	'100%': { opacity: 1 },
})

const contentShow = keyframes({
	'0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
	'100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

export const DialogOverlay = styled(Dialog.Overlay, {
	backgroundColor: '$modalOverlay',
	position: 'fixed',
	inset: 0,
	zIndex: 10000,
	animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
})

export const DialogContent = styled(Dialog.Content, {
	backgroundColor: '$neutral10',
	borderRadius: '16px',
	boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.12)',
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90vw',
	maxWidth: '450px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	maxHeight: '85vh',
	padding: 32,
	zIndex: 10001,
	animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	'&:focus': { outline: 'none' },

	variants: {
		popup: {
			true: {
				padding: 0,
				width: 'auto',
				height: 'auto',
				cursor: 'pointer',
				overflow: 'hidden',
				'&:hover': {
					cursor: 'pointer',
				},
				'@media (min-width: 768px)': {
					width: 'auto',
					height: 'auto',
				},
			},
		},
	},

	'&.freeLimitedIcon': {
		maxWidth: '417px',
	},

	'&.blackFridayImage': {
		maxWidth: '417px',
		padding: 0,
		maxHeight: '100vh',
	},
})

export const DialogTitle = styled(Dialog.Title, {
	margin: 0,
	_font: 'normal',
	fontSize: '$xxl',
	fontWeights: '$bold',
	lineHeight: '19px',
	color: '$neutral100',
	textAlign: 'center',
})

export const DialogDescription = styled(Dialog.Description, {
	margin: '10px 0 20px',
	fontSize: 15,
	lineHeight: 1.5,
})

export const DialogClose = styled(Dialog.Close, {
	position: 'absolute',
	top: 26,
	right: 24,
	cursor: 'pointer',
	button: {
		cursor: 'pointer',

		svg: {
			color: '$neutral100',
		},
	},
	svg: {
		color: '$neutral100',
	},
})

export const ImgIcon = styled(Icon, {
	marginBottom: 32,
})

export const Image = styled('div', {
	width: '100%',
	height: '100%',
	position: 'relative',
	overflow: 'hidden',
	borderRadius: 12,

	variants: {
		popup: {
			true: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
				'& img': {
					width: '100%',
					height: '100%',
					objectFit: 'fill',
					borderRadius: '12px',
				},
			},
		},
	},
})
