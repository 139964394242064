/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'

import { Dismiss20Regular, DismissCircle20Regular } from '@fluentui/react-icons'
import { FilterStateProps, StateProps } from '@hooks/useQuestion'

import { tagsStyles } from './styles'

const { containerTagsActions, containerTags, TagAction, buttonCloseAll } =
	tagsStyles

type StitchesProps = React.ComponentProps<any>

interface TagsProps extends StitchesProps {
	title: string
	name:
		| 'disciplines'
		| 'materId'
		| 'subMaterId'
		| 'year'
		| 'office'
		| 'juries'
		| 'institutions'
	onClose: Dispatch<SetStateAction<FilterStateProps>>
	options?: Array<StateProps>
}

export const TagsActions = ({
	options,
	name,
	title,
	onClose,
	...props
}: TagsProps) => {
	return (
		<>
			{options && options?.length > 0 && (
				<div className={containerTagsActions()} {...props}>
					<div className={containerTags()}>
						<h5>{title}</h5>

						{options?.map((item, index) => (
							<TagAction key={index}>
								{item.name}
								<DismissCircle20Regular
									onClick={() => {
										onClose((prevState) => {
											return {
												...prevState,
												[name]: prevState[name].filter(
													(tag) => tag.id !== item.id
												),
											}
										})
									}}
								/>
							</TagAction>
						))}
					</div>

					<button
						className={buttonCloseAll()}
						type='button'
						onClick={() => {
							onClose((prevState) => {
								return {
									...prevState,
									[name]: [],
								}
							})
						}}
					>
						<Dismiss20Regular />
					</button>
				</div>
			)}
		</>
	)
}
