import { styled } from '@styles/theme'

export const ClearButtonStyles = {
	ClearButtonStitcherComponent: styled('button', {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '48px',
		padding: '12px 16px',
		backgroundColor: '$neutral10',
		width: '100%',

		borderRadius: '12px',
		border: '1px solid $neutral50',

		fontFamily: 'Roboto',
		_font: 'normal',
		fontSize: '$md',
		color: '$red',
		lineHeight: '$xl',

		transition: 'all 0.3s',
		cursor: 'pointer',

		'&:hover': {
			color: '$red',
			borderColor: '$red',
		},
	}),
}
