import { styled, css } from '@styles/theme'

export const breadCrumbStyles = {
	breadCrumbContainer: css({
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		gap: '4px',
	}),

	buttonReturnStyle: css({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0px',
		gap: '4px',
		marginRight: '16px',
		cursor: 'pointer',

		width: '52px',
		height: '21px',

		svg: {
			width: '12px',
			height: '12px',
			color: '$neutral80',
		},

		p: {
			_font: 'normal',
			fontWeight: '600',
			fontSize: 'p',
			lineHeight: '21px',
			letterSpacing: '0.05em',
			textDecorationLine: 'underline',
			color: '$neutral80',
		},
	}),

	Step: styled('li', {
		_font: 'normal',
		fontWeight: '300',
		fontSize: '$p',
		lineHeight: '21px',
		letterSpacing: ' 0.05em',
		listStyle: 'none',
		textDecorationLine: 'underline',

		color: '$neutral80',

		variants: {
			active: {
				true: {
					fontWeight: '600',
					textDecorationLine: 'none',
				},
				false: {
					cursor: 'pointer',
				},
			},
		},
	}),

	breadcrumbSeparator: css({
		_font: 'normal',
		fontWeight: '300',
		fontSize: '$p',
		lineHeight: '21px',
		color: '$neutral80',
	}),
}
