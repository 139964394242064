import React from 'react'

import { Paragraph } from '../Typography'
import { styleProgressbar } from './styles'

const { Container, Progress } = styleProgressbar

interface ProgressBarProps {
	currentValue: number
	maxValue: number
	width: string
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
	currentValue,
	maxValue,
	width,
}) => {
	const progress = ((currentValue / maxValue) * 100).toFixed(2)

	return (
		<>
			<Paragraph
				paragraphType='p2'
				css={{ color: '$neutral100', textAlign: 'center', marginBottom: '8px' }}
			>{`${progress}% concluido`}</Paragraph>
			<Container style={{ width }}>
				<Progress style={{ width: `${progress}%` }} />
			</Container>
		</>
	)
}
