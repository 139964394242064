import React from 'react'

import { styled } from '@styles/theme'

const Svg = styled('svg')

type SvgStitches = React.ComponentProps<typeof Svg>

type IconProps = SvgStitches & { title?: string }

export function Icon({ title, ...props }: IconProps) {
	return (
		<Svg {...props} aria-label={title}>
			<use href={`/assets/svg/sprite.svg#${props.name}`} />
		</Svg>
	)
}
