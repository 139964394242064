import { css } from '@styles/theme'

export const sliderStyles = {
	containerSlider: css({
		display: 'none',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: '30px',
		paddingBottom: '30px',
		width: '50%',
		minHeight: '100vh',
		background: '$blue20',

		'@lg': {
			display: 'flex',
		},

		'& .image-top': {
			position: 'relative',
			left: '90px',
			top: '-200px',
			zIndex: '10',
		},

		'& .image-down': {
			position: 'relative',
			right: '45px',
			top: '55px',
			zIndex: '10',
		},
	}),
	text: css({
		width: '100%',
		maxWidth: '402px',
		textAlign: 'center',
		marginTop: '75px',

		'& h5': {
			_font: 'normal',
			fontWeight: '500px',
			fontSize: '$xxxl',
			color: '$text',
			marginBottom: '19px',
		},

		'& p': {
			_font: 'normal',
			color: '$text',
			fontSize: '$xl',
			lineHeight: '21px',
			letterSpacing: '0.05em',
		},
	}),
}
