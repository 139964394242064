import { styled } from '@styles/theme'

export const textAreaStyles = {
	Container: styled('div', {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	}),

	TextAreaContainer: styled('div', {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',

		border: ' 1px solid $neutral50',
		borderRadius: '12px',

		'.cke_inner': {
			borderRadius: '12px',
		},

		'.cke_top': {
			borderTopLeftRadius: '12px',
			borderTopRightRadius: '12px',
		},

		'.cke_bottom': {
			borderBottomLeftRadius: '12px',
			borderBottomRightRadius: '12px',
		},

		'.cke_reset': {
			borderRadius: '12px',
		},

		variants: {
			error: {
				true: {
					border: '2px solid $neutral50',
					outlineColor: '$red',
					borderColor: '$red',
				},
			},
		},
	}),

	EditorStyled: styled('div', {
		display: 'flex',
		flex: 1,
		fleDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '14px 16px',

		overflow: 'auto',

		resize: 'none',

		width: '100%',
		height: '84px',

		background: '$neutral30',
		borderRadius: '12px',

		color: '$neutral80',

		fontFamily: 'Roboto',
		fontWeight: '400',
		fontSize: '$md',

		'&::placeholder': {},

		'&:focus': {
			outlineColor: '$blue20',
		},

		variants: {
			invalid: {
				true: {
					color: '$red',

					'&:focus': {
						outlineColor: '$red',
					},

					'&::placeholder': {
						color: '$red',
					},
				},
			},
		},
	}),

	ErrorSpan: styled('span', {
		color: '$red',
		_font: 'normal',
		fontWeight: '400',
		fontSize: '$md',
		lineHeight: '21px',
		paddingLeft: '16px',
	}),
}
