import { createSlice } from '@reduxjs/toolkit'

interface FontQuestionState {
	fontSize: number
}

const initialState: FontQuestionState = {
	fontSize: 16,
}

const fontQuestion = createSlice({
	name: 'value',
	initialState,
	reducers: {
		incrementValue: (state) => {
			state.fontSize += 2
			if (state.fontSize > 24) {
				state.fontSize = 24
			}
		},
		decrementValue: (state) => {
			state.fontSize -= 2
			if (state.fontSize < 8) {
				state.fontSize = 8
			}
		},
	},
})

export const fontQuestionCreators = fontQuestion.actions

export const fontQuestionReducer = fontQuestion.reducer
