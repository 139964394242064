import React from 'react'

import { Dismiss24Regular } from '@fluentui/react-icons'

import { ClearButtonStyles } from './styles'

const { ClearButtonStitcherComponent } = ClearButtonStyles

type StitchesProps = React.ComponentProps<typeof ClearButtonStitcherComponent>
interface ClearButtonProps extends StitchesProps {
	children: string
}

const ClearButton = ({
	children = 'Atualizar',
	...props
}: ClearButtonProps) => {
	return (
		<ClearButtonStitcherComponent {...props}>
			<p>{children}</p>
			<Dismiss24Regular />
		</ClearButtonStitcherComponent>
	)
}

export { ClearButton }
