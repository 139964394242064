import { lazy } from 'react'

import { Routes } from '@models/route/route.model'

const Home = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Home }))
)

const Questions = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Questions }))
)

const Notebooks = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Notebooks }))
)

const NotebookShow = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.NotebookShow }))
)
const NotebookShowFolder = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageShowFolderNotebook }))
)

const NotebookErrorShow = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.NotebookErrorShow }))
)

const Filters = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Filters }))
)

const FolderShowFilters = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageFolderShowFilters }))
)

const Disciplines = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Disciplines }))
)

const QuestionsDiscipline = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.QuestionsDiscipline }))
)

const Simulate = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageSimulate }))
)

const SimulateShow = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageSimulateShow }))
)

const PageSimulateTest = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageSimulateTest }))
)

const PageSimulateHistoric = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageSimulateHistoric }))
)

const PageSimulateExam = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageSimulateExam }))
)

const PageSimulateResult = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageSimulateResult }))
)

const PageSimulateRanking = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageSimulateRanking }))
)

const Ranking = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Ranking }))
)

const Courses = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Courses }))
)

const MyCourses = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.MyCourses }))
)

const MyCoursesShow = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.MyCoursesShow }))
)

const Settings = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Settings }))
)

const ActivityHistoric = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageActivityHistory }))
)

const Shared = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageShared }))
)

export const privateRoutes: Routes = [
	{ path: '/', element: Home, isPrivate: true, exact: true },
	{
		path: '/vade-mecum-de-questoes',
		element: Questions,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/cadernos',
		element: Notebooks,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/cadernos/:id',
		element: NotebookShow,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/cadernos/pasta/:id',
		element: NotebookShowFolder,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/cadernos/erros/:id',
		element: NotebookErrorShow,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/disciplinas',
		element: Disciplines,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/disciplinas/questoes',
		element: QuestionsDiscipline,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/filtros-salvos',
		element: Filters,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/filtros-salvos/pasta/:id',
		element: FolderShowFilters,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/simulados',
		element: Simulate,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/cursos',
		element: Courses,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/simulados/:id',
		element: SimulateShow,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/simulados/:id',
		element: SimulateShow,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/simulados/treino/:id',
		element: PageSimulateTest,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/simulados/prova/:id',
		element: PageSimulateExam,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/simulados/resultado',
		element: PageSimulateResult,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/simulados/prova/historico/:id',
		element: PageSimulateHistoric,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/simulados/prova/ranking/:id',
		element: PageSimulateRanking,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/rankings',
		element: Ranking,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/cursos/meus-cursos/:id',
		element: MyCourses,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/cursos/meus-cursos/:id/show/:idModule',
		element: MyCoursesShow,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/meu-perfil',
		element: Settings,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/meu-perfil/histórico-de-atividade',
		element: ActivityHistoric,
		isPrivate: true,
		exact: true,
	},

	{
		path: '/compartilhamento',
		element: Shared,
		isPrivate: true,
		exact: true,
	},
]
