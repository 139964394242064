import * as Toast from '@radix-ui/react-toast'
import { styled, keyframes } from '@stitches/react'

const VIEWPORT_PADDING = 25

export const ToastViewport = styled(Toast.Viewport, {
	position: 'fixed',
	bottom: 0,
	right: 0,
	display: 'flex',
	flexDirection: 'column',
	margin: VIEWPORT_PADDING,
	gap: 10,
	width: `calc(100% - (${VIEWPORT_PADDING}px * 2))`,
	height: '100vh',
	maxHeight: '10%',
	maxWidth: '438px',
	listStyle: 'none',
	zIndex: 100000,
	outline: 'none',
})

const hide = keyframes({
	'0%': { opacity: 1 },
	'100%': { opacity: 0 },
})

const slideIn = keyframes({
	from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
	to: { transform: 'translateX(0)' },
})

const swipeOut = keyframes({
	from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
	to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
})

export const ToastRoot = styled(Toast.Root, {
	backgroundColor: '$red',
	borderRadius: 6,
	boxShadow:
		'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
	padding: '12px',
	display: 'grid',
	gridTemplateColumns: '1fr auto max-content',
	columnGap: 8,
	maxHeight: '92px',
	overflow: 'hidden',
	alignItems: 'flex-start',

	'&[data-state="open"]': {
		animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
	'&[data-state="closed"]': {
		animation: `${hide} 100ms ease-in`,
	},
	'&[data-swipe="move"]': {
		transform: 'translateX(var(--radix-toast-swipe-move-x))',
	},
	'&[data-swipe="cancel"]': {
		transform: 'translateX(0)',
		transition: 'transform 200ms ease-out',
	},
	'&[data-swipe="end"]': {
		animation: `${swipeOut} 100ms ease-out`,
	},
})
export const ToastCheck = styled(Toast.Action, {
	color: 'white',
	'& svg': {
		display: 'block',
		width: 50,
		height: 50,
	},
})

export const ToastTitle = styled(Toast.Title, {
	fontWeight: 500,
	color: '$text',
	fontSize: 18,
})

export const ToastAction = styled(Toast.Close, {
	cursor: 'pointer',
})

export const ToastDescription = styled(Toast.Description, {
	margin: 0,
	color: '$text',
	fontSize: 14,
	lineHeight: 1.3,
})
