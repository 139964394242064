import { ListHistoryProps } from '@components/molecules/ListHistory'
import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import {
	DashBoardResponse,
	DashboardFilteredRequest,
	MeResponse,
	MeResquest,
	PopupData,
} from '@models/dashboard.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const dashboardAPI = createApi({
	reducerPath: 'dashboardAPI',
	tagTypes: ['Clientes'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		getMe: builder.mutation<MeResponse, void>({
			query: () => ({
				url: '/client/header',
				method: 'GET',
			}),
		}),
		listMe: builder.query<MeResponse, void>({
			query: () => ({
				url: '/client/profile',
				method: 'GET',
			}),
			providesTags: ['Clientes'],
		}),
		listMeProfile: builder.mutation<MeResponse, void>({
			query: () => ({
				url: '/client/profile',
				method: 'GET',
			}),
		}),
		getDashboard: builder.mutation<DashBoardResponse, void>({
			query: () => ({
				url: '/client/dashboard',
				method: 'GET',
			}),
		}),
		getDashboardFiltered: builder.mutation<
			DashBoardResponse,
			DashboardFilteredRequest
		>({
			query: ({
				disciplineIds,
				matterIds,
				submatterIds,
				yearIds,
				positionIds,
				juryIds,
				institutionIds,
				dateInterval,
			}) => ({
				url: `client/dashboard?disciplineIds=${disciplineIds}&matterIds=${matterIds}&subMatterIds=${submatterIds}&yearIds=${yearIds}&positionIds=${positionIds}&juryIds=${juryIds}&institutionIds=${institutionIds}&dateInterval=${dateInterval}&lastResolutions=4`,
				method: 'GET',
			}),
		}),
		editInfo: builder.mutation<void, MeResquest>({
			query: (data) => ({
				url: '/client/edit',
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Clientes'],
		}),
		editPasswod: builder.mutation<
			void,
			{ password: string; oldPassword: string }
		>({
			query: (data) => ({
				url: '/client/password',
				method: 'PUT',
				body: data,
			}),
		}),
		editPhoto: builder.mutation<void, { photo: File }>({
			query: ({ photo }) => {
				const formData = new FormData()

				formData.append('photo', photo)

				return {
					url: '/client/image',
					method: 'PUT',
					body: formData,
				}
			},
		}),
		clearHistoric: builder.mutation<void, void>({
			query: () => ({
				url: '/client/clear-history',
				method: 'DELETE',
			}),
		}),

		getActivityHistory: builder.mutation<Array<ListHistoryProps>, void>({
			query: () => ({
				url: '/client-logs',
				method: 'GET',
			}),
		}),
		getPopup: builder.query<PopupData, void>({
			query: () => ({
				url: '/popup',
				method: 'GET',
			}),
		}),
	}),
})
