import React from 'react'

import Lottie from 'lottie-react'

import BlueLodaer from '../JsonLottie/BlueLoader.json'
import { LoaderStyles } from './style'

const { Container } = LoaderStyles

export function Loader({ ...props }) {
	return (
		<Container {...props}>
			<Lottie
				animationData={BlueLodaer}
				loop
				style={{ width: '180px', height: '180px' }}
			/>
		</Container>
	)
}
