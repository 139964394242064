import React, { ButtonHTMLAttributes } from 'react'

import { useAppSelector } from '@store/hooks'

import { Paragraph, Small } from '../Typography'
import { stylesRadioButton } from './styles'

const {
	Container,
	ContainerWrapper,
	Ellipse,
	RadioButtonStitcherComponent,
	Checkmark,
	Dismiss,
} = stylesRadioButton

interface RadioButtonProps extends ButtonHTMLAttributes<RadioButtonProps> {
	questionType: boolean
	checked: boolean
	questionAnswer?: boolean
	isCorrect?: boolean
	customText?: string
}

const QuestionAnswerChecked = ({ isCorrect }: { isCorrect: boolean }) => {
	return (
		<>
			{isCorrect ? (
				<Checkmark isCorrect={isCorrect} />
			) : (
				<Dismiss isCorrect={isCorrect} />
			)}
		</>
	)
}

const QuestionAnswerCheckedText = ({ isCorrect }: { isCorrect: boolean }) => {
	return (
		<>
			{isCorrect ? (
				<Small
					smallType='small1'
					css={{ position: 'absolute', bottom: '-20px', color: '$primary' }}
				>
					Sua resposta está correta
				</Small>
			) : (
				<Small
					smallType='small1'
					css={{ position: 'absolute', bottom: '-20px', color: '$neutral150' }}
				>
					Sua resposta está errada
				</Small>
			)}
		</>
	)
}

const RadioButton = ({
	questionType,
	checked,
	questionAnswer,
	isCorrect,
	customText,
}: RadioButtonProps) => {
	const { fontSize } = useAppSelector((state) => state.fonts)

	return (
		<RadioButtonStitcherComponent css={{ position: 'relative' }}>
			<Container
				isCorrect={questionAnswer && checked && isCorrect}
				questionAnswer={questionAnswer && checked}
			>
				<ContainerWrapper>
					<Ellipse active={checked} />
					<Paragraph
						paragraphType='p2'
						css={{ color: '$neutral100' }}
						style={{ fontSize }}
					>
						{customText || (questionType ? 'Verdadeiro' : 'Falso')}
					</Paragraph>
				</ContainerWrapper>
				{questionAnswer && checked && isCorrect !== undefined && (
					<QuestionAnswerChecked isCorrect={isCorrect} />
				)}
			</Container>
			{questionAnswer && checked && isCorrect !== undefined && (
				<QuestionAnswerCheckedText isCorrect={isCorrect} />
			)}
		</RadioButtonStitcherComponent>
	)
}

export { RadioButton }
