import { css } from '@styles/theme'

export const questionReportErrorStyles = {
	containerReportError: css({
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		width: '100%',
		height: '100%',
		padding: '16px',
		margin: '28px 0px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',
	}),

	commentButton: css({ display: 'flex', justifyContent: 'flex-end' }),
}
