/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, SetStateAction, Dispatch } from 'react'

import { IntervaDatelProps } from '@components/organisms/Dashboard'
import { SelectWeek } from '@components/organisms/Simulate/SimulateShow'
import { ChevronDown20Regular, ChevronUp20Regular } from '@fluentui/react-icons'
import { Listbox } from '@headlessui/react'
import {
	FilterStateProps,
	StateProps,
	StateSelectProps,
} from '@hooks/useQuestion'
import { InterestSelectType } from '@pages/SignUp'
import { styled } from '@styles/theme'

import { NotebookSelectType } from '../QuestionNotebook'
import { ErrorSelectType } from '../QuestionReportError'
import { selectStyles } from './styles'

const { Container, ListboxButton, ListboxOptions, listboxOption } = selectStyles

const Error = styled('span', selectStyles.error)

type StitchesProps = React.ComponentProps<typeof ListboxButton>
export type OptionsProps = { label: string; value: number | object | string }
interface SelectProps extends StitchesProps {
	error?: string
	options?: Array<OptionsProps>
	disabled?: boolean
	multiple?: boolean
	placeholder?: string
	onChange?:
		| Dispatch<SetStateAction<FilterStateProps>>
		| Dispatch<SetStateAction<StateProps>>
		| Dispatch<SetStateAction<StateSelectProps>>
		| Dispatch<SetStateAction<Array<StateProps>>>
		| Dispatch<SetStateAction<Array<StateSelectProps>>>
		| Dispatch<SetStateAction<FilterStateProps>>
		| Dispatch<SetStateAction<NotebookSelectType | undefined>>
		| Dispatch<SetStateAction<InterestSelectType | undefined>>
		| Dispatch<SetStateAction<ErrorSelectType>>
		| Dispatch<SetStateAction<SelectWeek | undefined>>
		| Dispatch<SetStateAction<IntervaDatelProps>>
		| Dispatch<
				SetStateAction<
					| {
							value?: 'answer_error' | 'title_error' | 'description_error'
							label?: string
					  }
					| undefined
				>
		  >
		| Dispatch<
				SetStateAction<{
					value?: string | number
					label?: string
				}>
		  >
		| undefined
	name: string
	selectedValue?: any
}

export const SelectBase = ({
	error,
	options,
	disabled,
	multiple = false,
	placeholder = 'Selecione',
	onChange,
	name,
	selectedValue,
	...props
}: SelectProps) => {
	const [selectedSingle, setSelectedSingle] = useState<OptionsProps>({
		label: placeholder,
		value: '',
	})
	const [selectedMultiple, setSelectedMultiple] = useState([])

	useEffect(() => {
		if (selectedValue !== undefined) {
			selectedValue === selectedSingle
				? setSelectedSingle(selectedSingle)
				: setSelectedSingle(selectedValue)
		}
	}, [selectedSingle, selectedValue, setSelectedSingle])

	useEffect(() => {
		if (selectedValue !== undefined) {
			selectedValue === selectedMultiple
				? setSelectedMultiple(selectedMultiple)
				: setSelectedMultiple(selectedValue)
		}
	}, [selectedMultiple, selectedValue, setSelectedMultiple])

	return (
		<Container {...props}>
			{multiple ? (
				<Listbox
					value={selectedMultiple}
					onChange={(item) => {
						setSelectedMultiple(item)
						if (onChange) {
							onChange((prevState: any) => ({
								...prevState,
								[name]: item,
							}))
						}
					}}
					disabled={disabled}
					multiple={multiple}
				>
					{({ open }) => (
						<>
							<ListboxButton>
								{Array.isArray(selectedMultiple) && selectedMultiple.length > 0
									? selectedMultiple
											.map((item: OptionsProps) => item.label)
											.join(', ')
									: placeholder}
								{open ? <ChevronUp20Regular /> : <ChevronDown20Regular />}
							</ListboxButton>
							<ListboxOptions>
								{options &&
									options.map((item, index) => (
										<Listbox.Option
											className={listboxOption()}
											key={index}
											value={item}
										>
											{item.label}
										</Listbox.Option>
									))}
							</ListboxOptions>
						</>
					)}
				</Listbox>
			) : (
				<Listbox
					value={selectedSingle}
					onChange={(item) => {
						setSelectedSingle(item)
						if (onChange) {
							onChange((prevState: any) => ({
								...prevState,
								[name]: item,
							}))
						}
					}}
					disabled={disabled}
					{...props}
				>
					{({ open }) => (
						<>
							<ListboxButton>
								{selectedSingle &&
								Object.keys(selectedSingle).length === 0 &&
								Object.getPrototypeOf(selectedSingle) === Object.prototype
									? placeholder
									: selectedSingle?.label}

								{open ? <ChevronUp20Regular /> : <ChevronDown20Regular />}
							</ListboxButton>
							<ListboxOptions>
								<div>
									{options &&
										options.map((item, index) => (
											<Listbox.Option
												className={listboxOption()}
												key={index}
												value={item}
											>
												{item.label}
											</Listbox.Option>
										))}
								</div>
							</ListboxOptions>
						</>
					)}
				</Listbox>
			)}
			<div>{error && <Error>{error}</Error>}</div>
		</Container>
	)
}
