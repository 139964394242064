import { styled } from '@styles/theme'

export const cardListStyle = {
	Container: styled('div', {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '16px',
		minHeight: '57px',

		background: '$neutral10',
		borderRadius: '12px',
		border: '1px solid $neutral50',

		cursor: 'pointer',

		svg: { width: '20px', height: '22.73px', color: '$neutral100' },

		'&:hover': {
			h6: {
				color: '$blue20',
			},
			svg: {
				color: '$blue20',
			},
		},

		variants: {
			disabled: {
				true: {
					cursor: 'not-allowed',
					color: '$disabled',
					h6: {
						color: '$disabled',
					},

					'&:hover': {
						color: '$disabled',
						h6: {
							color: '$disabled',
						},
						svg: {
							color: '$disabled',
						},
					},
				},
			},
		},
	}),
}
