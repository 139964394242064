import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import {
	FilterMatterResponse,
	QuestionsResponse,
	QuestionsRequest,
	AnswerQuestionRequest,
	AnswerQuestionResponse,
	AllCommentsResponse,
	CommentQuestionRequest,
	AllReminderQuestionResponse,
	ReminderQuestionResquest,
	ReportErrorQuestionResquest,
	AllHistoricResponse,
	AllStatistcQuestionResponse,
	PrintQuestion,
} from '@models/questions.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const questionsAPI = createApi({
	reducerPath: 'questionsAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		getAllOptionsFilter: builder.mutation<void, void>({
			query: () => ({
				url: '/filter/general',
				method: 'GET',
			}),
		}),
		getOptionMatter: builder.mutation<FilterMatterResponse, string>({
			query: (ids) => ({
				url: `/filter/general?filter=matter&dependencies=${ids}`,
				method: 'GET',
			}),
		}),
		getOptionSubMatter: builder.mutation<FilterMatterResponse, string>({
			query: (ids) => ({
				url: `/filter/general?filter=submatter&dependencies=${ids}`,
				method: 'GET',
			}),
		}),
		getQuestions: builder.mutation<QuestionsResponse, QuestionsRequest>({
			query: ({
				value: {
					discipline,
					matter,
					submatter,
					year,
					position,
					jury,
					institution,
					status,
					comment,
					limit = 50,
					mode,
				},
				page,
			}: QuestionsRequest) => ({
				url: `/question/${page}?disciplineIds=${discipline}&matterIds=${matter}&subMatterIds=${submatter}&yearIds=${year}&positionIds=${position}&juryIds=${jury}&institutionIds=${institution}&limit=${limit}${
					status !== 'all' ? `&questionStatus=${status}` : ''
				}${comment !== 'all' ? `&questionComment=${comment}` : ''}${
					mode === 'random' ? '&noOrder=true' : ''
				}`,
				method: 'GET',
			}),
		}),
		getQuestionComments: builder.mutation<AllCommentsResponse, number>({
			query: (id) => ({
				url: `question/${id}/comments?isWeb=true`,
				method: 'GET',
			}),
		}),

		getQuestionHistoric: builder.mutation<AllHistoricResponse, number>({
			query: (id) => ({
				url: `question/${id}/historic`,
				method: 'GET',
			}),
		}),

		getQuestionStatistics: builder.mutation<
			AllStatistcQuestionResponse,
			number
		>({
			query: (id) => ({
				url: `question/${id}/statistics`,
				method: 'GET',
			}),
		}),

		getQuestionReminders: builder.mutation<AllReminderQuestionResponse, number>(
			{
				query: (id) => ({
					url: `question/${id}/reminders`,
					method: 'GET',
				}),
			}
		),

		getPrintQuestions: builder.mutation<Array<PrintQuestion>, Array<number>>({
			query: (ids) => ({
				url: `print/questions?questions=${ids.join(',')}`,
				method: 'GET',
			}),
		}),

		postAnswerQuestion: builder.mutation<
			AnswerQuestionResponse,
			AnswerQuestionRequest
		>({
			query: (answerQuestion: AnswerQuestionRequest) => ({
				url: '/question',
				method: 'POST',
				body: answerQuestion,
				headers: { platform: 'web' },
			}),
		}),

		postCommentQuestion: builder.mutation<void, CommentQuestionRequest>({
			query: (commentQuestion: CommentQuestionRequest) => ({
				url: '/question/comments',
				method: 'POST',
				body: commentQuestion,
			}),
		}),

		postReminderQuestion: builder.mutation<void, ReminderQuestionResquest>({
			query: (reminderQuestion: ReminderQuestionResquest) => ({
				url: '/question/reminders',
				method: 'POST',
				body: reminderQuestion,
			}),
		}),

		postReportErrorQuestion: builder.mutation<
			void,
			ReportErrorQuestionResquest
		>({
			query: (reminderQuestion: ReportErrorQuestionResquest) => ({
				url: '/question/error',
				method: 'POST',
				body: reminderQuestion,
			}),
		}),

		deleteQuestionComment: builder.mutation<void, number>({
			query: (id) => ({
				url: `question/comments/${id}`,
				method: 'DELETE',
			}),
		}),

		deleteQuestionReminder: builder.mutation<void, number>({
			query: (id) => ({
				url: `question/reminders/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
})
