import React, { useState } from 'react'

import SearchInput from '@components/atoms/SearchInput'

import { selectMoveStyles } from './styles'

const { ItemSelect, container, Ellipse } = selectMoveStyles

export interface Option {
	option: string
	value: string | number
	disabled?: boolean
	id?: string
}

interface SelectMoveProps {
	options: Array<Option>
	onChange: (option: Option) => void
	value?: string | number
	placeHolderFilter?: string
}

export const SelectRadio = ({
	options,
	value,
	onChange,
	placeHolderFilter = 'Buscar',
}: SelectMoveProps) => {
	const [selected, setSelected] = useState<number>()
	const [search, setSearch] = useState<string>('')

	const filteredOptions = options.filter((option) =>
		option.option.toLowerCase().includes(search.toLowerCase())
	)

	return (
		<>
			<SearchInput
				placeholder={placeHolderFilter}
				onChange={(e) => setSearch(e.target.value)}
				value={search}
				css={{ maxWidth: '100%', marginBottom: '16px' }}
			/>
			<div className={container()}>
				{filteredOptions.map((item, index) => {
					return (
						<ItemSelect
							type='button'
							key={index}
							onClick={() => {
								onChange(item)
								setSelected(index)
							}}
							selected={value === item.value || selected === index}
						>
							<p>{item.option}</p>
							<Ellipse active={value === item.value || selected === index} />
						</ItemSelect>
					)
				})}
			</div>
		</>
	)
}
