import React, { useState, useEffect } from 'react'

import { Clock16Regular } from '@fluentui/react-icons'

import { stylesStopwatch } from './style'

const { ConatinerStopwatch } = stylesStopwatch

export const Stopwatch = () => {
	const [stopwatchInterval, setStopwatchInterval] = useState(0)

	const stopwatch = (seconds: number) => {
		const interval = setInterval(() => {
			if (seconds < 0) {
				clearInterval(interval)
				setStopwatchInterval(seconds)
			} else {
				seconds += 1
				setStopwatchInterval(seconds)
			}
		}, 1000)
	}

	useEffect(() => {
		stopwatch(0)
	}, [])

	const hours = Math.floor(stopwatchInterval / 3600)
	const minutes = Math.floor((stopwatchInterval % 3600) / 60)
	const seconds = stopwatchInterval % 60

	return (
		<ConatinerStopwatch>
			<Clock16Regular />
			{`${hours.toString().padStart(2, '0')}:${minutes
				.toString()
				.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
		</ConatinerStopwatch>
	)
}
