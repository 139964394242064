import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import { TrackingResponse, TrackingRequest } from '@models/tracking.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const trackingAPI = createApi({
	reducerPath: 'trackingAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		setTracking: builder.mutation<TrackingResponse, TrackingRequest>({
			query: ({ clientId, action }: TrackingRequest) => ({
				url: `/tracking`,
				method: 'POST',
				body: {
					clientId,
					action,
				},
			}),
		}),
	}),
})
