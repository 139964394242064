import React, { Dispatch, SetStateAction, useState } from 'react'

import { Print20Regular, WeatherMoon20Regular } from '@fluentui/react-icons'
import { FilterStateProps } from '@hooks/useQuestion'
import { QuestionType } from '@models/questions.model'
import { fontQuestionCreators } from '@store/ducks/fontsQuestion'
import { questionPrintcreators } from '@store/ducks/questionPrint'
import { themeCreators } from '@store/ducks/theme'
import { useAppDispatch, useAppSelector } from '@store/hooks'

import { Button } from '../Button'
import { CountdownTimer } from '../CountdownTimer'
import { ModalBase } from '../ModalBase'
import { SelectBase } from '../SelectBase'
import { Select } from '../SelectBase/Select'
import { Stopwatch } from '../Stopwatch'
import { Paragraph } from '../Typography'
import { menuQuestionsMobileStyles } from './styles'

const {
	container,
	rowContainer,
	questionsNumber,
	buttonIcon,
	changeSizeContainer,
} = menuQuestionsMobileStyles

type MenuQuestionsProps = {
	questions: Array<QuestionType>
	setFilter: Dispatch<SetStateAction<FilterStateProps>>
	values: FilterStateProps
	handlePrint?: () => void
	questionsAnswered?: number
	countdownTimerDuration?: number
	stopwatchTimer?: boolean
	numberQuestions?: number
	isSimulate?: boolean
}

export const MenuQuestionsMobile = ({
	questions,
	handlePrint,
	setFilter,
	values,
	questionsAnswered,
	countdownTimerDuration,
	stopwatchTimer,
	numberQuestions,
	isSimulate = false,
}: MenuQuestionsProps) => {
	const [openPrintModal, setOpenPrintModal] = useState(false)

	const { theme, questionPrint } = useAppSelector((state) => state)
	const dispatch = useAppDispatch()

	const questionsDesc = () => {
		if (questionsAnswered || questionsAnswered === 0) {
			if (countdownTimerDuration) {
				const hours = Math.floor(countdownTimerDuration / 3600)
				const minutes = Math.floor((countdownTimerDuration % 3600) / 60)
				const seconds = countdownTimerDuration % 60

				return (
					<div>
						<div className={questionsNumber()}>
							<h6>{`${hours.toString().padStart(2, '0')}:${minutes
								.toString()
								.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</h6>
							<p>Tempo de prova</p>
						</div>
						<div className={questionsNumber()}>
							<h6>
								{questionsAnswered}/{numberQuestions || questions.length}
							</h6>
							<p>Total de Perguntas</p>
						</div>
					</div>
				)
			}

			return (
				<div className={questionsNumber()}>
					<h6>
						{questionsAnswered}/{numberQuestions || questions.length}
					</h6>
					<p>Total de Perguntas</p>
				</div>
			)
		}

		return (
			<div className={questionsNumber()}>
				<h6>{numberQuestions || questions.length}</h6>
				<p>Questões encontradas</p>
			</div>
		)
	}

	return (
		<div className={container()}>
			{questionsDesc()}

			{countdownTimerDuration && (
				<CountdownTimer duration={countdownTimerDuration} caution />
			)}

			{stopwatchTimer && <Stopwatch />}

			<div className={rowContainer()}>
				<div className={changeSizeContainer()}>
					<button
						onClick={() => dispatch(fontQuestionCreators.incrementValue())}
						type='button'
					>
						A+
					</button>
					<button
						onClick={() => dispatch(fontQuestionCreators.decrementValue())}
						type='button'
					>
						A-
					</button>
				</div>
			</div>

			<div className={rowContainer()}>
				{handlePrint && (
					<button
						type='button'
						className={buttonIcon()}
						onClick={() => {
							setOpenPrintModal(true)
						}}
					>
						<Print20Regular />
						<Paragraph paragraphType='p2' css={{ color: '$neutral100' }}>
							Imprimir
						</Paragraph>
					</button>
				)}
				<button
					type='button'
					className={buttonIcon()}
					onClick={() =>
						dispatch(
							themeCreators.setTheme(theme.value === 'dark' ? 'light' : 'dark')
						)
					}
				>
					<WeatherMoon20Regular />
					<Paragraph paragraphType='p2' css={{ color: '$neutral100' }}>
						Modo Noturno
					</Paragraph>
				</button>
			</div>

			<SelectBase
				name='readingMode'
				onChange={setFilter}
				placeholder='Modo scroll'
				options={[
					{ value: 1, label: 'Modo scroll' },
					{ value: 2, label: 'Modo foco' },
				]}
				selectedValue={values?.readingMode}
			/>

			{!isSimulate && (
				<SelectBase
					name='QuestionsSolve'
					placeholder='Questões a resolver:  50'
					onChange={setFilter}
					options={[
						{ value: '100', label: 'Questões a resolver:  100' },
						{ value: '75', label: 'Questões a resolver:  75' },
						{ value: '50', label: 'Questões a resolver:  50' },
						{ value: '25', label: 'Questões a resolver:  25' },
					]}
					selectedValue={values.QuestionsSolve}
				/>
			)}

			<ModalBase
				title='Imprimir questões!'
				open={openPrintModal}
				onOpenChange={setOpenPrintModal}
				desc='Selecione o formato de exibição da impressão que você deseja realizar.'
			>
				<div
					style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
				>
					<Select
						placeholder='Selecione o formato de exibição'
						css={{
							marginBottom: '34px',
							marginTop: '32px',
							button: {
								height: '80px',
							},
						}}
						selectedValue={questionPrint.printType}
						options={[
							{
								option:
									'Exibir apenas o enunciado e gabarito das questões realizadas até o momento',
								value: '1',
							},
							{
								option: 'Exibir apenas o enunciado',
								value: '2',
							},
							{
								option: 'Exibir enunciado e gabarito de todas as questões',
								value: '3',
							},
						]}
						onChange={(e) => {
							dispatch(
								questionPrintcreators.setQuestionPrintType({
									type: String(e.value),
								})
							)
						}}
					/>
					<div style={{ display: 'flex', gap: '26px', marginTop: '34px' }}>
						<Button
							onClick={() => {
								if (handlePrint) {
									handlePrint()
									setOpenPrintModal(false)
								}
							}}
						>
							Imprimir
						</Button>
						<Button
							outlined
							onClick={() => {
								setOpenPrintModal(false)
							}}
						>
							Cancelar
						</Button>
					</div>
				</div>
			</ModalBase>
		</div>
	)
}
