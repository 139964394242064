import { css } from '@styles/theme'

export const questionCommentStyles = {
	containerComment: css({
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		width: '100%',
		height: '100%',
		padding: '16px',
		margin: '28px 0px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',
	}),

	containerComments: css({
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		width: '100%',
		height: '100%',

		background: '$neutral10',
		borderTop: '1px solid $neutral50',
	}),

	containerTextArea: css({
		display: 'flex',
		flexDirection: 'column',
		gap: '22px',
		width: '100%',
		border: '1px solid $neutral50',
		borderRadius: '14px',
		padding: '16px 20px',
	}),

	commentDiv: css({ display: 'flex', gap: '8px', marginTop: '24px' }),
	commentButton: css({ display: 'flex', justifyContent: 'flex-end' }),

	buttonDelete: css({
		cursor: 'pointer',
		display: 'flex',
		gap: '8px',
		alignItems: 'center',

		svg: {
			width: '14px',
			height: '14px',
			color: '$red',
		},
	}),

	containerModalDelete: css({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '80px',
	}),

	buttonsModalDelete: css({
		display: 'flex',
		width: '100%',
		gap: '32px',
	}),
}
