import { css } from '@styles/theme'

export const ParagraphStyles = {
	base: css({
		color: '$text',
		_font: 'normal',
		variants: {
			paragraphType: {
				p1: {
					fontSize: '$lg',
					lineHeight: '19px',
					fontWeight: 400,

					media: {
						md: {
							fontSize: '$p',
							fontWeight: 700,
						},
					},
				},
				p2: {
					fontSize: '$md',
					fontWeight: 400,
					lineHeight: '18px',

					media: {
						md: {
							fontSize: '$p',
							fontWeight: 500,
						},
					},
				},
				p3: {
					fontSize: '$p',
					fontWeight: 600,
					lineHeight: '21px',
					letterSpacing: '0.04em',

					media: {
						md: {
							fontSize: '$p',
							fontWeight: 400,
						},
					},
				},
			},
		},
	}),
}
