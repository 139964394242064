import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { RECAPTCHA_KEY } from '@helpers/constants'
import { AppRoutes } from '@routes'
import { store } from '@store'
import './i18n'
import '@styles/fonts.css'
import 'moment/locale/pt-br'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

/* The Strict Mode is triggering useEffect twice on
   development mode because test of future new features
*/

const tagManagerArgs = {
	gtmId: 'GTM-NC9LH69',
}

TagManager.initialize(tagManagerArgs)
ReactGA.initialize('G-B5VPJLW50H')

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
				<Suspense
					fallback={
						<div style={{ maxWidth: '100%' }}>
							{/* <Lottie animationData={WhiteLoader} loop /> */}
						</div>
					}
				>
					<Router>
						<AppRoutes />
					</Router>
				</Suspense>
			</GoogleReCaptchaProvider>
		</Provider>
	</React.StrictMode>
)
