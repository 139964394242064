import React from 'react'

import { motion } from 'framer-motion'

interface AnimationProps {
	children: React.ReactNode
	animation?: 'fade'
}

export function PageTransition({
	children,
	animation = 'fade',
}: AnimationProps) {
	const animations = {
		fade: {
			initial: { opacity: 0 },
			animate: { opacity: 1 },
			exit: { opacity: 0 },
		},
	}

	return <motion.div {...animations[animation]}>{children}</motion.div>
}
