import { Checkmark20Regular, Dismiss20Regular } from '@fluentui/react-icons'
import { styled } from '@styles/theme'

export const stylesRadioButton = {
	RadioButtonStitcherComponent: styled('button', {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		maxWidth: '100%',
		width: '100%',

		transition: 'all 0.3s',

		'@sm': {
			maxWidth: '327px',
		},
	}),

	Container: styled('div', {
		display: 'flex',
		flex: '1',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 16px',
		backgroundColor: '$neutral10',

		width: '100%',
		height: '50px',
		minHeight: '50px',

		borderRadius: '12px',
		border: '1px solid $neutral50',

		cursor: 'pointer',

		'&:hover': {
			borderColor: '$primary',
		},

		variants: {
			isCorrect: {
				true: {},
				false: {},
			},
			questionAnswer: {
				true: {},
			},
		},

		compoundVariants: [
			{
				isCorrect: true,
				questionAnswer: true,
				css: {
					borderColor: '$primary',
				},
			},
			{
				isCorrect: false,
				questionAnswer: true,
				css: {
					borderColor: '$neutral150',
				},
			},
		],
	}),

	ContainerWrapper: styled('div', {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '20px',
	}),

	Ellipse: styled('div', {
		width: '18px',
		height: '18px',
		borderRadius: '50%',
		backgroundColor: '$neutral30',
		border: '1px solid $neutral50',

		variants: {
			active: {
				true: {
					backgroundColor: '$primary',
					border: '1px solid $neutral30',
				},
			},
		},
	}),

	Checkmark: styled(Checkmark20Regular, {
		width: '27px',
		height: '27px',

		variants: {
			isCorrect: {
				true: {
					color: '$blue20',
				},
				false: {
					color: '$neutral150',
				},
			},
		},
	}),

	Dismiss: styled(Dismiss20Regular, {
		width: '27px',
		height: '27px',

		variants: {
			isCorrect: {
				true: {
					color: '$blue20',
				},
				false: {
					color: '$neutral150',
				},
			},
		},
	}),
}
