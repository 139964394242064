import { BASE_URL } from '@helpers/constants'
import { getBrowserName, getBrowserVersion } from '@helpers/osInfo'
import setAuthorization from '@helpers/setAuthorization'
import {
	CreatePromotional,
	CreatePromotionalResponse,
	PromotionalLP,
} from '@models/promotionalLP.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const promotionalLPApi = createApi({
	reducerPath: 'promotionalLPApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) => {
			if (endpoint.includes('/promotional')) {
				headers.delete('Authorization')

				return headers
			}

			const newHeaders = setAuthorization(headers, { getState, endpoint })

			return newHeaders
		},
	}),
	endpoints: (builder) => ({
		getPromotional: builder.query<PromotionalLP, void>({
			query: () => ({
				url: '/promotional',
				method: 'GET',
			}),
		}),
		authenticate: builder.mutation<
			CreatePromotionalResponse,
			CreatePromotional
		>({
			query: (data) => ({
				url: '/promotional',
				method: 'POST',
				body: {
					...data,
					platform: getBrowserName(navigator.userAgent),
					version: getBrowserVersion(navigator.userAgent),
				},
				headers: {
					platform: 'web',
				},
			}),
		}),
	}),
})
