import { css } from '@styles/theme'

export const questionStatistcsStyles = {
	container: css({
		width: '100%',
		background: '$neutral20',
		marginBottom: '32px',
		borderRadius: '12px',
		padding: '24px',
	}),

	rowContainer: css({
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'center',
	}),
}
