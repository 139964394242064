import { styled } from '@styles/theme'

export const stylesStopwatch = {
	ConatinerStopwatch: styled('div', {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		width: '100%',
		maxWidth: '105px',
		minHeight: '40px',
		padding: '8px',
		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',

		_font: 'normal',
		fontWeight: '500',
		fontSize: '$md',
		lineHeight: '21px',
		letterSpacing: '0.05em',

		color: '$neutral160',

		svg: {
			width: '24px',
			height: '24px',
		},

		'&:hover': {
			color: '$blue20',
		},
	}),
}
