import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import {
	ListDisciplineResponse,
	ListDisciplineResquest,
} from '@models/disciplines.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const disciplinesAPI = createApi({
	reducerPath: 'disciplinesAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		list: builder.query<ListDisciplineResponse, ListDisciplineResquest>({
			query: ({ page, limit = '10', search = '' }) => ({
				url: `/tags?page=${page}&limit=${limit}&search=${search}`,
				method: 'GET',
			}),
		}),
		get: builder.mutation<ListDisciplineResponse, ListDisciplineResquest>({
			query: ({ page, limit, search = '' }) => ({
				url: `/tags?page=${page}&limit=${limit}&search=${search}`,
				method: 'GET',
			}),
		}),
	}),
})
