import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { successMessages } from '@/constants/feedbackMessages'
import { DismissCircle24Regular } from '@fluentui/react-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { commentSchema } from '@schemas/questions/questions.schema'
import { coursesAPI } from '@services/CoursesAPI'
import { dashboardAPI } from '@services/DashboardAPI'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import moment from 'moment'

import { Avatar } from '../Avatar'
import { Button } from '../Button'
import { Loader } from '../Loader'
import { ModalBase } from '../ModalBase'
import { TextAreaCKE } from '../TextAreaCKE'
import { Paragraph, Small, Title } from '../Typography'
import { questionCommentStyles } from './styles'

const {
	containerComment,
	containerTextArea,
	containerComments,
	commentDiv,
	commentButton,
	buttonDelete,
	containerModalDelete,
	buttonsModalDelete,
} = questionCommentStyles

type FormCommentValue = {
	message: string
}

type QuestionComment = {
	id: number
}

export function CourseComment({ id }: QuestionComment) {
	const [openModalDeleteComment, setOpenModalDeleteComment] = useState(false)
	const [reset, setReset] = useState<boolean>(false)
	const [idDeletedComment, setIdDeletedComment] = useState<number>()

	const dispatch = useAppDispatch()

	const {
		register,
		handleSubmit,
		reset: resetComment,
		formState: { errors },
	} = useForm<FormCommentValue>({ resolver: yupResolver(commentSchema) })

	const [GetMe, { isSuccess, data }] = dashboardAPI.useGetMeMutation()

	const [
		getCommentsContent,
		{
			data: dataCommentsContent,
			isSuccess: isSuccessQuestionComments,
			isLoading,
		},
	] = coursesAPI.useGetCommentsContentMutation()

	const [createCommentContent, { isLoading: isLoadingCreateComment }] =
		coursesAPI.useCreateCommentContentMutation()
	const [deleteComment, { isSuccess: isSuccessDeleteComment }] =
		coursesAPI.useDeleteCommentContentMutation()

	const onSubmitComment = handleSubmit((payload) => {
		createCommentContent({
			contentId: id,
			message: payload.message,
		}).then(() => {
			resetComment()
			setReset(true)
			setTimeout(() => {
				setReset(false)
			}, 100)
			getCommentsContent(id)
			dispatch(notificationCreators.show(successMessages.createComment))
		})
	})

	const handleDeleteComment = async () => {
		if (idDeletedComment) {
			deleteComment(idDeletedComment)
		}
	}

	useEffect(() => {
		GetMe()
		getCommentsContent(id)
	}, [GetMe, getCommentsContent, id])

	useEffect(() => {
		if (isSuccessDeleteComment) {
			setOpenModalDeleteComment(false)
			getCommentsContent(id)
			dispatch(notificationCreators.show(successMessages.deletedComment))
		}
	}, [dispatch, getCommentsContent, id, isSuccessDeleteComment])

	return (
		<>
			<form className={containerComment()} onSubmit={onSubmitComment}>
				<div className={commentDiv()}>
					<Avatar
						src={(isSuccess && data?.imageUrl) || ''}
						alt='avatar'
						name={(isSuccess && data?.name) || ''}
						showName={false}
					/>
					<TextAreaCKE
						placeholder='Escreva seu comentário'
						value=''
						reset={reset}
						register={register('message')}
						error={errors.message?.message}
						name='message'
					/>
				</div>
				<div className={commentButton()}>
					<Button
						css={{ '@md': { maxWidth: '219px' } }}
						loading={isLoadingCreateComment}
						disabled={isLoadingCreateComment}
					>
						Comentar
					</Button>
				</div>

				{isLoading && <Loader />}

				{isSuccessQuestionComments &&
					dataCommentsContent &&
					dataCommentsContent.comments.map((comment, indexKey) => (
						<div className={containerComments()} key={indexKey}>
							<div className={commentDiv()}>
								<Avatar
									alt={comment.userUrl}
									name={comment.userName}
									showName={false}
								/>

								<div className={containerTextArea()}>
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<div
											style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}
										>
											<Paragraph
												paragraphType='p3'
												css={{ color: '$neutral120' }}
											>
												{comment.userName}
											</Paragraph>
											<Small smallType='small1' css={{ color: '$neutral70' }}>
												{moment(comment.date).locale('pt-br').format('LLL')}
											</Small>
										</div>

										<div>
											{Boolean(comment.fromLoggedUser) && (
												<button
													type='button'
													className={buttonDelete()}
													onClick={() => {
														setOpenModalDeleteComment(true)
														setIdDeletedComment(comment.id)
													}}
												>
													<DismissCircle24Regular />
													<Small smallType='small1' css={{ color: '$red' }}>
														Excluir
													</Small>
												</button>
											)}
										</div>
									</div>

									<Paragraph
										paragraphType='p2'
										css={{ color: '$neutral120' }}
										dangerouslySetInnerHTML={{ __html: comment.message }}
									/>
									{comment.response && (
										<>
											<hr style={{ color: '$neutral120' }} />
											<Paragraph
												paragraphType='p1'
												css={{ color: '$neutral120' }}
											>
												Resposta
											</Paragraph>
											<Paragraph
												paragraphType='p2'
												css={{ color: '$neutral120' }}
												dangerouslySetInnerHTML={{ __html: comment.response }}
											/>
										</>
									)}
								</div>
							</div>
						</div>
					))}
			</form>

			<ModalBase
				title=''
				open={openModalDeleteComment}
				onOpenChange={setOpenModalDeleteComment}
			>
				<div className={containerModalDelete()}>
					<Title variant='h2' color='$neutral120'>
						Deseja excluir seu comentário?
					</Title>

					<div className={buttonsModalDelete()}>
						<Button color='danger' onClick={handleDeleteComment}>
							Excluir
						</Button>
						<Button
							outlined
							color='secondary'
							onClick={() => {
								setOpenModalDeleteComment(false)
							}}
						>
							Cancelar
						</Button>
					</div>
				</div>
			</ModalBase>
		</>
	)
}
