import { LocalStorage } from '@helpers'
import { createSlice } from '@reduxjs/toolkit'

interface SideBarState {
	value: string
}

const storage = new LocalStorage()

const initialState: SideBarState = {
	value: storage.get('sideBar') === 'true' ? 'true' : 'true',
	// comportamento default abaixo
	/* 	value: storage.get('sideBar') === 'true' ? 'false' : 'true', */
}

export const headerSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		openSidebar: (state) => {
			state.value = state.value === 'true' ? 'false' : 'true'
			storage.set('sideBar', state.value === 'true' ? 'false' : 'true')
		},
	},
})

export const headerCreators = headerSlice.actions

export const headerReducer = headerSlice.reducer
