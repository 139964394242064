import React, { useEffect } from 'react'

import { questionsAPI } from '@services/QuestionsAPI'

import { ChartDoughnut } from '../ChartDoughnut'
import { Title } from '../Typography'
import { questionStatistcsStyles } from './styles'

const { container, rowContainer } = questionStatistcsStyles

type QuestionComment = {
	id: number
}

export function QuestionStatistics({ id }: QuestionComment) {
	const [
		getQuestionStatistics,
		{ data: dataQuestionStatistics, isSuccess: isSuccessQuestionStatistics },
	] = questionsAPI.useGetQuestionStatisticsMutation()

	useEffect(() => {
		getQuestionStatistics(id)
	}, [getQuestionStatistics, id])

	return (
		<div className={container()}>
			<Title variant='h3' color='$neutral160'>
				Percentual geral
			</Title>
			{isSuccessQuestionStatistics && dataQuestionStatistics && (
				<div className={rowContainer()}>
					<div>
						<ChartDoughnut
							hits={dataQuestionStatistics?.correctCount}
							errors={dataQuestionStatistics?.incorrectCount}
							showTitle={false}
							css={{ border: '0px', height: 'auto', padding: '0px' }}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
