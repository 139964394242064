import { useState, useEffect, useCallback } from 'react'

import { questionsAPI } from '@services/QuestionsAPI'

export type StateSelectProps = {
	value?: string | number | object
	label?: string
	id?: string | number
}

export type StateProps = {
	value?: string | number | object
	name?: string
	id?: string | number
}

export type FilterStateProps = {
	filterSaved: StateProps
	disciplines: Array<StateProps>
	materId: Array<StateProps>
	subMaterId: Array<StateProps>
	year: Array<StateProps>
	office: Array<StateProps>
	juries: Array<StateProps>
	institutions: Array<StateProps>
	comment: string
	quest: string
	mode: string
	readingMode: StateSelectProps
	QuestionsSolve: StateSelectProps
}

export type ErrorStateProps = {
	errorType: { value?: string }
}

export default function useQuestions() {
	const [values, setValues] = useState<FilterStateProps>({
		filterSaved: {},
		disciplines: [],
		materId: [],
		subMaterId: [],
		year: [],
		office: [],
		juries: [],
		institutions: [],
		comment: 'all',
		quest: 'all',
		mode: 'order',
		readingMode: { value: 1, label: 'Modo scroll' },
		QuestionsSolve: { value: '50', label: 'Questões a resolver:  50' },
	})
	const [pageReadMode, setPageReadMode] = useState(1)

	const [
		getQuestion,
		{
			data: dataQuestions,
			isSuccess: isSuccessQuestions,
			isLoading: isLoadingQuestions,
		},
	] = questionsAPI.useGetQuestionsMutation()

	const clearFilter: () => void = () => {
		setPageReadMode(0)
		setValues({
			filterSaved: { value: 0, name: 'Selecione um filtro salvo' },
			disciplines: [],
			materId: [],
			subMaterId: [],
			year: [],
			office: [],
			juries: [],
			institutions: [],
			comment: 'all',
			quest: 'all',
			mode: 'order',
			readingMode: { value: 1, label: 'Modo scroll' },
			QuestionsSolve: { value: '50', label: 'Questões a resolver:  50' },
		})
	}

	const handleFilterNextPage = () => {
		setPageReadMode((prev) => {
			getQuestion({
				page: prev + 1,
				value: {
					discipline: values.disciplines.map((value) => value.id).join(','),
					matter: values.materId.map((value) => value.id).join(','),
					submatter: values.subMaterId.map((value) => value.id).join(','),
					year: values.year.map((value) => value.id).join(','),
					position: values.office.map((value) => value.id).join(','),
					jury: values.juries.map((value) => value.id).join(','),
					institution: values.institutions.map((value) => value.id).join(','),
					status: values.quest,
					comment: values.comment,
					limit: values.QuestionsSolve.value,
					mode: values.mode,
				},
			})

			return prev + 1
		})
	}

	const getQuestions = useCallback(
		(reset = false) => {
			setPageReadMode(1)
			if (reset) {
				getQuestion({
					page: 1,
					value: {
						discipline: '',
						matter: '',
						submatter: '',
						year: '',
						position: '',
						jury: '',
						institution: '',
						status: '',
						comment: 'all',
						limit: '50',
						mode: 'order',
					},
				})
			} else {
				getQuestion({
					page: 1,
					value: {
						discipline: values.disciplines.map((value) => value.id).join(','),
						matter: values.materId.map((value) => value.id).join(','),
						submatter: values.subMaterId.map((value) => value.id).join(','),
						year: values.year.map((value) => value.id).join(','),
						position: values.office.map((value) => value.id).join(','),
						jury: values.juries.map((value) => value.id).join(','),
						institution: values.institutions.map((value) => value.id).join(','),
						status: values.quest,
						comment: values.comment,
						limit: values.QuestionsSolve.value,
						mode: values.mode,
					},
				})
			}
		},
		[
			getQuestion,
			values.QuestionsSolve.value,
			values.comment,
			values.disciplines,
			values.institutions,
			values.juries,
			values.materId,
			values.mode,
			values.office,
			values.quest,
			values.subMaterId,
			values.year,
		]
	)

	useEffect(() => {
		if (values.disciplines.length === 0) {
			setValues((prevState) => ({
				...prevState,
				materId: [],
				subMaterId: [],
			}))
		}
	}, [values.disciplines])

	useEffect(() => {
		if (values.materId.length === 0) {
			setValues((prevState) => ({
				...prevState,
				subMaterId: [],
			}))
		}
	}, [values.materId])

	return {
		dataQuestions,
		isSuccessQuestions,
		isLoadingQuestions,
		setValues,
		values,
		clearFilter,
		setPageReadMode,
		pageReadMode,
		handleFilterNextPage,
		getQuestions,
	}
}
