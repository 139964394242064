import { createStitches } from '@stitches/react'

export const stitchesConfig = createStitches({
	theme: {
		colors: {
			primary: '',
			blue20: '',
			blue30: '',
			blue40: '',
			orange10: '',
			orange20: '',
			orange30: '',
			orange40: '',
			neutral10: '',
			neutral20: '',
			neutral30: '',
			neutral40: '',
			neutral50: '',
			neutral60: '',
			neutral70: '',
			neutral80: '',
			neutral90: '',
			neutral100: '',
			neutral110: '',
			neutral120: '',
			neutral130: '',
			neutral140: '',
			neutral150: '',
			neutral160: '',
			gold: '',
			siler: '',
			bronze: '',
			green: '',
			yellow: '',
			red: '',
			disabled: '',
			text: '',
			modalOverlay: '',
			courseCardBackground: '',
		},
		fontWeights: {
			bold: '700',
			semiBold: '600',
			medium: '500',
			normal: '400',
		},
		fontSizes: {
			sm2: '8px',
			sm: '10px',
			p: '12px',
			md: '14px',
			lg: '16px',
			xl: '18px',
			xxl: '20px',
			xxll: '22px',
			xxxl: '24px',
		},
		fonts: {
			primary: 'Roboto',
			primaryMedium: 'Roboto-Medium',
			primaryBold: 'Roboto-Bold',
		},
		sizes: {
			defaultContainer: '1120px',
		},
		space: {
			1: '4px',
			2: '8px',
			3: '10px',
			4: '20px',
			5: '24px',
			6: '32px',
			7: '40px',
			8: '48px',
			9: '64px',
			10: '80px',
		},
	},
	media: {
		xs: '(min-width: 320px)',
		sm: '(min-width: 540px)',
		md: '(min-width: 768px)',
		lg: '(min-width: 992px)',
		xl: '(min-width: 1200px)',
		resp: '(min-width: 1245px)',
		'2xl': '(min-width: 1600px)',
	},
	utils: {
		my: (value: string) => ({
			marginTop: value,
			marginBottom: value,
		}),
		py: (value: string) => ({
			paddingTop: value,
			paddingBottom: value,
		}),
		_font: (value: 'normal' | 'medium' | 'bold') => {
			const fonts = {
				normal: {
					fontFamily: '$primary',
					fontWeight: '$normal',
				},
				medium: {
					fontFamily: '$primaryMedium',
					fontWeight: '$medium',
				},
				bold: {
					fontFamily: '$primaryBold',
					fontWeight: '$bold',
				},
			}

			return fonts[value]
		},
	},
})
