import { css } from '@styles/theme'

export const menuQuestionsMobileStyles = {
	container: css({
		display: 'flex',
		flexDirection: 'column',
		gap: '40px',
		padding: '24px',
		background: '$neutral10',
		boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.12)',
		border: '1px solid $neutral50',
		borderRadius: '12px',
		marginTop: '24px',
	}),

	questionsNumber: css({
		display: 'flex',
		gap: '16px',
		alignItems: ' center',
		justifyContent: 'center',
		width: '100%',

		h6: {
			_font: 'medium',
			fontWeight: '500',
			fontSize: '$md',
			lineHeight: '21px',
			letterSpacing: '0.05em',
			color: '$neutral150',
		},

		p: {
			_font: 'normal',
			fontWeight: '400',
			fontSize: '$md',
			lineHeight: '20px',
			letterSpacing: '0.05em',
			color: '$neutral90',
		},
	}),

	changeSizeContainer: css({
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		height: '48px',
		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '4px',

		button: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '49.5%',
			cursor: 'pointer',
			color: '$neutral130',
			_font: 'medium',
			fontWeight: '500',
			borderLeft: '1px solid $neutral50',
			fontSize: '14px',
			lineHeight: '21px',
			letterSpacing: '0.05em',

			'&:first-child': {
				borderLeft: 'none',
			},
		},
	}),

	buttonIcon: css({
		display: 'flex',
		gap: '8px',
		cursor: 'pointer',
		color: '$neutral130',

		'&:hover': {
			color: '$primary',
		},
	}),

	rowContainer: css({
		display: 'flex',
		justifyContent: 'space-around',
		gap: '10px',
	}),
}
