import { Listbox } from '@headlessui/react'
import { css, styled } from '@styles/theme'

export const selectStyles = {
	ListboxButton: styled(Listbox.Button, {
		display: 'flex',
		fleDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '14px 16px',

		width: '100%',
		minWidth: '170px',
		height: '50px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',

		color: '$neutral160',

		fontWeight: '400',
		fontSize: '$lg',

		'&:hover': {
			borderColor: '$blue20',
			color: '$blue20',
		},

		svg: {
			width: '24px',
			height: '24px',
		},

		variants: {
			invalid: {
				true: {
					outlineColor: '$orange40',
				},
			},
			disabled: {
				true: {
					borderColor: '$disabled',
					color: '$disabled',
				},
			},
		},
	}),

	ListboxOptions: styled(Listbox.Options, {
		zIndex: '100',
		overflow: 'auto',
		position: 'absolute',
		marginTop: '8px',
		width: 'inherit',
		maxWidth: 'inherit',
		maxHeight: 300,
		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',
		color: '$neutral160',
	}),

	listboxOption: css({
		width: '100%',
		minHeight: '48px',

		display: 'flex',
		alignItems: 'center',
		paddingLeft: '18px',

		color: '$neutral150',

		_font: 'normal',
		fontSize: '$md',

		'&:hover': {
			borderColor: '$blue20',
			color: '$blue20',
			cursor: 'pointer',
		},
	}),

	Container: styled('div', {
		position: 'relative',
		float: 'left',
		width: '100%',
	}),

	error: css({
		color: '$red',
		_font: 'normal',
		fontWeight: '400',
		fontSize: '$md',
		lineHeight: '21px',
		paddingLeft: '16px',
	}),
}
