import React from 'react'
import { Link } from 'react-router-dom'

import { Title } from '../Typography'
import { tableHistoricStyles } from './style'

const { container, Table, link, containerTitle } = tableHistoricStyles

type PropsTableHistoricData = {
	name: string | number
	week: string | number
	date: string | number
	value: string | number
	time: string | number
}

type PropsTableHistoric = {
	data: Array<PropsTableHistoricData>
}

export const TableHistoric = ({ data }: PropsTableHistoric) => {
	return (
		<div className={container()}>
			<div className={containerTitle()}>
				<Title variant='h5' color='$neutral130'>
					Últimas 4 notas dos simulados
				</Title>
				<Link to='/simulados' className={link()}>
					Ver histórico geral
				</Link>
			</div>

			<Table>
				<thead className='custom-head'>
					<div style={{ gridArea: 'name' }}>Categoria</div>
					<div style={{ gridArea: 'week' }}>Simulado</div>
					<div style={{ gridArea: 'date' }}>Data</div>
					<div style={{ gridArea: 'value' }}>Nota</div>
					<div style={{ gridArea: 'time' }}>Tempo</div>
				</thead>
				<tbody className='custom-body'>
					{data.length > 0 &&
						data.map((item, index) => (
							<tr className='custom-row' key={index}>
								<td style={{ gridArea: 'name' }}>{item.name}</td>
								<td style={{ gridArea: 'week' }}>{item.week}</td>
								<td style={{ gridArea: 'date' }}>{item.date}</td>
								<td style={{ gridArea: 'value' }}>{item.value}</td>
								<td style={{ gridArea: 'time' }}>{item.time}</td>
							</tr>
						))}
				</tbody>
			</Table>
		</div>
	)
}
