import React, { useEffect } from 'react'

import { Checkmark20Regular } from '@fluentui/react-icons'
import { notebookAPI } from '@services/NotebookAPI'
import { questionsAPI } from '@services/QuestionsAPI'
import moment from 'moment'

import { questionHistoricStyles } from './styles'

const { containerTable, headTable, rowTable, bodyTable, CheckItem, SpanCheck } =
	questionHistoricStyles

type QuestionComment = {
	id: number
	notebookQuestionId?: number
}

export function QuestionHistoric({ id, notebookQuestionId }: QuestionComment) {
	const [
		getQuestionHistoric,
		{ data: dataQuestionHistoric, isSuccess: isSuccessQuestionHistoric },
	] = questionsAPI.useGetQuestionHistoricMutation()

	const [
		getNotebookQuestionHistoric,
		{
			data: dataNotebookQuestionHistoric,
			isSuccess: isSuccessNotebookQuestionHistoric,
		},
	] = notebookAPI.useGetNotebookQuestionHistoricMutation()

	useEffect(() => {
		if (notebookQuestionId) {
			getNotebookQuestionHistoric({
				notebookId: notebookQuestionId,
				questionId: id,
			})
		} else {
			getQuestionHistoric(id)
		}
	}, [getNotebookQuestionHistoric, getQuestionHistoric, id, notebookQuestionId])

	return (
		<div className={containerTable()}>
			<div className={headTable()}>
				<h4>Data</h4>
				<h4>Resposta</h4>
			</div>
			<div className={bodyTable()}>
				{notebookQuestionId
					? isSuccessNotebookQuestionHistoric &&
					  dataNotebookQuestionHistoric?.questionHistoric.map((item) => {
							return (
								<div className={rowTable()} key={item.id}>
									<h4>{moment(item.createdAt).format('DD/MM/YYYY')}</h4>
									<CheckItem error={!item.isCorrect}>
										<Checkmark20Regular />
										<SpanCheck>
											{item.isCorrect ? 'Acertei' : 'Errei'}
										</SpanCheck>
									</CheckItem>
								</div>
							)
					  })
					: isSuccessQuestionHistoric &&
					  dataQuestionHistoric?.questions.map((item) => {
							return (
								<div className={rowTable()} key={item.id}>
									<h4>{moment(item.createdAt).format('DD/MM/YYYY')}</h4>
									<CheckItem error={!item.isCorrect}>
										<Checkmark20Regular />
										<SpanCheck>
											{item.isCorrect ? 'Acertei' : 'Errei'}
										</SpanCheck>
									</CheckItem>
								</div>
							)
					  })}
			</div>
		</div>
	)
}
