import React, { useState } from 'react'

import { Folder20Regular } from '@fluentui/react-icons'

import { selectMoveStyles } from './styles'

const { ItemSelect, container } = selectMoveStyles

export interface Option {
	option: string
	value: string | number
	disabled?: boolean
	id?: string
}

interface SelectMoveProps {
	options: Array<Option>
	onChange: (option: Option) => void
}

export const SelectMove = ({ options, onChange }: SelectMoveProps) => {
	const [selected, setSelected] = useState<number>()

	return (
		<div className={container()}>
			{options.map((item, index) => {
				return (
					<ItemSelect
						type='button'
						key={index}
						onClick={() => {
							onChange(item)
							setSelected(index)
						}}
						selected={selected === index}
					>
						<Folder20Regular /> <p>{item.option}</p>
					</ItemSelect>
				)
			})}
		</div>
	)
}
