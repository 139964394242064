import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { authApi } from '@services/AuthApi'
import { coursesAPI } from '@services/CoursesAPI'
import { dashboardAPI } from '@services/DashboardAPI'
import { disciplinesAPI } from '@services/DisciplinesAPI'
import { filterAPI } from '@services/FiltersAPI'
import { notebookAPI } from '@services/NotebookAPI'
import { promotionalLPApi } from '@services/PromotionLPApi'
import { questionsAPI } from '@services/QuestionsAPI'
import { rankingAPI } from '@services/RankingAPI'
import { sharedAPi } from '@services/ShareAPI'
import { simulatedAPI } from '@services/SimulatedAPI'
import { trackingAPI } from '@services/TrackingAPI'

import { authReducer } from './ducks/auth'
import { errorReducer } from './ducks/error'
import { fontQuestionReducer } from './ducks/fontsQuestion'
import { headerReducer } from './ducks/header'
import { modalReducer } from './ducks/modal'
import { notificationReducer } from './ducks/notification'
import { premiumReducer } from './ducks/premium'
import { profileReducer } from './ducks/profile'
import { questionPrintReducer } from './ducks/questionPrint'
import { themeReducer } from './ducks/theme'
import { errorMiddleware } from './middlewares/error'

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[questionsAPI.reducerPath]: questionsAPI.reducer,
		[dashboardAPI.reducerPath]: dashboardAPI.reducer,
		[notebookAPI.reducerPath]: notebookAPI.reducer,
		[filterAPI.reducerPath]: filterAPI.reducer,
		[trackingAPI.reducerPath]: trackingAPI.reducer,
		[disciplinesAPI.reducerPath]: disciplinesAPI.reducer,
		[simulatedAPI.reducerPath]: simulatedAPI.reducer,
		[rankingAPI.reducerPath]: rankingAPI.reducer,
		[coursesAPI.reducerPath]: coursesAPI.reducer,
		[sharedAPi.reducerPath]: sharedAPi.reducer,
		[promotionalLPApi.reducerPath]: promotionalLPApi.reducer,
		auth: authReducer,
		modal: modalReducer,
		error: errorReducer,
		theme: themeReducer,
		header: headerReducer,
		premium: premiumReducer,
		profile: profileReducer,
		questionPrint: questionPrintReducer,
		notification: notificationReducer,
		fonts: fontQuestionReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(
			errorMiddleware,
			authApi.middleware,
			questionsAPI.middleware,
			dashboardAPI.middleware,
			notebookAPI.middleware,
			filterAPI.middleware,
			trackingAPI.middleware,
			disciplinesAPI.middleware,
			simulatedAPI.middleware,
			rankingAPI.middleware,
			coursesAPI.middleware,
			sharedAPi.middleware,
			promotionalLPApi.middleware
		),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
