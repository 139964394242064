import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { LocalStorage } from '@helpers'
import { Routes as RoutesType } from '@models/route/route.model'
import { authCreators } from '@store/ducks/auth'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { globalStyles } from '@styles/globalStyles'
import { dark, light } from '@styles/theme'
import { AnimatePresence } from 'framer-motion'

import { privateRoutes } from './private'
import { PrivateRoute } from './PrivateRoute'
import { publicRoutes } from './public'

const routes: RoutesType = [...publicRoutes, ...privateRoutes]

export function AppRoutes() {
	const {
		theme: { value },
	} = useAppSelector((state) => state)
	const location = useLocation()
	const dispatch = useAppDispatch()

	const storage = new LocalStorage()
	const token = storage.get('token')
	const { pathname, search } = location

	globalStyles()

	useEffect(() => {
		if (token && pathname !== '/logout') {
			dispatch(authCreators.authenticate({ token }))
		}
	}, [dispatch, token, pathname])

	useEffect(() => {
		if (value === 'light') {
			document.body.classList.remove(light || '')
			document.body.classList.add(dark || '')
		} else {
			document.body.classList.remove(dark || '')
			document.body.classList.add(light || '')
		}

		document.body.style.backgroundColor = 'var(--colors-neutral30)'
	}, [value])

	// trailing slash
	if (pathname !== '/' && pathname.match(/.*\/$/)) {
		return (
			<Navigate
				replace
				to={{
					pathname: pathname.replace(/\/+$/, ''),
					search,
				}}
			/>
		)
	}

	return (
		<AnimatePresence mode='wait'>
			<Routes location={location} key={location.pathname}>
				{routes.map((route) => {
					if (route.isPrivate) {
						return (
							<Route
								key={route.path}
								{...route}
								element={
									<PrivateRoute>
										<route.element />
									</PrivateRoute>
								}
							/>
						)
					}

					return (
						<Route key={route.path} {...route} element={<route.element />} />
					)
				})}

				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
		</AnimatePresence>
	)
}
