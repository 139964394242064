/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	useState,
	Fragment,
	useEffect,
	useRef,
	Dispatch,
	SetStateAction,
} from 'react'

import { ChevronDown20Regular, LockClosed16Filled } from '@fluentui/react-icons'
import { Combobox, Transition } from '@headlessui/react'
import { FilterStateProps } from '@hooks/useQuestion'
import { styled } from '@styles/theme'
import { useVirtualizer } from '@tanstack/react-virtual'

import { CheckBox } from '../CheckBox'
import { autoCompleteStyles } from './styles'

const {
	ComboboxButton,
	ComboboxButtonDisabled,
	ComboboxOptions,
	ComboboxOption,
	comboboxInput,
	ContainerComboboxInput,
	ScrollOptions,
	NumberSelected,
} = autoCompleteStyles

const Error = styled('span', autoCompleteStyles.error)

type StitchesProps = React.ComponentProps<typeof ComboboxButton>
type OptionsProps = { name: string; value: number | string }

interface SelectProps extends StitchesProps {
	error?: string
	options?: Array<OptionsProps>
	disabled?: boolean
	placeholder?: string
	selectedText?: string
	onChange: Dispatch<SetStateAction<FilterStateProps>> | undefined
	name: string
	selectedValue?: any
}

const VirtualizedList = ({ items }: { items: Array<OptionsProps> }) => {
	const parentRef = useRef<HTMLDivElement>(null)

	const count = items.length
	const virtualizer = useVirtualizer({
		count,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 45,
		overscan: 5,
	})

	const rowItems = virtualizer.getVirtualItems()

	return (
		<ScrollOptions ref={parentRef}>
			<div
				style={{
					height: `${virtualizer.getTotalSize()}px`,
					width: '100%',
					position: 'relative',
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						transform: `translateY(${
							rowItems && rowItems[0] && rowItems[0].start
								? rowItems[0].start
								: ''
						}px)`,
					}}
				>
					{rowItems.map((virtualRow) => (
						<Combobox.Option
							className={ComboboxOption()}
							key={virtualRow.key}
							value={items?.[virtualRow.index]}
							data-index={virtualRow.index}
							ref={virtualizer.measureElement}
						>
							{({ selected: itemSelected }) => (
								<CheckBox
									label={items?.[virtualRow.index].name}
									checked={itemSelected}
								/>
							)}
						</Combobox.Option>
					))}
				</div>
			</div>
		</ScrollOptions>
	)
}

export const AutocompleteBase = ({
	error,
	options = [],
	disabled,
	placeholder = 'Selecione',
	selectedText = 'Disciplina...',
	onChange,
	name,
	selectedValue,
	...props
}: SelectProps) => {
	const [selected, setSelected] = useState([])
	const [query, setQuery] = useState('')

	function removeAccents(str: string) {
		return str
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase()
	}

	const filteredOptions =
		query === ''
			? options
			: options.filter((item) =>
					removeAccents(item.name)
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(removeAccents(query).toLowerCase().replace(/\s+/g, ''))
			  )

	useEffect(() => {
		if (selectedValue !== undefined) {
			selectedValue === selected
				? setSelected(selected)
				: setSelected(selectedValue)
		}
	}, [selected, selectedValue, setSelected])

	return (
		<div style={{ position: 'relative', float: 'left', width: '100%' }}>
			<Combobox
				value={selected}
				onChange={(item) => {
					setSelected(item)
					if (onChange) {
						onChange((prevState) => ({
							...prevState,
							[name]: item,
						}))
					}
				}}
				disabled={disabled}
				multiple
				{...props}
			>
				{disabled ? (
					<ComboboxButtonDisabled>
						{Array.isArray(selected) && selected.length > 0
							? selectedText /*  selected.map((item: OptionsProps) => item.label).join(', ') */
							: placeholder}

						<div style={{ display: 'flex', gap: '16px' }}>
							{selected.length > 0 && (
								<NumberSelected>{selected.length} </NumberSelected>
							)}

							<div style={{ display: 'flex', gap: '8x' }}>
								<LockClosed16Filled />
								<ChevronDown20Regular />
							</div>
						</div>
					</ComboboxButtonDisabled>
				) : (
					<ComboboxButton>
						{Array.isArray(selected) && selected.length > 0
							? selectedText /*  selected.map((item: OptionsProps) => item.label).join(', ') */
							: placeholder}

						<div style={{ display: 'flex', gap: '16px' }}>
							{selected.length > 0 && (
								<NumberSelected>{selected.length} </NumberSelected>
							)}

							<ChevronDown20Regular />
						</div>
					</ComboboxButton>
				)}

				<Transition
					as={Fragment}
					leave='transition ease-in duration-100'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
					afterLeave={() => setQuery('')}
				>
					<ComboboxOptions>
						<ContainerComboboxInput>
							<Combobox.Input
								placeholder='Pesquisar'
								className={comboboxInput()}
								onChange={(event) => setQuery(event.target.value)}
							/>
						</ContainerComboboxInput>

						{filteredOptions.length === 0 && query !== '' ? (
							''
						) : (
							<VirtualizedList items={filteredOptions ?? []} />
						)}
					</ComboboxOptions>
				</Transition>
			</Combobox>

			<div>{error && <Error>{error}</Error>}</div>
		</div>
	)
}
