import React, { useState, useEffect, useRef } from 'react'

import { Clock16Regular } from '@fluentui/react-icons'

import { stylesCountdownTimer } from './style'

const { ConatinerCountdown } = stylesCountdownTimer

type CountdownTimerProps = {
	duration: number
	caution?: boolean
}
export const CountdownTimer = ({ duration, caution }: CountdownTimerProps) => {
	const [timeRemaining, setTimeRemaining] = useState(duration)
	const intervalRef = useRef<number | undefined>(undefined)

	useEffect(() => {
		intervalRef.current = window.setInterval(() => {
			setTimeRemaining((prevTime) => prevTime - 1)
		}, 1000)

		return () => window.clearInterval(intervalRef.current)
	}, [])

	useEffect(() => {
		if (timeRemaining === 0) {
			window.clearInterval(intervalRef.current)
		}
	}, [timeRemaining])

	const hours = Math.floor(timeRemaining / 3600)
	const minutes = Math.floor((timeRemaining % 3600) / 60)
	const seconds = timeRemaining % 60

	return (
		<ConatinerCountdown caution={caution}>
			<Clock16Regular />
			{`${hours.toString().padStart(2, '0')}:${minutes
				.toString()
				.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
		</ConatinerCountdown>
	)
}
