import { css, styled } from '@styles/theme'

export const chartsDoughnutStyles = {
	Container: styled('div', {
		padding: '24px',

		height: '280px',
		width: '100%',
		minWidth: '233px',
		maxWidth: '363px',

		background: '$neutral10',
		border: '2px solid $neutral50',
		borderRadius: '12px',
	}),
	containerChart: css({
		height: '217px',
		width: '100%',
		marginTop: '14px',
		minWidth: '233px',
		maxWidth: '363px',
	}),
}
