import { Combobox } from '@headlessui/react'
import { css, styled } from '@styles/theme'

export const autoCompleteStyles = {
	ComboboxButton: styled(Combobox.Button, {
		display: 'flex',
		fleDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '14px 16px',

		width: '100%',
		minWidth: '200px',
		height: '50px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',

		color: '$neutral160',

		fontWeight: '400',
		fontSize: '$lg',

		'&:hover': {
			borderColor: '$blue20',
			color: '$blue20',
		},

		svg: {
			width: '24px',
			height: '24px',
		},

		variants: {
			invalid: {
				true: {
					borderColor: '$orange40',
				},
			},
			disabled: {
				true: {
					borderColor: '$disabled',
					color: '$disabled',
					'&:hover': {
						borderColor: '$disabled',
						color: '$blue20',
					},
				},
			},
		},
	}),

	ComboboxButtonDisabled: styled(Combobox.Button, {
		display: 'flex',
		fleDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '14px 16px',

		width: '100%',
		minWidth: '200px',
		height: '50px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '12px',

		fontWeight: '400',
		fontSize: '$lg',

		borderColor: '$disabled',
		color: '$disabled',

		'&:hover': {
			borderColor: '$neutral50',
			color: '$neutral50',
		},

		svg: {
			width: '24px',
			height: '24px',
		},
	}),

	ComboboxOptions: styled(Combobox.Options, {
		zIndex: '100',
		position: 'absolute',
		marginTop: '8px',
		width: 'inherit',
		maxWidth: 'inherit',
		maxHeight: '410px',

		background: '$neutral10',
		outline: '1px solid $neutral50',
		borderRadius: '12px',
		color: '$neutral160',
	}),

	ContainerComboboxInput: styled('div', {
		width: '100%',
		minWidth: '200px',
		padding: '16px',

		borderBottom: '1px solid $neutral40',
		background: '$neutral10',
		borderRadius: '12px',
		color: '$neutral160',
	}),

	ScrollOptions: styled('div', {
		overflowY: 'scroll',
		height: 'fit-content',
		maxHeight: '328px',

		'&::-webkit-scrollbar': {
			width: '12px',
			background: '$neutral50',
			borderRadius: '0px 0px 14px 0px',
		},
		'&::-webkit-scrollbar-track': {
			width: '12px',
		},
		'&::-webkit-scrollbar-thumb': {
			width: '6px',
			height: '37px',
			border: '3px solid $neutral50',
			background: '$primary',
			borderRadius: '6px',
		},
	}),

	comboboxInput: css({
		width: '100%',
		height: '50px',

		padding: '14px',
		background: '$neutral40',
		borderRadius: '14px',
		color: '$neutral70',

		_font: 'normal',
		fontSize: '$md',

		'&::placeholder': {
			_font: 'normal',
			fontWeight: '400',
			fontSize: '$md',
			lineHeight: '20px',
			color: '$neutral70',
		},
	}),

	ComboboxOption: css({
		width: '100%',
		alignItems: 'center',
		minWidth: '200px',
		minHeight: '48px',

		display: 'flex',
		paddingTop: '10px',
		paddingBottom: '10px',
		paddingLeft: '16px',
		paddingRight: '12px',

		color: '$neutral150',

		_font: 'normal',
		fontSize: '$md',
		lineHeight: '20px',

		'&:hover, &[aria-selected="true"]': {
			background: '$neutral30',
			outlineColor: '$blue20',
			color: '$blue20',
		},

		'&[aria-selected="true"]': {
			_font: 'medium',
			fontWeight: '500',
		},
	}),

	NumberSelected: styled('div', {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: '24px',
		minHeight: '20px',
		background: '$blue20',
		color: '$neutral10',
		borderRadius: '100%',

		_font: 'normal',
	}),

	error: css({
		color: '$red',
		_font: 'normal',
		fontWeight: '400',
		fontSize: '$md',
		lineHeight: '21px',
		paddingLeft: '16px',
	}),
}
