import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ProfileValue {
	id: string
	name?: string
	email?: string
	is_paid: boolean
}

const initialState: ProfileValue = {
	id: '',
	name: undefined,
	email: undefined,
	is_paid: false,
}

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setIsProfile: (
			state,
			{ payload: { id, name, email, is_paid } }: PayloadAction<ProfileValue>
		) => {
			return { ...state, id, name, email, is_paid }
		},
	},
})

export const profileCreators = profileSlice.actions

export const profileReducer = profileSlice.reducer
