import React, { useEffect } from 'react'

import { FilesPDF } from '@models/course.model'
import { coursesAPI } from '@services/CoursesAPI'
import Lottie from 'lottie-react'

import BlueLodaer from '../JsonLottie/BlueLoader.json'
import { LoaderStyles } from '../Loader/style'
import { fileStyle } from './style'

const {
	ContainerButton,
	IconContainer,
	IconCheckmark12Filled,
	DocumentPdf20RegularStyled,
} = fileStyle

const { Container } = LoaderStyles

export const FilePDF = ({ name, file, id, downloaded }: FilesPDF) => {
	const [getFilePDF, { isSuccess, isLoading, data }] =
		coursesAPI.useGetFilePdfMutation()

	function saveAs(blob: Blob, fileName: string) {
		const anchor = document.createElement('a')

		anchor.download = fileName
		anchor.href = URL.createObjectURL(blob)
		anchor.click()
		URL.revokeObjectURL(anchor.href)
	}

	const handleDownload = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.preventDefault()
		getFilePDF({ name: file, fileId: id })
	}

	useEffect(() => {
		if (isSuccess && data) {
			saveAs(data, name)
		}
	}, [isSuccess, data, name])

	return (
		<ContainerButton
			type='button'
			onClick={handleDownload}
			isSuccess={!!downloaded || isSuccess}
		>
			<IconContainer isSuccess={!!downloaded || isSuccess}>
				{isLoading ? (
					<Container>
						<Lottie animationData={BlueLodaer} loop />
					</Container>
				) : (
					<>
						<DocumentPdf20RegularStyled />
						{(downloaded || isSuccess) && <IconCheckmark12Filled />}
					</>
				)}
			</IconContainer>

			<p>{name}</p>
		</ContainerButton>
	)
}
