import { css } from '@styles/theme'

export const tagsStyles = {
	radioGroupOption: css({
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		height: '27px',
		padding: '3px 8px',
		gap: '4px',

		background: '$neutral10',
		border: '1px solid $neutral50',
		borderRadius: '8px',

		_font: 'normal',
		fontWeight: 500,
		fontSize: '$md',
		lineHeight: ' 21px',

		letterSpacing: '0.05em',
		color: '$neutral90',
		cursor: 'pointer',

		variants: {
			checked: {
				true: { color: '$blue20', border: '1px solid $blue20' },
			},
			disabledItem: {
				true: { border: '1px solid $disabled' },
			},
		},
	}),

	containerTags: css({
		display: 'flex',
		alignItems: 'center',
		gap: '16px',
		flexWrap: 'wrap',

		'@sm': {
			flexWrap: 'nowrap',
		},
	}),
}
