import React from 'react'

import { styled } from '@styles/theme'
import Lottie from 'lottie-react'

import { Icon } from '../Icon'
import WhiteLoader from '../JsonLottie/WhiteLoader.json'
import { buttonStyles } from './styles'

export const StitchesButton = styled('button', buttonStyles.base)

type StitchesButtonProps = React.ComponentProps<typeof StitchesButton>

interface ButtonProps extends StitchesButtonProps {
	loading?: boolean
	icon?: string
}

export function Button({ loading, children, icon, ...props }: ButtonProps) {
	return (
		<StitchesButton {...props} loading={loading}>
			{children}
			{icon && (
				<span className='icon'>
					<Icon title={icon} name={icon} width={18} height={18} />
				</span>
			)}
			{loading && (
				<div style={{ maxWidth: '43px' }}>
					<Lottie animationData={WhiteLoader} loop />
				</div>
			)}
		</StitchesButton>
	)
}
