import { useLayoutEffect, useState } from 'react'

const useIsMobile = (): boolean => {
	const [isMobile, setIsMobile] = useState(false)

	useLayoutEffect(() => {
		const updateIsMobile = () => {
			setIsMobile(window.innerWidth < 768)
		}

		updateIsMobile()

		window.addEventListener('resize', updateIsMobile)

		return () => {
			window.removeEventListener('resize', updateIsMobile)
		}
	}, [])

	return isMobile
}

export default useIsMobile
