import { Link } from 'react-router-dom'

import { styled } from '@styles/theme'

export const menuItemStyles = {
	MenuItemStitchesComponent: styled(Link, {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',

		height: '56px',

		padding: '24px 5px 21px 24px',

		textDecoration: 'none',
		cursor: 'pointer',

		background: '$neutral10',
		position: 'relative',

		variants: {
			isActive: {
				true: {
					background: '$neutral30',
				},
			},
			isOpened: {
				true: {
					width: '100%',
				},
				false: {
					padding: '24px 21px !important',
				},
			},
			isAccordionItem: {
				true: { padding: '24px 10px 24px 64px' },
			},
		},
	}),

	IconContainer: styled('div', {
		minWidth: '24px',
		maxWidth: '24px',
		minHeight: '24px',
		maxHeight: '24px',

		color: '$neutral90',

		variants: {
			isActive: {
				true: {
					color: '$primary',
				},
			},
		},
	}),

	ActiveIndicator: styled('div', {
		width: '4.21px',
		height: '40px',

		background: '$primary',
		boxShadow: '0px 8px 12px rgba(135, 145, 233, 0.3)',
		borderRadius: '0px 8px 8px 0px',
		transform: 'rotate(-180deg)',

		position: 'absolute',
		right: '0',
	}),

	ContainerMenuAccordion: styled('div', {
		display: 'flex',
		alignItems: 'center',
		background: '$neutral10',

		button: {
			cursor: 'pointer',
			paddingRight: '8px',
			svg: {
				color: '$neutral90',
			},
		},

		variants: {
			isActive: {
				true: {
					background: '$neutral30',
					button: {
						svg: {
							color: '$blue20',
						},
					},
				},
			},
			isOpened: {
				true: {
					width: '100%',
				},
			},
			isOpen: {
				true: {
					button: {
						svg: {
							color: '$blue20',
						},
					},
				},
			},
		},
	}),

	ScrollableSection: styled('div', {
		overflowY: 'auto',
		maxHeight: '360px',
		variants: {
			zoomed: {
				true: {
					maxHeight: '150px',
				},
			},
		},
	}),
}
