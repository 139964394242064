import React, { useRef } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
	Alignment,
	BlockQuote,
	Bold,
	ClassicEditor,
	Essentials,
	Font,
	Heading,
	Indent,
	Italic,
	List,
	Strikethrough,
	Subscript,
	Superscript,
	Underline,
} from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'

import { textAreaStyles } from './styles'

const { Container, TextAreaContainer, ErrorSpan } = textAreaStyles

interface Props {
	name: string
	value: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register: any
	placeholder?: string
	error?: string
	reset?: boolean
}

const TextAreaCKE: React.FC<Props> = ({
	value,
	register,
	name,
	placeholder,
	error,
	reset,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (editor: ClassicEditor) => {
		register.onChange({
			target: {
				value: editor.getData(),
				name,
			},
			type: 'change',
		})
	}
	const editorRef = useRef<null | ClassicEditor>(null)

	return (
		<Container>
			<TextAreaContainer error={!!error} className='custom-editor'>
				{!reset && (
					<CKEditor
						onChange={(event, editor) => {
							handleChange(editor)
						}}
						id='editor'
						editor={ClassicEditor}
						data={value || ''}
						onReady={(editor) => {
							editorRef.current = editor
							const fontFamily = editor.commands.get('fontFamily')

							fontFamily?.execute({ value: 'Arial' })
						}}
						config={{
							placeholder,
							fontFamily: {
								options: ['Arial'],
								supportAllValues: true,
							},
							removePlugins: ['Styles'], // Remove plugins que podem causar conflitos
							plugins: [
								Alignment,
								Essentials,
								Bold,
								Italic,
								Strikethrough,
								Subscript,
								Superscript,
								Font,
								Heading,
								Underline,
								BlockQuote,
								List,
								Indent,
							],
							toolbar: {
								items: [
									'undo',
									'redo',
									'|',
									'heading',
									'|',
									'fontfamily',
									'fontsize',
									'fontColor',
									'fontBackgroundColor',
									'Alignment',
									'|',
									'bold',
									'italic',
									'strikethrough',
									'subscript',
									'underline',
									'superscript',
									'code',
									'|',
									'link',
									'blockQuote',
									'|',
									'bulletedList',
									'numberedList',
									'todoList',
									'outdent',
									'indent',
								],
							},

							fontColor: {
								colors: [
									{
										color: '#ff1300',
										label: 'red',
									},
									{
										color: '#009966',
										label: 'green',
									},
								],
							},

							htmlSupport: {
								allow: [
									{
										name: 'iframe',
										attributes: true,
										classes: true,
										styles: true,
									},
								],
							},
							mediaEmbed: {
								previewsInData: true,
							},
							fontBackgroundColor: {
								colors: [
									{
										color: '#ff1300',
										label: 'red',
									},
									{
										color: '#009966',
										label: 'green',
									},
								],
							},
						}}
					/>
				)}
			</TextAreaContainer>
			{error && <ErrorSpan>{error}</ErrorSpan>}
		</Container>
	)
}

export { TextAreaCKE }
