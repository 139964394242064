import React, { Dispatch, SetStateAction } from 'react'

import { Switch } from '@headlessui/react'

import { switchStyles } from './styles'

const { switchContainer, SwitchSpan, switchContainerSelected } = switchStyles

type SwitchProps = {
	enabled: boolean
	setEnabled: Dispatch<SetStateAction<boolean>>
}

export const SwitchBase = ({ enabled, setEnabled }: SwitchProps) => {
	return (
		<Switch
			checked={enabled}
			onChange={setEnabled}
			className={enabled ? switchContainerSelected : switchContainer()}
		>
			<SwitchSpan enabled={enabled} />
		</Switch>
	)
}
