import * as Checkbox from '@radix-ui/react-checkbox'
import { styled } from '@styles/theme'

export const checkboxStyles = {
	CheckboxRoot: styled(Checkbox.Root, {
		all: 'unset',
		backgroundColor: 'white',
		minWidth: '20px',
		minHeight: '20px',
		width: 20,
		height: 20,
		borderRadius: 3,
		display: 'flex',
		justifyContent: 'center',
		boxShadow: '0 2px 10px $neutral160',
		background: '$neutral10',
		border: '1px solid $neutral80',
	}),
	CheckboxIndicator: styled(Checkbox.Indicator, {
		color: '$primary',
		width: '20px',
		height: '20px',
		svg: { width: '20px', height: '20px' },
	}),
	Label: styled('label', {
		width: 'fit-content',
		fontSize: '100%',
		paddingLeft: '0px !important',
		marginLeft: '3px',
		lineHeight: '19.5px',
		letterSpacing: '0.7px',
	}),
	Flex: styled('div', {
		display: 'flex',
		gap: '8px',
		alignItems: 'flex-start !important',
	}),
}
