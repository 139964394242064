import { css } from '@styles/theme'

export const textAreaStyles = {
	TextAreaStyles: {
		base: css({
			display: 'flex',
			flex: 1,
			fleDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '14px 16px',

			overflow: 'auto',

			resize: 'none',

			width: '100%',
			height: '84px',

			background: '$neutral30',
			borderRadius: '12px',

			color: '$neutral80',

			fontFamily: 'Roboto',
			fontWeight: '400',
			fontSize: '$md',

			'&::placeholder': {},

			'&:focus': {
				outlineColor: '$blue20',
			},

			variants: {
				invalid: {
					true: {
						color: '$red',

						'&:focus': {
							outlineColor: '$red',
						},

						'&::placeholder': {
							color: '$red',
						},
					},
				},
			},
		}),
	},

	wrapper: {
		base: css({
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			gap: '$2',
		}),
	},

	errorStyles: {
		base: css({
			color: '$red',
			_font: 'normal',
			fontWeight: '400',
			fontSize: '$md',
			lineHeight: '21px',
			paddingLeft: '16px',
		}),
	},
}
