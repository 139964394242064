import React, { useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import { QuestionType } from '@models/questions.model'
import { questionsAPI } from '@services/QuestionsAPI'
import { useAppSelector } from '@store/hooks'

export type PrintProps = {
	componentRef: {
		current: HTMLDivElement | null
	}
	questions?: Array<QuestionType>
	setQuestions?: React.Dispatch<React.SetStateAction<QuestionType[]>>
}

export default function usePrintQuestion({
	componentRef,
	questions,
	setQuestions,
}: PrintProps) {
	const [loading, setLoading] = useState<boolean>(false)
	const { questionPrint } = useAppSelector((state) => state)

	const [getPrintQuesion, { isSuccess, data }] =
		questionsAPI.useGetPrintQuestionsMutation()

	const printFunc = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: `
		@page {
		  size: A4 portrait;
		  margin: 1cm;
		  /* Define o estilo do cabeçalho */
		  @top-center {
			content: element(header);
		  }
		  /* Define o estilo do rodapé */
		  @bottom-center {
			content: element(footer);
		  }
		}
		/* Define o estilo do cabeçalho e rodapé */
		#header, #footer {
		  position: running(${Math.random()});
		}
		/* Define o estilo do número da página */
		.page-number:before {
		  content: counter(page);
		}
	  `,
	})

	const handlePrint = async () => {
		if (questionPrint.printType === '3') {
			if (questions) {
				getPrintQuesion(questions.map((quest) => quest.id))
			}
		} else {
			printFunc()
		}
	}

	useEffect(() => {
		if (isSuccess && data) {
			if (setQuestions) {
				setQuestions((item) =>
					item.map((question) => {
						const dataItem = data.find(
							(findItem) => findItem.id === question.id
						)

						if (dataItem) {
							return { ...question, feedbackText: dataItem.explanation }
						}

						return question
					})
				)

				setLoading(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, data, setQuestions])

	useEffect(() => {
		if (loading) {
			printFunc()
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading])

	return {
		handlePrint,
	}
}
