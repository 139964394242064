import React, { forwardRef, Ref } from 'react'
import NumberFormat, {
	NumberFormatValues,
	SourceInfo,
} from 'react-number-format'

import { styled } from '@styles/theme'

import { inputStyles } from '../InputBase/styles'

const InputBase = styled('input', inputStyles.base)

const StitchesInput = styled(NumberFormat, InputBase)

/* const Error = styled('span', inputStyles.base) */

type StitchesProps = React.ComponentProps<typeof StitchesInput>
interface NumberInputProps extends StitchesProps {
	error?: string
	onValueChange: (values: NumberFormatValues, sourceInfo: SourceInfo) => void
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
	({ defaultValue, error, onValueChange, ...props }, ref) => {
		return (
			<div>
				<StitchesInput
					{...props}
					ref={ref as Ref<NumberFormat<unknown>> | undefined}
					onValueChange={onValueChange}
					defaultValue={defaultValue}
					invalid={!!error}
				/>
			</div>
		)
	}
)
