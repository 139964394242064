/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import { ChevronDown20Regular, ChevronUp20Regular } from '@fluentui/react-icons'
import { Listbox } from '@headlessui/react'
import { styled } from '@styles/theme'

import { selectStyles } from './styles'

const { Container, ListboxButton, ListboxOptions, listboxOption } = selectStyles

const Error = styled('span', selectStyles.error)

type StitchesProps = React.ComponentProps<typeof ListboxButton>
export interface Option {
	option: string
	value: string | number
	disabled?: boolean
	id?: string
}

interface SelectProps extends StitchesProps {
	error?: string
	options: Array<Option>
	disabled?: boolean
	placeholder?: string
	onChange: (option: Option) => void
	selectedValue?: any
}

export const Select = ({
	error,
	options,
	disabled,
	placeholder = 'Selecione',
	onChange,
	selectedValue,
	...props
}: SelectProps) => {
	const [selected, setSelected] = useState<Option>({
		value: '',
		option: placeholder,
	})

	const [useSelected, setUseSelected] = useState(true)

	useEffect(() => {
		if (typeof selectedValue !== undefined) {
			const value = options?.find((option) => option.value === selectedValue)

			if (value) {
				setSelected(value)
			}
			value && (value.value === '' || useSelected) && setSelected(value)
		}
	}, [selectedValue, options, useSelected])

	return (
		<Container {...props}>
			<Listbox
				value={selected}
				onChange={(value) => {
					onChange(value)

					setSelected(value)
					setUseSelected(false)
				}}
				disabled={disabled}
				{...props}
			>
				{({ open }) => (
					<>
						<ListboxButton>
							<Listbox.Label>{selected.option}</Listbox.Label>
							{open ? <ChevronUp20Regular /> : <ChevronDown20Regular />}
						</ListboxButton>
						<ListboxOptions>
							{options &&
								options.map((option, index) => (
									<Listbox.Option
										className={listboxOption()}
										value={option}
										disabled={option.disabled}
										key={index}
									>
										{option.option}
									</Listbox.Option>
								))}
						</ListboxOptions>
					</>
				)}
			</Listbox>

			<div>{error && <Error>{error}</Error>}</div>
		</Container>
	)
}
