import React from 'react'

import { styled } from '@styles/theme'

import { titleStyles } from './styles'

type VariantsMappingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface TitleProps {
	variant?: VariantsMappingType
	children: string
	color?: string
}

const Title = ({ children, variant = 'h2', color = '$text' }: TitleProps) => {
	const TitleStitchesComponent = styled(variant, titleStyles.base[variant])

	return (
		<TitleStitchesComponent css={{ color }}>{children}</TitleStitchesComponent>
	)
}

export { Title }
