import { styled } from '@styles/theme'

export const RatingStyle = {
	Rating: styled('div', {
		display: 'flex',
		'& span': {
			svg: {
				width: '28px',
				height: '28px',
			},
			color: '$neutral90',
			cursor: 'pointer',
			'&:hover': {
				color: 'gray',
				'& ~ span': {
					color: 'gray',
				},
				'&:first-child': {
					color: '$gold',
				},
				'&:nth-child(2)': {
					color: '$gold',
				},
				'&:nth-child(3)': {
					color: '$gold',
				},
				'&:nth-child(4)': {
					color: '$gold',
				},
				'&:last-child': {
					color: '$gold',
				},
			},
		},
		'& span.active, & span.hover': {
			color: '$gold',
		},
	}),
}
