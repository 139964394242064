import { BASE_URL } from '@helpers/constants'
import { getBrowserName, getBrowserVersion } from '@helpers/osInfo'
import setAuthorization from '@helpers/setAuthorization'
import {
	LoginRequest,
	LoginResponse,
	signUpRequest,
	signUpResponse,
	recoveryResponse,
	recoveryRequest,
	changePasswordResponse,
	changePasswordRequest,
	migrationVideoRequest,
} from '@models/auth.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) => {
			if (endpoint.includes('/login')) {
				headers.delete('Authorization')

				return headers
			}

			const newHeaders = setAuthorization(headers, { getState, endpoint })

			return newHeaders
		},
	}),
	endpoints: (builder) => ({
		authenticate: builder.mutation<LoginResponse, LoginRequest>({
			query: (userCredentials) => ({
				url: '/login',
				method: 'POST',
				body: {
					...userCredentials,
					platform: getBrowserName(navigator.userAgent),
					version: getBrowserVersion(navigator.userAgent),
				},
				headers: {
					platform: 'web',
				},
			}),
		}),
		signUp: builder.mutation<signUpResponse, signUpRequest>({
			query: (userCredentials) => ({
				url: '/register',
				method: 'POST',
				body: userCredentials,
			}),
		}),
		recovery: builder.mutation<recoveryResponse, recoveryRequest>({
			query: (userCredentials) => ({
				url: '/recover-password?sendLink=1',
				method: 'POST',
				body: userCredentials,
			}),
		}),
		changePassword: builder.mutation<
			changePasswordResponse,
			changePasswordRequest
		>({
			query: (userCredentials) => ({
				url: '/change-password',
				method: 'PUT',
				body: userCredentials,
			}),
		}),
		migrationLink: builder.mutation<void, migrationVideoRequest>({
			query: (token) => ({
				url: '/email-migration',
				method: 'POST',
				body: token,
			}),
		}),
		// profile: builder.mutation<ProfileResponse, void>({
		// 	query: () => ({
		// 		url: `/profile`,
		// 		method: 'GET',
		// 	}),
		// }),
	}),
})
