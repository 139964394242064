import React from 'react'
import { Bar } from 'react-chartjs-2'

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'

import { Title as TitleTypo } from '../Typography'
import { chartsHistoricStyles } from './style'

const { Container } = chartsHistoricStyles

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	interaction: {
		mode: 'index' as const,
		intersect: false,
	},
	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: false,
		},
	},
}

type StitchesProps = React.ComponentProps<typeof Container>

type PropsChartsHistoricObject = {
	correct: number
	date: string
	total: number
}

interface PropsChartsHistoric extends StitchesProps {
	data: Array<PropsChartsHistoricObject>
	showTitle?: boolean
}

export const ChartsHistoric = ({
	data,
	showTitle = true,
	...props
}: PropsChartsHistoric) => {
	const labels = data.map((item) => item.date)

	const refinedDate = {
		labels,
		datasets: [
			{
				label: 'Total de acertos',
				data: data.map((item) => item.correct),
				backgroundColor: 'rgba(41, 160, 210, 0.5)',
				stack: 'Stack 0',
				borderRadius: 100,
			},
			{
				label: 'Total respondido',
				data: data.map((item) => item.total),
				backgroundColor: '#EDEEF3',
				stack: 'Stack 0',
				borderRadius: 100,
			},
		],
	}

	return (
		<Container {...props}>
			{showTitle && (
				<TitleTypo variant='h5' color='$neutral160'>
					Histórico geral de resoluções
				</TitleTypo>
			)}

			<Bar options={options} data={refinedDate} />
		</Container>
	)
}
