import React from 'react'

import { Title } from '@components/atoms'
import { IosArrowRtl24Regular } from '@fluentui/react-icons'

import { cardListStyle } from './style'

const { Container } = cardListStyle

type StitchesProps = React.ComponentProps<typeof Container>

interface CardListProps extends StitchesProps {
	name: string
}

export const CardList = ({ name, ...props }: CardListProps) => {
	return (
		<Container {...props}>
			<Title variant='h6' color='$neutral100'>
				{name}
			</Title>
			<IosArrowRtl24Regular />
		</Container>
	)
}
