import { css, styled } from '@styles/theme'

export const selectMoveStyles = {
	container: css({
		height: '284px',
		overflowY: 'scroll',
		borderBottom: '1px solid $neutral60',
		borderTop: '1px solid $neutral60',

		'&::-webkit-scrollbar': {
			width: '12px',
			background: '$neutral30',
		},
		'&::-webkit-scrollbar-track': {
			width: '12px',
		},
		'&::-webkit-scrollbar-thumb': {
			width: '6px',
			height: '37px',
			border: '3px solid $neutral70',
			background: '$neutral70',
			borderRadius: '6px',
		},
	}),

	ItemSelect: styled('button', {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '12px 16px',
		gap: '16px',
		width: '100%',
		borderRadius: '4px',

		color: '$neutral100',

		p: {
			_font: 'normal',
			fontWeight: '400',
			fontSize: '$lg',
			lineHeight: '19px',
		},

		'&:hover': {
			background: '$neutral40',
		},
		'&:focus': {
			background: 'rgba(107, 187, 220, 0.15)',
			color: '$blue20',
		},

		variants: {
			selected: {
				true: {
					background: 'rgba(107, 187, 220, 0.15)',
					color: '$blue20',
				},
			},
		},
	}),

	Ellipse: styled('div', {
		width: '14px',
		height: '14px',
		borderRadius: '50%',
		backgroundColor: '$neutral30',
		border: '1px solid $neutral50',

		variants: {
			active: {
				true: {
					backgroundColor: '$primary',
					border: '1px solid $neutral30',
				},
			},
		},
	}),
}
