import { stitchesConfig } from './stitches.config'

export const dark = stitchesConfig.createTheme({
	colors: {
		primary: '#6BBBDC',
		blue20: '#29A0D2',
		blue30: '#24799D',
		blue40: '#2F80ED',
		orange10: '#FF9862',
		orange20: '#FC6719',
		orange30: '#CE500F',
		orange40: '#B03D01',
		neutral10: '#252525',
		neutral20: '#252525',
		neutral30: '#0A0A0A',
		neutral40: '#141414',
		neutral50: '#3F454B',
		neutral60: '#252525',
		neutral70: '#2E3135',
		neutral80: '#3F454B',
		neutral90: '#C4C4C4',
		neutral100: '#FFFFFF',
		neutral110: '#A4A7AB',
		neutral120: '#DFDEDE',
		neutral130: '#EDEEF3',
		neutral140: '#F6F6F6',
		neutral150: '#FBFBFB',
		neutral160: '#FDFDFD',
		courseCardBackground: '#4E565B',
		gold: '#F29D1D',
		siler: '#A7A4A0',
		bronze: '#9D6C3D',
		green: '#219653',
		yellow: '#C79B16',
		red: '#C74242',
		disabled: '#3F454B',
		text: '#FDFDFD',
		modalOverlay: 'rgba(37, 37, 37, 0.75)',
	},
})
