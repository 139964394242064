import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { successMessages } from '@/constants/feedbackMessages'
import { Add12Regular } from '@fluentui/react-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import {
	newNotebookSchema,
	notebookSchema,
} from '@schemas/questions/questions.schema'
import { notebookAPI } from '@services/NotebookAPI'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'

import { Button } from '../Button'
import { Input } from '../InputBase'
import { OptionsProps, SelectBase } from '../SelectBase'
import { Paragraph } from '../Typography'
import { questionBookStyles } from './styles'

const {
	containerBook,
	containerDiv,
	containerDivBook,
	containerInput,
	containerNotebook,
	buttonNew,
} = questionBookStyles

type FormNotebookValue = {
	notebook: number
}

export type FormNewNotebookValue = {
	name: string
}

type QuestionInfo = {
	id: number
}

export type NotebookSelectType = {
	selectNotebook: { value: number }
}

export function QuestionNotebook({ id }: QuestionInfo) {
	const [values, setValues] = useState<NotebookSelectType>()
	const [notebooks, setNotebooks] = useState<Array<OptionsProps>>()
	const [newNotebook, setNewNotebook] = useState(false)

	const dispatch = useAppDispatch()

	const {
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<FormNotebookValue>({
		resolver: yupResolver(notebookSchema),
	})

	const {
		register: registerNewNotebook,
		reset: resetNewNotebook,
		handleSubmit: handleSubmitNewNotebook,
		formState: { errors: errorsNewNotebook },
	} = useForm<FormNewNotebookValue>({
		resolver: yupResolver(newNotebookSchema),
	})

	const [getAllNotebooks, { data, isSuccess }] =
		notebookAPI.useGetAllNotebooksMutation()

	const [
		createNotebook,
		{ isSuccess: isSuccessCreateNotebook, isLoading: isLoadingCreateNotebook },
	] = notebookAPI.usePostCreateNotebookMutation()
	const [
		QuestionToNotebook,
		{
			isSuccess: isSuccessQuestionToNotebook,
			isLoading: isLoadingQuestionToNotebook,
		},
	] = notebookAPI.usePostQuestionToNotebookMutation()

	const onSubmitSaveQuestion = handleSubmit((payload) => {
		QuestionToNotebook({
			questionId: id,
			notebookId: payload.notebook,
		})
	})

	const onSubmitNewBook = handleSubmitNewNotebook((payload) => {
		if (payload.name === '') {
			onSubmitSaveQuestion()
		} else {
			createNotebook(payload).then(() => {
				getAllNotebooks()
				resetNewNotebook()
				setNewNotebook(!newNotebook)
			})
		}
	})

	useEffect(() => {
		if (values?.selectNotebook?.value) {
			setValue('notebook', values?.selectNotebook?.value)
		}
	}, [setValue, values])

	useEffect(() => {
		getAllNotebooks()
	}, [getAllNotebooks])

	useEffect(() => {
		if (isSuccess && data) {
			setNotebooks(
				data.notebooks.map((item) => {
					return { label: item.name, value: item.id }
				})
			)
		}
	}, [isSuccess, data])

	useEffect(() => {
		if (isSuccessCreateNotebook) {
			dispatch(notificationCreators.show(successMessages.addNotebook))
		}
	}, [dispatch, isSuccessCreateNotebook])

	useEffect(() => {
		if (isSuccessQuestionToNotebook) {
			dispatch(notificationCreators.show(successMessages.questionToNotebook))
		}
	}, [dispatch, isSuccessQuestionToNotebook])

	return (
		<>
			{newNotebook ? (
				<div className={containerBook()}>
					<form className={containerDiv()} onSubmit={onSubmitNewBook}>
						<Paragraph paragraphType='p2' css={{ color: '$neutral130' }}>
							Criar caderno
						</Paragraph>
						<Input
							placeholder='Escreva o nome do caderno...'
							error={errorsNewNotebook.name?.message}
							{...registerNewNotebook('name')}
							css={{
								'@md': {
									minWidth: '328px',
								},
							}}
						/>
						<Button
							css={{
								'@md': {
									maxWidth: '149px',
								},
							}}
							loading={isLoadingQuestionToNotebook || isLoadingCreateNotebook}
						>
							Salvar
						</Button>
					</form>
					<div className={containerNotebook()}>
						<Paragraph paragraphType='p1' css={{ color: '$neutral130' }}>
							Escolha um caderno para adicionar a questão
						</Paragraph>
						<div className={containerInput()}>
							<SelectBase
								placeholder='Selecione o caderno'
								options={notebooks}
								name='selectNotebook'
								onChange={setValues}
								error={errors?.notebook?.message}
								css={{
									'@md': {
										maxWidth: '322px',
									},
								}}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className={containerBook()}>
					<div className={containerDivBook()}>
						<button
							type='button'
							onClick={() => {
								setNewNotebook(!newNotebook)
							}}
							className={buttonNew()}
						>
							Adicionar caderno <Add12Regular />
						</button>
					</div>
					<div className={containerNotebook()}>
						<Paragraph paragraphType='p1' css={{ color: '$neutral130' }}>
							Escolha um caderno para adicionar a questão
						</Paragraph>
						<form className={containerInput()} onSubmit={onSubmitSaveQuestion}>
							<SelectBase
								placeholder='Selecione o caderno'
								options={notebooks}
								name='selectNotebook'
								onChange={setValues}
								error={errors?.notebook?.message}
								css={{
									'@md': {
										maxWidth: '322px',
									},
								}}
							/>
							<Button
								css={{
									'@md': {
										maxWidth: '149px',
									},
								}}
								loading={isLoadingQuestionToNotebook}
							>
								Salvar
							</Button>
						</form>
					</div>
				</div>
			)}
		</>
	)
}
