import { css } from '@styles/theme'

export const buttonStyles = {
	base: css({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '14px 16px',
		gap: '16px',
		width: '100%',
		height: '48px',
		borderRadius: '14px',

		_font: 'normal',
		fontSize: '$xl',
		color: '$text',

		transition: 'all 0.3s',
		cursor: 'pointer',

		'span.icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '1px',
		},

		variants: {
			color: {
				primary: {
					backgroundColor: '$blue20',
					'&:hover:enabled, &:focus:enabled': {
						backgroundColor: '$blue30',
					},
					'&:disabled': {
						backgroundColor: '$disabled',
						cursor: 'not-allowed',
					},
				},
				secondary: {
					backgroundColor: '$neutral80',
					'&:hover, &:focus:enabled': {
						backgroundColor: '$blue30',
					},
				},
				danger: {
					backgroundColor: '$red',
					'&:hover, &:focus:enabled': {
						backgroundColor: '$red',
					},
				},
				orange: {
					backgroundColor: '$orange20',
					'&:hover, &:focus:enabled': {
						backgroundColor: '$orange30',
					},
				},
			},
			outlined: {
				true: {
					backgroundColor: 'transparent',
					'&:hover, &:focus:enabled': {
						backgroundColor: 'transparent',
					},
					'&:disabled': {
						color: '$disabled',
						backgroundColor: 'transparent',
					},
				},
			},
			loading: {
				true: {},
			},
		},

		compoundVariants: [
			{
				color: 'primary',
				outlined: true,
				css: {
					border: '1px solid $blue20',
					color: '$blue20',

					'&:hover:enabled, &:focus:enabled': {
						backgroundColor: 'transparent',
						borderColor: '$blue30',
						color: '$blue30',
					},

					'&:disabled': {
						backgroundColor: 'transparent',
						borderColor: '$disabled',
						color: '$disabled',
					},
				},
			},
			{
				color: 'secondary',
				outlined: true,
				css: {
					backgroundColor: 'transparent',
					border: '1px solid $neutral80',
					color: '$neutral80',

					'&:hover:enabled, &:focus:enabled': {
						backgroundColor: 'transparent',
						borderColor: '$blue30',
						color: '$blue30',
					},

					'&:disabled': {
						backgroundColor: 'transparent',
						borderColor: '$disabled',
						color: '$disabled',
					},
				},
			},
			{
				color: 'danger',
				outlined: true,
				css: {
					backgroundColor: 'transparent',
					border: '1px solid $red',
					color: '$red',

					'&:hover:enabled, &:focus:enabled': {
						backgroundColor: 'transparent',
						borderColor: '$red',
						color: '$red',
					},

					'&:disabled': {
						backgroundColor: 'transparent',
						borderColor: '$disabled',
						color: '$disabled',
					},
				},
			},
		],

		defaultVariants: {
			color: 'primary',
		},
	}),
}
