import React from 'react'

import { Paragraph, Title } from '@components/atoms'
import { CalendarLtr20Regular } from '@fluentui/react-icons'
import moment from 'moment'

import { cardSimulateStyles } from './style'

const {
	Container,
	content1,
	content2,
	/* containerRating,
	StarFilled,
	StarEmpty, */
} = cardSimulateStyles

type StitchesProps = React.ComponentProps<typeof Container>

interface CardListProps extends StitchesProps {
	name: string
	questionNumber: number
	rating: number
	date: string
}

export const CardSimulate = ({
	name,
	questionNumber,
	date,
	...props
}: CardListProps) => {
	return (
		<Container {...props}>
			<Title variant='h6' color='$neutral100'>
				{name}
			</Title>
			<div style={{ width: '100%' }}>
				<div className={content1()}>
					<Paragraph paragraphType='p2' css={{ color: '$neutral90' }}>
						{questionNumber} questões
					</Paragraph>
				</div>
				<div className={content2()}>
					<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
						<CalendarLtr20Regular />
						<Paragraph paragraphType='p2' css={{ color: '$neutral90' }}>
							{moment(date).format('DD/MM/YYYY')}
						</Paragraph>
					</div>
				</div>
			</div>
		</Container>
	)
}
