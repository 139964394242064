import { styled, css } from '@styles/theme'

export const sideModalStyles = {
	OverlaySideMenu: styled('div', {
		position: 'fixed',
		right: '0px',
		top: '0px',
		minHeight: '100%',
		width: '100%',
		background: '$modalOverlay',
		zIndex: '10000',

		variants: {
			open: {
				false: {
					display: 'none',
				},
			},
		},
	}),

	SideMenuFilter: styled('div', {
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		right: '0px',
		top: '0px',
		paddingBottom: '24px',

		width: '100%',
		maxWidth: '438px',
		height: '100%',
		background: '$neutral10',
		overflowY: 'auto',
	}),

	headerSideMenu: css({
		display: 'flex',
		justifyContent: 'space-between',
		minHeigth: ' 94px',
		borderBottom: '1px solid $neutral50',
		padding: '32px',

		h5: {
			_font: 'normal',
			fontWeight: '500',
			fontSize: '$xl',
			lineHeight: '19px',
			color: '$blue20',
		},
	}),
}
