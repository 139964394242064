import React, { forwardRef } from 'react'

import { styled } from '@styles/theme'

import { inputStyles } from './styles'

const InputBase = styled('input', inputStyles.base)

const Error = styled('span', inputStyles.error)

type StitchesProps = React.ComponentProps<typeof InputBase>
interface InputProps extends StitchesProps {
	error?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
	({ defaultValue, error, ...props }, ref) => {
		return (
			<div>
				<InputBase
					{...props}
					ref={ref}
					defaultValue={defaultValue}
					invalid={!!error}
				/>
				{error && <Error>{error}</Error>}
			</div>
		)
	}
)
