import { lazy } from 'react'

import { Routes } from '@models/route/route.model'
import PromotionalLP from '@pages/PromotionalLP'
import { Logout } from '@routes/Logout'

const Login = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.Login }))
)

const RecoveryEmail = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.RecoveryEmail }))
)

const ResetPassword = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.ResetPassword }))
)

const SignUp = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.SignUp }))
)

const MigrationVideo = lazy(() =>
	import('@pages').then((comp) => ({ default: comp.PageMigrationVideo }))
)

export const publicRoutes: Routes = [
	{ path: '/logout', element: Logout, isPrivate: false, exact: true },
	{
		path: '/login',
		element: Login,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/recuperar-senha',
		element: RecoveryEmail,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/reset-senha',
		element: ResetPassword,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/cadastre-se',
		element: SignUp,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/nova-plataforma',
		element: MigrationVideo,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/promo',
		element: PromotionalLP,
		isPrivate: false,
		exact: true,
	},
]
