import { BASE_URL } from '@helpers/constants'
import setAuthorization from '@helpers/setAuthorization'
import {
	AllFolderNotebooksResponse,
	AllNotebooksResponse,
	CreateNotebookInFolderRequest,
	CreateNotebookRequest,
	CreateNotebookResponse,
	DeleteNotebookQuestionRequest,
	EditNotebookRequest,
	EditNotebookResponse,
	FolderNotebooksResponse,
	NotebookQuestionHistoricRequest,
	NotebookQuestionHistoricResponse,
	NotebookToFolderRequest,
	QuestionToNotebookRequest,
} from '@models/notebook.model'
import {
	AnswerQuestionResponse,
	ShowNotebookQuestionResponse,
} from '@models/questions.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const notebookAPI = createApi({
	reducerPath: 'notebookAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) =>
			setAuthorization(headers, { getState, endpoint }),
	}),
	endpoints: (builder) => ({
		getAllNotebooks: builder.mutation<AllNotebooksResponse, void>({
			query: () => ({
				url: 'notebooks',
				method: 'GET',
			}),
		}),

		getAllNotebooksPage: builder.mutation<AllNotebooksResponse, void>({
			query: () => ({
				url: 'notebooks?excludeArchived=1',
				method: 'GET',
			}),
		}),

		getAllFolderNotebooks: builder.mutation<
			Array<AllFolderNotebooksResponse>,
			void
		>({
			query: () => ({
				url: 'notebooksFolders',
				method: 'GET',
			}),
		}),

		getFolderShowNotebooks: builder.mutation<FolderNotebooksResponse, string>({
			query: (folderId) => ({
				url: `notebooksFolders/${folderId}`,
				method: 'GET',
			}),
		}),

		getOneNotebook: builder.mutation<ShowNotebookQuestionResponse, string>({
			query: (id) => ({
				url: `notebooks/${id}`,
				method: 'GET',
			}),
		}),

		getOneNotebookError: builder.mutation<ShowNotebookQuestionResponse, string>(
			{
				query: (id) => ({
					url: `notebooks/${id}/errors`,
					method: 'GET',
				}),
			}
		),

		getNotebookQuestionHistoric: builder.mutation<
			NotebookQuestionHistoricResponse,
			NotebookQuestionHistoricRequest
		>({
			query: ({ notebookId = 0, questionId }) => ({
				url: `notebooks/${notebookId}/questions/${questionId}/historic`,
				method: 'GET',
			}),
		}),

		postCreateNotebook: builder.mutation<
			CreateNotebookResponse,
			CreateNotebookRequest
		>({
			query: (notebook: CreateNotebookRequest) => ({
				url: 'notebooks',
				method: 'POST',
				body: notebook,
			}),
		}),

		postCreateNotebookInFolder: builder.mutation<
			CreateNotebookResponse,
			CreateNotebookInFolderRequest
		>({
			query: ({ name, idFolder }: CreateNotebookInFolderRequest) => ({
				url: `notebooksFolders/${idFolder}/notebooks`,
				method: 'POST',
				body: { notebookName: name },
			}),
		}),

		postCreateNotebookFolder: builder.mutation<
			CreateNotebookResponse,
			CreateNotebookRequest
		>({
			query: (notebook: CreateNotebookRequest) => ({
				url: 'notebooksFolders',
				method: 'POST',
				body: notebook,
			}),
		}),

		postQuestionToNotebook: builder.mutation<
			AnswerQuestionResponse,
			QuestionToNotebookRequest
		>({
			query: (notebook: QuestionToNotebookRequest) => ({
				url: 'notebooks/questions',
				method: 'POST',
				body: notebook,
			}),
		}),

		postNotebookInFolder: builder.mutation<
			AnswerQuestionResponse,
			NotebookToFolderRequest
		>({
			query: (notebook: NotebookToFolderRequest) => ({
				url: 'notebooksFolders/notebooks',
				method: 'POST',
				body: notebook,
			}),
		}),

		putEditNotebook: builder.mutation<
			EditNotebookResponse,
			EditNotebookRequest
		>({
			query: (notebook: EditNotebookRequest) => ({
				url: `notebooks/${notebook.id}`,
				method: 'PUT',
				body: { name: notebook.name },
			}),
		}),

		putEditFolderNotebook: builder.mutation<
			EditNotebookResponse,
			EditNotebookRequest
		>({
			query: (notebook: EditNotebookRequest) => ({
				url: `/notebooksFolders/${notebook.id}`,
				method: 'PUT',
				body: { name: notebook.name },
			}),
		}),

		deleteNotebook: builder.mutation<void, string>({
			query: (id) => ({
				url: `notebooks/${id}`,
				method: 'DELETE',
			}),
		}),

		deleteFolderNotebook: builder.mutation<void, string>({
			query: (folderId) => ({
				url: `notebooksFolders/${folderId}`,
				method: 'DELETE',
			}),
		}),

		deleteNotebookQuestion: builder.mutation<
			void,
			DeleteNotebookQuestionRequest
		>({
			query: (value) => ({
				url: `notebooks/questions/${value.questionId}?notebookId=${value.notebookId}`,
				method: 'DELETE',
			}),
		}),
	}),
})
