import { keyframes } from './theme'

export const fadeIn = keyframes({
	from: { opacity: 0 },
	to: { opacity: 1 },
})

export const fadeOut = keyframes({
	from: { opacity: 1 },
	to: { opacity: 0 },
})

export const loadingSpinner = keyframes({
	from: {
		transform: 'rotate(0deg)',
	},

	to: {
		transform: 'rotate(359deg)',
	},
})

export const shake = keyframes({
	'0%': {
		transform: 'rotate(0deg)',
	},
	'10%': {
		transform: 'rotate(14deg)',
	},
	'20%': {
		transform: 'rotate(-8deg)',
	},
	'30%': {
		transform: 'rotate(14deg)',
	},
	'40%': {
		transform: 'rotate(-4deg)',
	},
	'50%': {
		transform: 'rotate(10deg)',
	},
	'60%': {
		transform: 'rotate(0deg)',
	},
	'100%': {
		transform: 'rotate(0deg)',
	},
})

export const slideDown = keyframes({
	'0%': {
		'-webkit-transform': 'translateY(-20px)',
		transform: 'translateY(-20px)',
		visibility: 'visible',
		opacity: 0,
	},

	'50%': {
		opacity: 0.4,
	},

	'100%': {
		'-webkit-transform': 'translateY(0)',
		transform: 'translateY(0)',
		opacity: 1,
	},
})

export const slideUp = keyframes({
	'0%': {
		'-webkit-transform': 'translateY(px)',
		transform: 'translateY(px)',
		visibility: 'visible',
		opacity: 1,
	},

	'50%': {
		opacity: 0.4,
	},

	'100%': {
		'-webkit-transform': 'translateY(-20px)',
		transform: 'translateY(-20px)',
		opacity: 0,
	},
})

export const scaleUp = keyframes({
	from: { width: '72px' },
	to: { width: '277px' },
})
