import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Icon } from '@components/atoms'
import rehypeRaw from 'rehype-raw'

import { sliderStyles } from './styles'

const { containerSlider, text } = sliderStyles

type SliderPromo = {
	promotionalText: string
	promotionalImage: string
}
export default function SliderPromo({
	promotionalText,
	promotionalImage,
}: SliderPromo) {
	return (
		<div className={containerSlider()}>
			<div>
				<Icon
					className='image-top'
					title='back-top'
					name='back-top'
					width={114}
					height={120}
					fill='none'
				/>
				<img width={500} src={promotionalImage} alt='perguntas' />
				<Icon
					className='image-down'
					title='back-down'
					name='back-down'
					width={114}
					height={120}
					fill='none'
				/>
			</div>

			<div className={text()}>
				<ReactMarkdown rehypePlugins={[rehypeRaw]}>
					{promotionalText || ''}
				</ReactMarkdown>
			</div>
		</div>
	)
}
