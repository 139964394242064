import { css } from '@styles/theme'

export const SmallStyles = {
	base: css({
		color: '$text',

		variants: {
			smallType: {
				small1: {
					fontSize: '$p',
					lineHeight: '19px',
					fontWeight: 400,

					media: {
						md: {
							fontSize: '$sm',
							fontWeight: 400,
						},
					},
				},
				small2: {
					fontSize: '$sm2',
					fontWeight: 400,
					lineHeight: '18px',
				},
			},
		},
	}),
}
