import { useLocation, useResolvedPath } from 'react-router-dom'

export default function useIsCurrentLink(pathname: string) {
	const resolved = useResolvedPath(pathname)

	const { pathname: currentPathname } = useLocation()
	const parts = currentPathname.split('/').filter(Boolean)
	const currentPage = parts[0]

	const isCurrent =
		currentPage === undefined && resolved.pathname === '/'
			? true
			: `/${currentPage}` === resolved.pathname

	return {
		isCurrent,
	}
}
