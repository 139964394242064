import React from 'react'

import { ArrowClockwise24Regular } from '@fluentui/react-icons'

import { RefreshButtonStyles } from './styles'

const { RefreshButtonStitcherComponent } = RefreshButtonStyles

type StitchesProps = React.ComponentProps<typeof RefreshButtonStitcherComponent>
interface RefreshButtonProps extends StitchesProps {
	children: string
}

const RefreshButton = ({
	children = 'Atualizar',
	...props
}: RefreshButtonProps) => {
	return (
		<RefreshButtonStitcherComponent {...props}>
			<p>{children}</p>
			<ArrowClockwise24Regular />
		</RefreshButtonStitcherComponent>
	)
}

export { RefreshButton }
