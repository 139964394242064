/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalState {
	modalName?: string
	modalInfo: any
	type: 'alert' | 'error' | 'success' | undefined
	options: {
		title: string | undefined
		description: string | undefined
		buttonLabel: string
	}
}

const initialState: ModalState = {
	modalName: '',
	modalInfo: undefined,
	type: undefined,
	options: {
		title: undefined,
		description: undefined,
		buttonLabel: 'Fechar',
	},
}

export const modalSlice = createSlice({
	name: 'example',
	initialState,
	reducers: {
		openModal: (
			state,
			{
				payload: { name, modalInfo },
			}: PayloadAction<{ name: string; modalInfo?: any }>
		) => {
			document.getElementsByTagName('body')[0].style.overflowY = 'hidden'

			state.modalName = name
			state.modalInfo = modalInfo
		},
		closeModal: (state) => {
			if (state.modalName) {
				state.modalName = ''
			}

			if (state.type) {
				state.type = undefined
			}

			document.getElementsByTagName('body')[0].style.overflowY = 'auto'
		},
		openAlert: (
			state,
			{
				payload: { type, buttonLabel, description, title },
			}: PayloadAction<{
				type: ModalState['type']
				title: string
				description: string
				buttonLabel: string
			}>
		) => {
			const newOptions = { ...initialState.options }

			newOptions.title = title
			newOptions.description =
				typeof description === 'string'
					? description
					: 'Ocorreu um erro desconhecido'
			newOptions.buttonLabel = buttonLabel
			state.type = type
			state.options = newOptions
		},

		close: (state) => {
			state.type = undefined
		},
	},
})

export const modalCreators = modalSlice.actions

export const modalReducer = modalSlice.reducer
