import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { IosArrowLtr24Regular } from '@fluentui/react-icons'

import { breadCrumbStyles } from './style'

const { breadCrumbContainer, Step, breadcrumbSeparator, buttonReturnStyle } =
	breadCrumbStyles

interface BreadCrumbProps {
	locations: Array<{ link: string; name: string }>
	buttonReturn?: boolean
}

export function BreadCrumb({ locations, buttonReturn }: BreadCrumbProps) {
	const navigate = useNavigate()

	return (
		<>
			<nav aria-label='breadcrumb'>
				<ul className={breadCrumbContainer()}>
					{buttonReturn && (
						<button
							className={buttonReturnStyle()}
							type='button'
							onClick={() => navigate(-1)}
						>
							<IosArrowLtr24Regular />
							<p>Voltar</p>
						</button>
					)}

					{locations.map((item, index) => (
						<React.Fragment key={index}>
							<Step
								active={index === locations.length - 1}
								aria-current={
									index === locations.length - 1 ? 'page' : undefined
								}
							>
								{index === locations.length - 1 ? (
									item.name
								) : (
									<Link to={item.link}>{item.name}</Link>
								)}
							</Step>
							{index < locations.length - 1 && (
								<span className={breadcrumbSeparator()}>/</span>
							)}
						</React.Fragment>
					))}
				</ul>
			</nav>
		</>
	)
}
