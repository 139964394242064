import React, { useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { Navigate, Link } from 'react-router-dom'

import {
	Button,
	Input,
	PageTransition,
	Icon,
	NumberInput,
} from '@components/atoms'
import ToastError from '@components/atoms/Error'
import SliderPromo from '@components/molecules/SliderPromo'
import { yupResolver } from '@hookform/resolvers/yup'
import { promotionalSchema } from '@schemas/promotional/promotionalLP.schema'
import { promotionalLPApi } from '@services/PromotionLPApi'
import { authCreators } from '@store/ducks/auth'
import { useAppSelector, useAppDispatch } from '@store/hooks'

import { promotionalLPStyles } from './styles'

const { background, container } = promotionalLPStyles

type FormValues = {
	name: string
	email: string
	phone: string
}

export default function PromotionalLP() {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({ resolver: yupResolver(promotionalSchema) })

	const {
		auth: { signed },
		/* 		error: { message, show }, */
	} = useAppSelector((state) => state)

	const dispatch = useAppDispatch()
	const { executeRecaptcha } = useGoogleReCaptcha()

	const { data } = promotionalLPApi.useGetPromotionalQuery()

	const [authenticate, { isSuccess, data: login }] =
		promotionalLPApi.useAuthenticateMutation()

	const onSubmit = handleSubmit(async (payload) => {
		const recaptcha = executeRecaptcha && (await executeRecaptcha('leads'))

		window.dataLayer.push({
			event: 'form_submit',
			eventModel: {
				name: payload.name,
				email: payload.email,
				phone: payload.phone,
			},
		})

		authenticate({ ...payload, token: recaptcha || '' })
		dispatch(authCreators.setNewSession())
	})

	useEffect(() => {
		if (isSuccess && login) {
			dispatch(authCreators.authenticate({ token: login.token }))
		}
	}, [dispatch, isSuccess, login])

	return (
		<PageTransition>
			{signed && login && (
				<Navigate to={login ? login?.courseUrl : '/cursos'} />
			)}
			<main className={background()}>
				<div className={container()}>
					<Link to='/'>
						<Icon title='logo DLS' name='logo' width={116} height={50} />
					</Link>

					<div className='titles'>
						<h4>{data?.promotionalTitle}</h4>
						<p>{data?.promotionalSubtitle}</p>
					</div>

					<form onSubmit={onSubmit}>
						<Input
							placeholder='Nome completo'
							error={errors.name?.message}
							{...register('name')}
						/>
						<Input
							placeholder='Email'
							error={errors.email?.message}
							{...register('email')}
						/>
						<NumberInput
							placeholder='Whatsapp'
							error={errors.phone?.message}
							{...register('phone')}
							css={{ marginBottom: 0, color: '$neutral120' }}
							onValueChange={(v) => setValue('phone', v.value)}
							defaultValue=''
							format='(##) #####-####'
						/>
						<Button onClick={onSubmit}>{data?.promotionalButtonText}</Button>
					</form>
				</div>

				<SliderPromo
					promotionalText={data?.promotionalText || ''}
					promotionalImage={data?.promotionalImage || ''}
				/>
			</main>
			<ToastError />
		</PageTransition>
	)
}
