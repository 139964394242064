import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { LocalStorage } from '@helpers'
import { useAppSelector } from '@store/hooks'

type PrivateRouteProps = {
	children: React.ReactElement | null
}

export function PrivateRoute({ children }: PrivateRouteProps) {
	const location = useLocation()

	const storage = new LocalStorage()

	const {
		auth: { signed },
	} = useAppSelector((state) => state)

	if (storage.get('token') && !signed) {
		return <></>
	}

	return signed ? (
		children
	) : (
		<Navigate to='/login' state={{ redirect: location.pathname }} />
	)
}
