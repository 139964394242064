import { Checkmark12Filled, DocumentPdf20Regular } from '@fluentui/react-icons'
import { styled } from '@styles/theme'

export const fileStyle = {
	ContainerButton: styled('button', {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '8px',
		cursor: 'pointer',

		p: {
			_font: 'normal',
			fontSize: '$p',
			fontWeight: 400,
			lineHeight: '19px',
			color: '$neutral120',
		},

		'&:hover': {
			color: '$blue20',

			p: {
				color: '$blue20',
			},

			svg: {
				color: '$blue20',
				borderColor: '$blue20',
			},
		},

		variants: {
			isSuccess: {
				true: {
					p: {
						color: '$green',
					},
				},
			},
		},
	}),

	IconContainer: styled('div', {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '40px',
		height: '40px',
		color: '$neutral80',
		position: 'relative',

		border: '1px solid $neutral50',
		borderRadius: '26px',

		variants: {
			isSuccess: {
				true: {
					borderColor: '$green',
				},
			},
		},
	}),

	IconCheckmark12Filled: styled(Checkmark12Filled, {
		width: '16px',
		height: '16px',
		background: ' $green',
		color: '$neutral10',
		borderRadius: '100%',

		position: 'absolute',
		top: '-4px',
		left: '23px',
	}),

	DocumentPdf20RegularStyled: styled(DocumentPdf20Regular, {
		width: '30px',
		height: '30px',
	}),
}
