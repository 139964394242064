import React, { useEffect } from 'react'

import { ErrorCircleFilled } from '@fluentui/react-icons'
import * as Toast from '@radix-ui/react-toast'
import { modalCreators } from '@store/ducks/modal'
import { useAppDispatch, useAppSelector } from '@store/hooks'

import { Icon } from '../Icon'
import {
	ToastAction,
	ToastRoot,
	ToastTitle,
	ToastViewport,
	ToastDescription,
	ToastCheck,
} from './styles'

const ToastError = () => {
	const { options, type } = useAppSelector((state) => state.modal)

	const dispatch = useAppDispatch()

	useEffect(() => {
		let timeout: NodeJS.Timeout

		if (type === 'error') {
			timeout = setTimeout(() => dispatch(modalCreators.close()), 6000)
		}

		return () => clearTimeout(timeout)
	}, [type, dispatch])

	return (
		<Toast.Provider swipeDirection='right'>
			<ToastRoot open={type === 'error'}>
				<ToastCheck altText='Alert'>
					<ErrorCircleFilled />
				</ToastCheck>
				<div>
					<ToastTitle>{options.title}</ToastTitle>
					<ToastDescription>{options.description}</ToastDescription>
				</div>
				<ToastAction
					asChild
					onClick={() => {
						dispatch(modalCreators.close())
					}}
				>
					<Icon title='Close' name='close' width={17} height={17} />
				</ToastAction>
			</ToastRoot>
			<ToastViewport />
		</Toast.Provider>
	)
}

export default ToastError
